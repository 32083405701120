import { produce } from 'immer';
import moment from 'moment-timezone';
import { RootState } from '../app/store';
import { MenuStages } from '../constants/enums';
import {
  Category,
  CategoryAndTimePeriodQueryQuery,
  MenuItem,
  MenuItemSetting,
  MenuItemSettingKey,
  MenuItemsQueryQuery,
  MenuOverride,
  ModalityType,
  ModifierGroup,
  ModifierGroupsQueryQuery,
  OverrideKey,
  PosProperties,
  RestaurantInfoQuery,
  SecondaryType,
  TimePeriod,
} from '../generated-interfaces/graphql';
import { CartItem, CartModifierGroup } from './cart';
import { VOICE_PROPERTIES } from './constants';
import {
  ExpandName,
  generateModSymbolMapping,
  ModSymbolCodeNameMappingType,
} from './mappings';
import {
  getGraphQLClient,
  getMenuFromMenuAPI,
  getMenuURLFromMenuAPI,
  getPersistentMenuPropByRestaurant,
} from './network';
import { GenericMap, Override } from './types';

type AlwaysAvailableTimePeriod = TimePeriod;
export const alwaysAvailableTimePeriod: AlwaysAvailableTimePeriod = {
  id: '-1',
  description: null,
  availability: [0, 1, 2, 3, 4, 5, 6].map((day) => {
    return {
      day,
      hours: [],
      alwaysEnabled: true,
    };
  }),
  timePeriodCategoryMappings: [],
};

export function convertToMap<T extends { id: string }>(
  entries: T[]
): GenericMap<T> {
  return entries.reduce((acc, entry) => {
    acc[entry.id] = entry;
    return acc;
  }, {} as GenericMap<T>);
}

export function formatMenuResponse(menuRes: MenuItemsQueryQuery) {
  return {
    menuItems: convertToMap(menuRes.menuItems),
    overrides: convertToMap(menuRes.menuOverrides),
    menuItemSettings: convertToMap(menuRes.menuItemSettings),
    posSettings: convertToMap(menuRes.posProperties),
  } as {
    menuItems: GenericMap<MenuItem>;
    overrides: GenericMap<MenuOverride>;
    menuItemSettings: GenericMap<MenuItemSetting>;
    posSettings: GenericMap<PosProperties>;
  };
}

export function formatCategoryAndTimePeriodResponse(
  categoryAndTimePeriodRes: CategoryAndTimePeriodQueryQuery
) {
  return {
    categories: convertToMap(categoryAndTimePeriodRes.categories),
    timePeriods: convertToMap(categoryAndTimePeriodRes.timePeriods),
  } as {
    categories: GenericMap<Category>;
    timePeriods: GenericMap<TimePeriod>;
  };
}

export function formatModGroupResponse(
  modifierGroupRes: ModifierGroupsQueryQuery
) {
  return {
    modifierGroups: convertToMap(modifierGroupRes.modifierGroups),
  } as {
    modifierGroups: GenericMap<ModifierGroup>;
  };
}

// "Re-Flatten" menu items but AFTER setting the category and children and stuff for easier use later
export function flattenMenuItems(
  menuItems: GenericMap<ParsedMenuItem>
): GenericMap<ParsedMenuItem> {
  return Object.values(menuItems).reduce((acc, item) => {
    if (!acc[item.itemId]) {
      acc[item.itemId] = item;
      for (let childModGroup of Object.values(item.modifierGroups)) {
        if (!flattenedModGroups[childModGroup.id]) {
          flattenedModGroups[childModGroup.id] = childModGroup;
          Object.assign(acc, flattenMenuItems(childModGroup.menuItems));
        }
      }
    }
    return acc;
  }, {} as { [itemId: string]: ParsedMenuItem });
}

export type ParsedModifierGroup = Override<
  ModifierGroup,
  { menuItems: GenericMap<ParsedMenuItem>; prpName: string }
>;

/*
 * if
 * A -> A .. childModifer is excluded.
 *
 * if
 * A -> B -> A
 * ... We get in a loop.
 *
 * How do we prevent the HITL from choking?
 * (Without exploding into 100,000,000 menu items)
 *
 * So... We only go down one level!
 */
function parseROPModifierGroup(
  modifierGroupId: string,
  menuRes: MenuResponses,
  persistentVoiceProps: GenericMap<PersistentMenuProperty>,
  history: MenuItem[] = []
): ParsedModifierGroup {
  if (parsedModifierGroups[modifierGroupId]) {
    return parsedModifierGroups[modifierGroupId];
  }

  const modifierGroup = menuRes.modifierGroups[modifierGroupId];
  const [modGroupDisplayName, modGroupDescription] = getVoiceProps(
    modifierGroup.name,
    persistentVoiceProps
  );
  const itemsToAdd = modifierGroup.menuItems
    .filter(
      (menuItemId) =>
        !history.map((menuItem) => menuItem.id).includes('' + menuItemId)
    )
    .map((menuItemId) =>
      parseROPMenuItem(
        String(menuItemId),
        modifierGroup.id,
        menuRes,
        persistentVoiceProps,
        history
      )
    )
    .filter((item) => item.available || isItem86edToday(item));

  const result = {
    ...modifierGroup,
    name: modGroupDisplayName ? modGroupDisplayName : modifierGroup.name,
    description: modGroupDescription
      ? modGroupDescription
      : modifierGroup.description,
    menuItems: convertToMap(itemsToAdd),
    prpName: modifierGroup.name,
  };
  parsedModifierGroups[modifierGroupId] = result;
  return result;
}

function parseROPChildModifierGroup(
  modifierGroup: ParsedModifierGroup,
  persistentVoiceProps: GenericMap<PersistentMenuProperty>,
  cartSequenceId: number,
  modality: ModalityType
): CartModifierGroup {
  let { id: modifierGroupId, defaultSelectedItemIds } = modifierGroup;
  if (!defaultSelectedItemIds || defaultSelectedItemIds?.length <= 0) {
    return {} as CartModifierGroup;
  }
  if (parsedChildModifierGroups[modifierGroupId]) {
    return parsedChildModifierGroups[modifierGroupId];
  }

  const [modGroupDisplayName] = getVoiceProps(
    modifierGroup.name,
    persistentVoiceProps
  );
  const selectedItems: GenericMap<CartItem> = {};
  defaultSelectedItemIds?.forEach((item: number) => {
    const menuItem = modifierGroup.menuItems[item];
    if (menuItem) {
      selectedItems[item] = {
        ...menuItem,
        modality,
        cartItemId: cartSequenceId,
        childModifierGroups: {},
      };
    }
  });
  parsedChildModifierGroups[modifierGroupId] = {
    ...modifierGroup,
    cartModifierGroupId: String(cartSequenceId),
    menuModifierGroupId: modifierGroup.id,
    name: modGroupDisplayName ? modGroupDisplayName : modifierGroup.name,
    selectedItems,
  };

  return parsedChildModifierGroups[modifierGroupId];
}

interface ExtraMenuItemProps {
  category: string;
  categoryId: string | null;
  itemId: string;
  synonyms?: string;
  originalMenuItemId: string;
  containsOwnModifierGroup: boolean;
  sortOrderByCategory: number | null;
  sortOrderByModifierGroup: number | null;
  modifierGroups: GenericMap<ParsedModifierGroup>;
  overrides: GenericMap<MenuOverride>;
  menuItemSettings: GenericMap<MenuItemSetting>;
  posProperties: GenericMap<PosProperties>;
}

export type ParsedMenuItem = Override<MenuItem, ExtraMenuItemProps>;

const parsedMenuItems: GenericMap<ParsedMenuItem> = {}; // key should be combination of menu item id + modifier group id/category id
const parsedModifierGroups: GenericMap<ParsedModifierGroup> = {};
const parsedChildModifierGroups: GenericMap<CartModifierGroup> = {};
const parsedModAvailability: GenericMap<GenericMap<ParsedModifierGroup>> = {};
const flattenedModGroups: GenericMap<ParsedModifierGroup> = {};

export interface MenuResponses {
  menuItems: GenericMap<MenuItem>;
  overrides: GenericMap<MenuOverride>;
  menuItemSettings: GenericMap<MenuItemSetting>;
  posSettings: GenericMap<PosProperties>;
  categories: GenericMap<Category>;
  timePeriods: GenericMap<TimePeriod>;
  modifierGroups: GenericMap<ModifierGroup>;
}

export function buildFullMenuItem(
  item: TopLevelMenuItem,
  menuRes: MenuResponses,
  persistentVoiceProps: GenericMap<PersistentMenuProperty>
): ParsedMenuItem {
  const menuItem = menuRes.menuItems[item.id];
  const categoryId = item.categoryId;
  const itemId = item.id + '-' + categoryId;
  const filteredCategory = menuRes.categories[categoryId].sortOrder.filter(
    (order) => order.id === Number(item.id)
  );
  const [menuItemDisplayName, menuItemDescription] = getVoiceProps(
    menuItem.name,
    persistentVoiceProps
  );
  const modifierGroups = menuItem.modifierGroups.map((modifierGroupId) =>
    parseROPModifierGroup(
      String(modifierGroupId),
      menuRes,
      persistentVoiceProps
    )
  );
  const modifierGroupsMap = convertToMap(modifierGroups);

  const result = {
    ...menuItem,
    name: ExpandName(menuItemDisplayName ? menuItemDisplayName : menuItem.name),
    description: menuItemDescription
      ? menuItemDescription
      : menuItem.description,
    category: item.category,
    categoryId: item.categoryId,
    itemId,
    originalMenuItemId: String(item.id),
    containsOwnModifierGroup: false,
    // Dinero library requires the price in integer cents
    price: Math.round(menuItem.price * 100),
    imageUrl: menuItem.imageUrl ?? '/no_menu_image.jpg',
    sortOrderByCategory:
      filteredCategory.length > 0 ? filteredCategory[0].sortOrder : null,
    sortOrderByModifierGroup: null,
    modifierGroups: modifierGroupsMap,
    overrides: convertToMap(
      menuItem.menuOverrides
        .map((id) => menuRes.overrides[id])
        .filter((override) => {
          return (
            override.secondaryType === null ||
            (override.secondaryType === SecondaryType.Category &&
              override.secondaryId === categoryId)
          );
        })
    ),
    menuItemSettings: convertToMap(
      menuItem.menuItemSettings
        .map((id) => menuRes.menuItemSettings[id])
        .filter((setting) => {
          return String(setting.menuItemId) === menuItem.id;
        })
    ),
    posProperties: convertToMap(
      Object.values(menuRes.posSettings).filter(
        (setting) =>
          setting.objectPrimaryKey === menuItem.id &&
          setting.propertyType === 'MENU_ITEM'
      )
    ),
  };
  // use itemId as the unique id to store parsed menu items
  parsedMenuItems[itemId] = result;
  return result;
}

function parseROPMenuItem(
  menuItemId: string,
  parentModifierGroupId: string,
  menuRes: MenuResponses,
  persistentVoiceProps: GenericMap<PersistentMenuProperty>,
  history: MenuItem[] = []
): ParsedMenuItem {
  const itemId = menuItemId + '-' + parentModifierGroupId;
  if (parsedMenuItems[itemId]) {
    return parsedMenuItems[itemId];
  }

  const menuItem: MenuItem = menuRes.menuItems[menuItemId];
  const parentModifierGroup = menuRes.modifierGroups[parentModifierGroupId];

  const [menuItemDisplayName, menuItemDescription] = getVoiceProps(
    menuItem.name,
    persistentVoiceProps
  );

  const result = {
    ...menuItem,
    name: ExpandName(menuItemDisplayName ? menuItemDisplayName : menuItem.name),
    description: menuItemDescription
      ? menuItemDescription
      : menuItem.description,
    category: 'modifier',
    categoryId: null,
    containsOwnModifierGroup: false,
    itemId: menuItemId,
    originalMenuItemId: menuItemId,
    // Dinero library requires the price in integer cents
    price: Math.round(menuItem.price * 100),
    imageUrl: menuItem.imageUrl ?? '/no_menu_image.jpg',
    sortOrderByCategory: null,
    sortOrderByModifierGroup:
      parentModifierGroup.sortOrder.filter(
        (order) => String(order.id) === menuItemId
      ).length > 0
        ? parentModifierGroup.sortOrder.filter(
            (order) => String(order.id) === menuItemId
          )[0].sortOrder
        : null,
    modifierGroups: {},
    overrides: convertToMap(
      menuItem.menuOverrides
        .map((id) => menuRes.overrides[id])
        .filter((override) => {
          return (
            override.secondaryType === null ||
            (override.secondaryType === SecondaryType.ModifierGroup &&
              override.secondaryId === parentModifierGroupId)
          );
        })
    ),
    menuItemSettings: convertToMap(
      menuItem.menuItemSettings
        .map((id) => menuRes.menuItemSettings[id])
        .filter((setting) => {
          return String(setting.menuItemId) === menuItem.id;
        })
    ),
    posProperties: convertToMap(
      Object.values(menuRes.posSettings).filter(
        (setting) =>
          setting.objectPrimaryKey === menuItem.id &&
          setting.propertyType === 'MENU_ITEM'
      )
    ),
  };

  if (history.length < 10) {
    // use itemId as the unique id to store parsed menu items
    parsedMenuItems[itemId] = result;
    // Recurse after
    result.containsOwnModifierGroup = menuItem.modifierGroups.includes(
      parseInt(parentModifierGroupId)
    );
    result.modifierGroups = convertToMap(
      menuItem.modifierGroups
        .filter(
          (modifierGroupId) => String(modifierGroupId) !== parentModifierGroupId
        )
        .map((modifierGroupId) =>
          parseROPModifierGroup(
            String(modifierGroupId),
            menuRes,
            persistentVoiceProps,
            history.concat(menuItem)
          )
        )
    );
  }
  return result;
}

export type ParsedCategory = Override<
  Category,
  { timePeriods: GenericMap<TimePeriod>; activeTimePeriod?: ActiveTimePeriod }
>;

export function parseCategoryAndTimeperiodResponse(
  categoryAndTimePeriodRes: ReturnType<
    typeof formatCategoryAndTimePeriodResponse
  >
) {
  const categoriesWithTimePeriod: ParsedCategory[] = [];
  const alwaysAvailableCategories: ParsedCategory[] = [];
  Object.values(categoryAndTimePeriodRes.categories)
    .sort((a, b) => {
      // sort categories by ownSortOrder
      if (a.ownSortOrder !== null && b.ownSortOrder !== null) {
        return a.ownSortOrder - b.ownSortOrder;
      } else if (a.ownSortOrder !== null) {
        return -1;
      } else if (b.ownSortOrder !== null) {
        return 1;
      }
      return 0;
    })
    .forEach((category) => {
      if (!category.timePeriods.length) {
        alwaysAvailableCategories.push({
          ...category,
          timePeriods: convertToMap([alwaysAvailableTimePeriod]),
        });
      } else {
        categoriesWithTimePeriod.push({
          ...category,
          timePeriods: convertToMap(
            category.timePeriods.map(
              (id) => categoryAndTimePeriodRes.timePeriods[id]
            )
          ),
        });
      }
    });
  return { categoriesWithTimePeriod, alwaysAvailableCategories };
}

export type TopLevelMenuItem = {
  category: string;
  categoryId: string;
  id: string;
  name: string;
  available: boolean;
  unavailableUntil: string | null;
  modcode?: string;
  speak?: string;
  synonyms?: string;
};

export function parseMenuResponse(
  categories: ParsedCategory[],
  menuRes: ReturnType<typeof formatMenuResponse>,
  persistentVoiceProps: GenericMap<PersistentMenuProperty>
) {
  let modSymbolMapping: ModSymbolCodeNameMappingType = {};
  let codeNameMapping: ModSymbolCodeNameMappingType = {};
  const topLevelMenuItems = categories
    .sort((a, b) => {
      // sort categories by ownSortOrder
      if (a.ownSortOrder !== null && b.ownSortOrder !== null) {
        return a.ownSortOrder - b.ownSortOrder;
      } else if (a.ownSortOrder !== null) {
        return -1;
      } else if (b.ownSortOrder !== null) {
        return 1;
      }
      return 0;
    })
    .reduce((acc, category) => {
      category.menuItems
        .filter((id) => {
          if (!menuRes.menuItems[id]) {
            return false;
          }
          const is86edIndefinitely = isItem86edIndefinitely(
            menuRes.menuItems[id]
          );
          return !menuRes.menuItems[id].isModifierOnly && !is86edIndefinitely;
        })
        .forEach((menuItemId) => {
          const [categoryDisplayName] = getVoiceProps(
            category.name,
            persistentVoiceProps
          );
          const [menuItemDisplayName, , synonyms] = getVoiceProps(
            menuRes.menuItems[menuItemId].name,
            persistentVoiceProps
          );

          const menuItem: TopLevelMenuItem = {
            category: ExpandName(
              categoryDisplayName ? categoryDisplayName : category.name
            ),
            categoryId: category.id,
            id: String(menuItemId),
            name: ExpandName(
              menuItemDisplayName
                ? menuItemDisplayName
                : menuRes.menuItems[menuItemId].name
            ),
            synonyms: synonyms || '',
            available: menuRes.menuItems[menuItemId].available,
            unavailableUntil: menuRes.menuItems[menuItemId].unavailableUntil,
          };
          acc[menuItem.id] = { ...menuItem };
        });
      if (category.name === '__omnimod__') {
        const [parsedModSymbolMapping, parsedCodeNameMapping] =
          generateModSymbolMapping(category, menuRes);
        modSymbolMapping = parsedModSymbolMapping;
        codeNameMapping = parsedCodeNameMapping;
      }
      return acc;
    }, {} as GenericMap<TopLevelMenuItem>);
  return { topLevelMenuItems, modSymbolMapping, codeNameMapping };
}

function getMenuOverrides(menuItem: ParsedMenuItem, modality: ModalityType) {
  const isCategoryLevelItem = !!menuItem.categoryId;
  const isModifierLevelItem = menuItem.category === 'modifier';
  let overrides: any[] = [];
  if (menuItem.overrides) {
    overrides = Object.values(menuItem.overrides).filter(
      (o) => o.modalityType === modality
    );
  }
  if (isCategoryLevelItem) {
    overrides = overrides.filter(
      (o) =>
        o.secondaryType === SecondaryType.Category || o.secondaryType === null
    );
  } else if (isModifierLevelItem) {
    overrides = overrides.filter(
      (o) =>
        o.secondaryType === SecondaryType.ModifierGroup ||
        o.secondaryType === null
    );
  }

  const secondaryTypeOverride = overrides.filter(
    (o) => o.secondaryType !== null
  );
  const modalityTypeOverride = overrides.filter(
    (o) => o.secondaryType === null
  );
  return {
    secondaryTypeOverride,
    modalityTypeOverride,
  };
}

function isItemEnabledByModality(
  menuItem: ParsedMenuItem,
  modalityState: ModalityType
) {
  let requiredSettingKey: MenuItemSettingKey;
  switch (modalityState) {
    case ModalityType.Dinein:
      requiredSettingKey = MenuItemSettingKey.IsDineInEnabled;
      break;
    case ModalityType.Togo:
      requiredSettingKey = MenuItemSettingKey.IsToGoEnabled;
      break;
    case ModalityType.Delivery:
      requiredSettingKey = MenuItemSettingKey.IsDeliveryEnabled;
      break;
  }
  return !!Object.values(menuItem.menuItemSettings).find(
    (setting) => setting.key === requiredSettingKey && setting.value === 'true'
  );
}

export type PRPRestaurantSettings =
  RestaurantInfoQuery['restaurant']['restaurantSettings'];

export function getDefaultTaxRateForModality(
  modality: ModalityType,
  restaurantSettings?: PRPRestaurantSettings
) {
  if (!restaurantSettings) {
    return 0;
  }
  switch (modality) {
    case ModalityType.Togo:
      return restaurantSettings.toGoModalityTaxRate;
    case ModalityType.Delivery:
      return restaurantSettings.deliveryModalityTaxRate;
    case ModalityType.Dinein:
    default:
      return restaurantSettings.dineInModalityTaxRate;
  }
}

export function getMenuItemTax(
  menuItem: ParsedMenuItem,
  modality: ModalityType,
  restaurantSettings?: PRPRestaurantSettings
): number {
  let actualModality = modality;
  const { modalityTypeOverride } = getMenuOverrides(menuItem, actualModality);
  const taxOverride = modalityTypeOverride.find(
    (o) => o.overrideKey === OverrideKey.Tax
  );
  if (taxOverride !== undefined) {
    return parseFloat(taxOverride.overrideValue);
  } else if (modality === ModalityType.Dinein && menuItem.tax != null) {
    return menuItem.tax;
  } else {
    return getDefaultTaxRateForModality(modality, restaurantSettings);
  }
}

export function getMenuItemPrice(
  menuItem: ParsedMenuItem,
  modality: ModalityType,
  quantity?: number
) {
  let actualModality = modality;
  const { modalityTypeOverride, secondaryTypeOverride } = getMenuOverrides(
    menuItem,
    actualModality
  );
  const priceOverride =
    secondaryTypeOverride.find((o) => o.overrideKey === OverrideKey.Price) ||
    modalityTypeOverride.find((o) => o.overrideKey === OverrideKey.Price);
  const price =
    priceOverride === undefined
      ? menuItem.price
      : Math.round(parseFloat(priceOverride.overrideValue) * 100);
  return price * (quantity || 1);
}

export function updateCartItemModality(
  cartItem: CartItem,
  modality: ModalityType
) {
  if (cartItem.modality === modality) {
    return null;
  }
  cartItem.modality = modality;
  Object.keys(cartItem.childModifierGroups).forEach((modGroupId) => {
    const modGroup = cartItem.childModifierGroups[modGroupId];
    Object.keys(modGroup.selectedItems).forEach((cartItemId) => {
      const updatedCartItem = updateCartItemModality(
        modGroup.selectedItems[cartItemId],
        modality
      );
      if (updatedCartItem) {
        modGroup.selectedItems[cartItemId] = updatedCartItem;
      }
    });
  });
  return cartItem;
}

export function updateCartPrices(
  cartItem: CartItem,
  menuItems: GenericMap<ParsedMenuItem>,
  modality: ModalityType
): CartItem | null {
  let updated = false;
  let updatedCartItem = cartItem;
  const menuItem = menuItems[cartItem.itemId];
  if (menuItem) {
    const cartItemPrice = getMenuItemPrice(cartItem, cartItem.modality);
    const menuItemPrice = getMenuItemPrice(menuItem, modality);
    if (menuItemPrice !== cartItemPrice) {
      updatedCartItem = produce(updatedCartItem, (cartItem) => {
        cartItem.price = menuItemPrice;
      });
      updated = true;
    }
    for (let cartModGroup of Object.values(cartItem.childModifierGroups)) {
      const modGroup =
        menuItem.modifierGroups[cartModGroup.menuModifierGroupId];
      for (let item of Object.values(cartModGroup.selectedItems)) {
        const updatedItem = updateCartPrices(
          item,
          modGroup.menuItems,
          modality
        );
        if (updatedItem) {
          updatedCartItem = produce(updatedCartItem, (cartItem) => {
            cartItem.childModifierGroups[
              cartModGroup.menuModifierGroupId
            ].selectedItems[updatedItem.itemId] = updatedItem;
          });
          updated = true;
        }
      }
    }
  }
  return updated ? updatedCartItem : null;
}

export function checkForRequiredAndNotFreeModifier(
  item: ParsedMenuItem
): boolean {
  let result = false;
  firstLevelLoop: for (let modifierGroup of Object.values(
    item.modifierGroups
  )) {
    if (modifierGroup.minimumSelections > 0) {
      for (let child of Object.values(modifierGroup.menuItems)) {
        if (child.price > 0) {
          // if a priced modifier is found, no need to check the rest of modifiers
          result = true;
          break firstLevelLoop;
        }
      }
    }
  }
  return result;
}

export function findTopLevelCartItem(
  wantedCartItem: CartItem,
  cartItems: { [cartId: string]: CartItem },
  topLevelItem?: CartItem
): CartItem | null {
  for (let cartItem of Object.values(cartItems)) {
    if (cartItem.cartItemId === wantedCartItem.cartItemId) {
      if (topLevelItem) {
        return topLevelItem;
      }
      return cartItem;
    }
    const modGroups = Object.values(cartItem.childModifierGroups);
    for (let modGroup of modGroups) {
      const found = findTopLevelCartItem(
        wantedCartItem,
        modGroup.selectedItems,
        topLevelItem ? topLevelItem : cartItem
      );
      if (found) {
        return found;
      }
    }
  }
  return null;
}

// Takes in menu items flattened as we get from webservice instead of in the nested mod group form
export function checkForNoLongerAvailableItems(
  cartItems: GenericMap<CartItem>,
  parsedMenuItems: GenericMap<ParsedMenuItem>
): CartItem[] {
  const unavailableItems: CartItem[] = [];
  for (let cartItem of Object.values(cartItems)) {
    const menuItem = parsedMenuItems[cartItem.itemId];
    if (!menuItem) {
      unavailableItems.push(cartItem);
      continue;
    }

    for (let modGroup of Object.values(cartItem.childModifierGroups)) {
      unavailableItems.push(
        ...checkForNoLongerAvailableItems(
          modGroup.selectedItems,
          parsedMenuItems
        )
      );
    }
  }
  return unavailableItems;
}

function considerItemAvailability(
  menuItems: GenericMap<ParsedMenuItem>,
  modalityState: ModalityType
): GenericMap<ParsedMenuItem> {
  return Object.values(menuItems).reduce((acc, menuItem) => {
    if (!menuItem.available) {
      return acc; // Don't return 86'ed items
    }

    if (!isItemEnabledByModality(menuItem, modalityState)) {
      return acc;
    }

    acc[menuItem.itemId] = { ...menuItem };

    var updatedModGroups: GenericMap<ParsedModifierGroup> = {};
    if (parsedModAvailability[menuItem.itemId]) {
      updatedModGroups = parsedModAvailability[menuItem.itemId];
    } else {
      parsedModAvailability[menuItem.itemId] = updatedModGroups;
      for (let modGroup of Object.values(menuItem.modifierGroups)) {
        const updatedModGroup = { ...modGroup };
        updatedModGroup.menuItems = considerItemAvailability(
          modGroup.menuItems,
          modalityState
        );
        updatedModGroups[modGroup.id] = updatedModGroup;
      }
    }

    acc[menuItem.itemId].modifierGroups = updatedModGroups;
    return acc;
  }, {} as GenericMap<ParsedMenuItem>);
}

type ActiveTimePeriod = {
  alwaysEnabled: boolean;
  day: string;
  start?: string;
  end?: string;
};

export function considerTimePeriodCategory(
  categoryMap: ParsedCategory[],
  timezone: string
): ParsedCategory[] {
  return categoryMap
    .map((category) => ({
      ...category,
      activeTimePeriod: getTimePeriod(
        Object.values(category.timePeriods),
        timezone
      ),
    }))
    .filter((category) => category.activeTimePeriod);
}

function getTimePeriod(
  timePeriods: TimePeriod[],
  timezone: string
): ActiveTimePeriod | undefined {
  const currentUTCTime = moment.utc().format();
  //use the timezone of the restaurant to create the moment objects
  moment.tz.setDefault(timezone);
  let current = moment
    .utc(currentUTCTime, 'YYYY-MM-DD HH-mm-ss Z')
    .tz(timezone);
  const day = current.weekday();
  for (let period of timePeriods) {
    const availabilityOfCurrent = period.availability.find(
      (obj) => obj.day === day
    );
    if (availabilityOfCurrent?.alwaysEnabled) {
      return { alwaysEnabled: true, day: current.format('dddd') };
    }
    if (availabilityOfCurrent?.hours) {
      for (let timeRange of availabilityOfCurrent?.hours) {
        const updatedTimeRange = timeRange.map((time) => {
          let timeStr = String(time);
          while (timeStr.length < 4) {
            timeStr = '0' + timeStr;
          }
          return timeStr;
        });
        const startTime = current
          .clone()
          .set('hour', Number(updatedTimeRange[0].slice(0, 2)))
          .set('minute', Number(updatedTimeRange[0].slice(2)));
        const endTime = current
          .clone()
          .set('hour', Number(updatedTimeRange[1].slice(0, 2)))
          .set('minute', Number(updatedTimeRange[1].slice(2)));

        if (
          current.isSameOrBefore(endTime) &&
          current.isSameOrAfter(startTime)
        ) {
          return {
            alwaysEnabled: false,
            day: current.format('dddd'),
            start: startTime.format('h:mma'),
            end: endTime.format('h:mma'),
          };
        }
      }
    }
  }
  return undefined;
}

export function checkForUnavailableRequiredModifierGroup(
  modifierGroups: GenericMap<ParsedModifierGroup>
) {
  return !!Object.values(modifierGroups).find((modGroup) => {
    if (modGroup.minimumSelections > 0) {
      //check the modifier groups recursively
      const availableModsCounter = Object.values(modGroup.menuItems).filter(
        (child) =>
          child.available &&
          !checkForUnavailableRequiredModifierGroup(child.modifierGroups)
      ).length;
      if (availableModsCounter < modGroup.minimumSelections) {
        return true;
      }
    }
  });
}

export function checkForQuantityExceededItems(
  cartItems: GenericMap<CartItem>,
  parsedMenuItems: GenericMap<ParsedMenuItem>
): CartItem[] {
  const quantityExceededItems: CartItem[] = [];
  let quantityMapping: { [itemId: string]: number } = {};
  for (let cartItem of Object.values(cartItems)) {
    const itemId = cartItem.itemId;
    if (itemId in quantityMapping) {
      quantityMapping[itemId] += 1;
    } else {
      quantityMapping[itemId] = 1;
    }
  }
  for (let cartItem of Object.values(cartItems)) {
    const menuItem = parsedMenuItems[cartItem.itemId];
    if (
      menuItem &&
      menuItem.availableLimitedQuantity &&
      menuItem.availableLimitedQuantity < quantityMapping[menuItem.itemId]
    ) {
      const itemAlreadyExist = quantityExceededItems.find(
        (item) => item.itemId === cartItem.itemId
      );
      if (!itemAlreadyExist) {
        quantityExceededItems.push(cartItem);
      }
      continue;
    }

    for (let modGroup of Object.values(cartItem.childModifierGroups)) {
      quantityExceededItems.push(
        ...checkForQuantityExceededItems(
          modGroup.selectedItems,
          parsedMenuItems
        )
      );
    }
  }
  return quantityExceededItems;
}

export function considerSubCategory(categories: string[]) {
  let subCatMapping: {
    [category: string]: { hasSubCat: boolean; subCats: string[] };
  } = {};
  categories.map((cat) => {
    if (cat.indexOf(':') !== -1) {
      //there is a colon in the category name which should be a sub category in BJs menu
      const catName = cat.split(':')[0];
      if (!Object.keys(subCatMapping).includes(catName)) {
        subCatMapping[catName] = { hasSubCat: true, subCats: [] };
      }
      subCatMapping[catName].subCats?.push(cat);
    } else {
      subCatMapping[cat] = { hasSubCat: false, subCats: [] };
    }
  });
  return subCatMapping;
}

export function sortChildrenModGroup(cartItem: CartItem) {
  const modifierGroupsSortOrderMapping = cartItem.sortOrder.reduce(
    (acc: any, o: any) => {
      acc[String(o.id)] = o.sortOrder;
      return acc;
    },
    {} as { [id: string]: any }
  );

  return Object.values(cartItem.childModifierGroups).sort((a: any, b: any) => {
    if (
      modifierGroupsSortOrderMapping[a.menuModifierGroupId] !== null &&
      modifierGroupsSortOrderMapping[b.menuModifierGroupId] !== null
    ) {
      return (
        modifierGroupsSortOrderMapping[a.menuModifierGroupId] -
        modifierGroupsSortOrderMapping[b.menuModifierGroupId]
      );
    } else if (modifierGroupsSortOrderMapping[a.menuModifierGroupId] !== null) {
      return -1;
    } else if (modifierGroupsSortOrderMapping[b.menuModifierGroupId] !== null) {
      return 1;
    }
    return 0;
  });
}

export function sortModGroup(cartItem: CartItem | ParsedMenuItem) {
  const modifierGroupsSortOrderMapping = cartItem.sortOrder.reduce(
    (acc: any, o: any) => {
      acc[String(o.id)] = o.sortOrder;
      return acc;
    },
    {} as { [id: string]: any }
  );

  return Object.values(cartItem.modifierGroups).sort((a: any, b: any) => {
    if (
      modifierGroupsSortOrderMapping[a.id] !== null &&
      modifierGroupsSortOrderMapping[b.id] !== null
    ) {
      return (
        modifierGroupsSortOrderMapping[a.id] -
        modifierGroupsSortOrderMapping[b.id]
      );
    } else if (modifierGroupsSortOrderMapping[a.menuModifierGroupId] !== null) {
      return -1;
    } else if (modifierGroupsSortOrderMapping[b.menuModifierGroupId] !== null) {
      return 1;
    }
    return 0;
  });
}

export type PersistentMenuProperty = {
  property_id: number;
  restaurant_code: string;
  unique_identifier: string;
  property_type: string;
  property_key: string;
  property_value: any;
};

export interface IMenuVersionsResponse {
  id: string;
  restaurant_code: string;
  creator_username: string;
  creator_first_name: string;
  creator_last_name: string;
  commit_id: string;
  updated_at: string;
  created_at: string;
  stage: MenuStages;
  is_active: boolean;
  publisher_username: string;
  publisher_first_name: string;
  publisher_last_name: string;
  commit_created_at: string;
  comment: string;
}

export interface IMenuVersion {
  id: string;
  creatorUsername: string;
  creatorName: string;
  publisherUsername: string;
  publisherName: string;
  commitId: string;
  updatedAt: string;
  createdAt: string;
  comment: string;
  menuCommitUrl?: string;
  stage?: MenuStages;
  isActive: boolean;
}

enum PropertyValueKey {
  DisplayName = 'display_name',
  Description = 'description',
  Synonym = '__synonym__',
}

export function getVoiceProps(
  uniqueIdentifier: string,
  persistentVoiceProps: GenericMap<PersistentMenuProperty>
) {
  let displayName;
  let description;
  let synonyms;
  if (uniqueIdentifier in persistentVoiceProps) {
    try {
      displayName = JSON.parse(
        persistentVoiceProps[uniqueIdentifier].property_value
      )[PropertyValueKey.DisplayName];
      synonyms = JSON.parse(
        persistentVoiceProps[uniqueIdentifier].property_value
      )[PropertyValueKey.Synonym];
      description = JSON.parse(
        persistentVoiceProps[uniqueIdentifier].property_value
      )[PropertyValueKey.Description];
    } catch (err) {
      console.error('Parse voice props failed', err);
    }
  }
  return [displayName || uniqueIdentifier, description, synonyms];
}

export function convertModGroupToMap<T extends { menuModifierGroupId: string }>(
  entries: T[]
): GenericMap<T> {
  return entries.reduce((acc, entry) => {
    acc[entry.menuModifierGroupId] = entry;
    return acc;
  }, {} as GenericMap<T>);
}

export function isItem86edIndefinitely(item: MenuItem | ParsedMenuItem) {
  return !item.available && !item.unavailableUntil;
}

export function isItem86edToday(
  item: MenuItem | ParsedMenuItem | TopLevelMenuItem
) {
  return !item.available && item.unavailableUntil;
}

export const checkItemInTree = ({
  cartItem,
  pathToItem,
  fromSelectModifier,
}: {
  cartItem: CartItem;
  pathToItem: string;
  fromSelectModifier?: boolean;
}): any => {
  const ids = pathToItem.split('__');
  let splicedId = '';
  if (fromSelectModifier) {
    splicedId = ids.splice(-1, 1)[0] || '';
  }
  let currentItem: any = null;
  currentItem = ids.reduce((item: any, id: string) => {
    if (Object.values(item?.childModifierGroups || {}).length) {
      return item.childModifierGroups[id];
    } else if (Object.values(item?.selectedItems || {}).length) {
      return item.selectedItems[id];
    }
    return null;
  }, cartItem as any);
  if (fromSelectModifier && currentItem?.selectedItems) {
    return currentItem.selectedItems[splicedId];
  }
  return currentItem;
};

export async function fetchMenuBasedOnStage({
  restaurantCode,
  state,
  primaryRestaurantCode,
  currentMenuVersion,
  currentStage,
}: {
  restaurantCode: string;
  primaryRestaurantCode?: string;
  state: RootState;
  currentMenuVersion: string;
  currentStage: MenuStages;
}) {
  if (
    currentMenuVersion === 'latest' &&
    currentStage === MenuStages.PLAYGROUND
  ) {
    const client = getGraphQLClient(state.config.NODE_ENV);
    const categoryAndTimePeriodJSON = (await client.categoryAndTimePeriodQuery({
      restaurantCode: restaurantCode,
      version: null,
    })) as any as CategoryAndTimePeriodQueryQuery;
    const persistentMenuProperty = await getPersistentMenuPropByRestaurant(
      state.config.NODE_ENV,
      primaryRestaurantCode || restaurantCode,
      {
        property_key: VOICE_PROPERTIES,
      }
    );

    const menuJSON = (await client.menuItemsQuery({
      restaurantCode: restaurantCode,
      version: null,
    })) as any as MenuItemsQueryQuery;

    const modifierGroupJSON = (await client.modifierGroupsQuery({
      restaurantCode: restaurantCode,
      version: null,
    })) as any as ModifierGroupsQueryQuery;

    return {
      categoryAndTimePeriodJSON,
      menuJSON,
      modifierGroupJSON,
      persistentMenuProperty,
    };
  } else {
    const params: { [key: string]: string } = {
      restaurant_code: primaryRestaurantCode || restaurantCode,
    };
    if (currentMenuVersion) {
      params['commit_id'] = currentMenuVersion;
    }
    const menuRes = await getMenuURLFromMenuAPI(
      state.config.NODE_ENV,
      currentStage,
      params
    );
    if (menuRes.data.menu_url) {
      const {
        categories,
        timePeriods,
        menuItemSettings,
        menuItems,
        menuOverrides,
        posProperties,
        modifierGroups,
        voiceProperties,
      }: any = await getMenuFromMenuAPI(
        state.config.NODE_ENV,
        menuRes.data.menu_url,
        {}
      );
      return {
        categoryAndTimePeriodJSON: {
          categories,
          timePeriods,
        },
        menuJSON: {
          menuItemSettings,
          menuItems,
          menuOverrides,
          posProperties,
        },
        modifierGroupJSON: {
          modifierGroups,
        },
        persistentMenuProperty: voiceProperties,
      };
    }
    return {
      CategoryAndTimePeriodQueryQuery: {},
      menuJSON: {},
      modifierGroupJSON: {},
    };
  }
}

export const generateMenuTitle = (commitId: string, comment?: string) =>
  comment ? `${commitId} - ${comment}` : commitId;
