import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { CartItem } from '../../utils/cart';
import Colors from '../../utils/color';
import {
  convertModGroupToMap,
  convertToMap,
  sortModGroup,
} from '../../utils/menu';
import CartItemChildModGroup from './CartItemChildModGroup';

interface ICartItemModGroups {
  cartItem: CartItem;
  modGroupCartItem: CartItem;
  quantity: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  cartItemModGroupClass: {
    paddingLeft: theme.spacing(1.75),
  },
  modGroupErrorClass: {
    fontSize: `${theme.spacing(1.5)} !important`,
  },
}));

export default function CartItemModGroups({
  modGroupCartItem,
  cartItem,
  quantity,
}: ICartItemModGroups) {
  const { cartItemModGroupClass, modGroupErrorClass } = useStyles();
  const childModifierGroups = convertModGroupToMap(
    Object.values({
      ...modGroupCartItem.childModifierGroups,
      ...cartItem.childModifierGroups,
    }).filter((modGroup) => Object.keys(modGroup.selectedItems).length)
  );

  const childModifierGroupIDs = Object.keys(childModifierGroups);

  const requiredButNotSelectedModifierGroups = convertToMap(
    Object.values(modGroupCartItem.modifierGroups).filter(
      (modGroup) =>
        modGroup.minimumSelections > 0 &&
        !childModifierGroupIDs.includes(modGroup.id)
    )
  );
  const requiredButNotSelectedModifierGroupIDs = Object.keys(
    requiredButNotSelectedModifierGroups
  );

  return (
    <div className={`cartitem-mod-group ${cartItemModGroupClass}`}>
      {sortModGroup(modGroupCartItem).map((modGroup) => {
        if (childModifierGroupIDs.includes(modGroup.id))
          return (
            <CartItemChildModGroup
              key={modGroup.id}
              quantity={quantity}
              cartItem={cartItem}
              modGroup={childModifierGroups[modGroup.id]}
            />
          );
        if (requiredButNotSelectedModifierGroupIDs.includes(modGroup.id))
          return (
            <Typography
              key={modGroup.id}
              className={modGroupErrorClass}
              color={Colors.Thunderbird}
              variant="subtitle2"
              children={'! ' + modGroup.name}
            />
          );
        return null;
      })}
    </div>
  );
}
