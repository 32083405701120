import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { selectMenuVersion } from '../../../reducers/menuSlice';
import { EntityMenuItem } from '../../../reducers/messagingSlice';
import { selectRestaurant, selectStage } from '../../../reducers/restaurantSlice';
import { SLICE_NAME, SuggestionStatus } from './ai.constants';
import { UpdateSuggestionPayload } from './ai.props';
import { initialState } from './ai.utils';

export const aiSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetAI: (state) => {
      state = initialState;
      return state;
    },
    addSuggestion: (state, action: PayloadAction<EntityMenuItem>) => {
      const { id } = action.payload;
      state.suggestionsById[id] = {
        suggestionStatus: SuggestionStatus.new,
        ...action.payload,
      };
      state.suggestionsOrder.push(id);
    },
    updateSuggestion: (state, action: PayloadAction<UpdateSuggestionPayload>) => {
      const { id, quantity, suggestionStatus } = action.payload;
      state.suggestionsById[id] = {
        ...state.suggestionsById[id],
        ...(quantity ? { quantity } : null),
        ...(suggestionStatus ? { suggestionStatus } : null),
      };
    },
    rejectFirstActiveSuggestion: (state) => {
      const id = state.suggestionsOrder.find((sid) => state.suggestionsById[sid].suggestionStatus === SuggestionStatus.new);
      if (id) {
        state.suggestionsById[id] = {
          ...state.suggestionsById[id],
          suggestionStatus: SuggestionStatus.reject,
        };
      }
    },
    removeSuggestion: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      delete state.suggestionsById[id];
      state.suggestionsOrder = state.suggestionsOrder.filter((item) => item !== id);
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(selectRestaurant.fulfilled, selectStage.fulfilled, selectMenuVersion.fulfilled), (state, action) => {
      state.suggestionsById = {};
      state.suggestionsOrder = [];
    });
  },
});

const { resetAI, addSuggestion, updateSuggestion, removeSuggestion, rejectFirstActiveSuggestion } = aiSlice.actions;

export { resetAI, addSuggestion, updateSuggestion, removeSuggestion, rejectFirstActiveSuggestion };
export default aiSlice.reducer;
