import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import CartItemDetail from '../../components/ItemBuilder/menu/CartItemDetail';
import { selectCartItems } from '../../selectors/cart';
import { selectSelectedItemCartId } from '../../selectors/dialog';
import { SetCartItemRef } from '../../types';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  conatiner: {
    padding: `${spacing(4)} ${spacing(1)} 0 ${spacing(1)}`,
  },
  title: {
    fontStyle: 'italic',
  },
}));

interface OwnProps {
  setCartItemRef: SetCartItemRef;
}

const ItemBuilder = memo(function (props: OwnProps) {
  const { setCartItemRef } = props;
  const classes = useStyles(props);
  const cartItems = useSelector(selectCartItems);
  const selectedItemCartId = useSelector(selectSelectedItemCartId);
  const cartItem = cartItems?.[selectedItemCartId];

  console.log('selectedItemCartId::', selectedItemCartId);
  console.log('cartItems::', cartItems);

  return (
    <div id="itemBuilder" className={classes.conatiner}>
      {selectedItemCartId && cartItem ? (
        <CartItemDetail cartItem={cartItem} setCartItemRef={setCartItemRef} />
      ) : undefined}
    </div>
  );
});

export default ItemBuilder;
