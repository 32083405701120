import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectIsLoading } from '../../redux/features/isLoading/isLoading.selector';
import LoadingIndicator from '../LoadingIndicator';
import MainContent from '../MainContent';
import RestaurantStaffDialog from '../RestaurantStaff';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  bodyClass: {
    height: `calc(100vh - ${spacing(8)})`,
  },
  containerClass: {
    height: '100%',
  },
}));

const Body = () => {
  const { bodyClass, containerClass } = useStyles();
  const isLoading = useAppSelector(selectIsLoading);

  return (
    <div id="body" className={bodyClass}>
      <div className={containerClass}>{isLoading ? <LoadingIndicator /> : <MainContent />}</div>
      <RestaurantStaffDialog />
    </div>
  );
};

export default Body;
