import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { getAllInvalidModGroups } from '../utils/cart';

const selectCart = (state: RootState) => state.cart;
const selectCartModality = createSelector(selectCart, (cart) => cart.modality);
const selectCartItems = createSelector(selectCart, (cart) => cart.cartItems);
const selectCartItemsQuantity = createSelector(selectCart, (cart) => cart.cartItemsQuantity);
const invalidModGroupsSelector = createSelector(selectCartItems, (cartItems) => getAllInvalidModGroups(cartItems));

export { selectCart, selectCartItems, selectCartItemsQuantity, invalidModGroupsSelector, selectCartModality };
