import React, { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { selectFeatureFlagTaskRouter } from '../redux/features/config/config.selector';
import { selectRestaurantsById } from '../selectors/restaurant';
import { selectIsLoggedIn } from '../selectors/user';
import UnauthorizedPage from './UnauthorizedPage';

type ProtectedRouteProps = {
  isTaskRouterPage?: boolean;
  isRestaurantPage?: boolean;
};

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, isTaskRouterPage, isRestaurantPage }) => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const location = useLocation();
  const taskRouterFeatureFlag = useAppSelector(selectFeatureFlagTaskRouter);
  const restaurantsById = useAppSelector(selectRestaurantsById);

  if (isTaskRouterPage && !taskRouterFeatureFlag) return <UnauthorizedPage />;

  if (!isLoggedIn) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  if (isRestaurantPage && !Object.values(restaurantsById).length) return <></>;

  return <>{children}</>;
};

export default ProtectedRoute;
