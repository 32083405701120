import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useCallback } from 'react';
import {
  CartItem,
  getCartModGroupInChildModifierGroups,
} from '../../../utils/cart';
import {
  checkForUnavailableRequiredModifierGroup,
  isItem86edToday,
  ParsedMenuItem,
  ParsedModifierGroup,
} from '../../../utils/menu';
import MultipleSelectionModGroup from './MultipleSelectionModGroup';
import SingularSelectionModGroup from './SingularSelectionModGroup';

interface OwnProps {
  modGroup: ParsedModifierGroup;
  cartItem: CartItem;
  selectMenuItemModifier: (
    menuItem: ParsedMenuItem,
    modGroup: ParsedModifierGroup,
    pathToItem: string,
    selected: boolean
  ) => void;
  pathToItem: string;
}

export interface IModGroupStyleProps {
  is86edToday?: boolean;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  modGroupClass: {
    paddingLeft: spacing(2.5),
  },
}));

const ModGroup = (props: OwnProps) => {
  const classes = useStyles();
  const { modGroup, cartItem, selectMenuItemModifier, pathToItem } = props;
  const allModifierUnavailable = !Object.values(modGroup.menuItems).find(
    (menuItem) => {
      if (
        (menuItem.available || isItem86edToday(menuItem)) &&
        !checkForUnavailableRequiredModifierGroup(menuItem.modifierGroups)
      ) {
        return menuItem;
      }
    }
  );
  const cartModGroup = getCartModGroupInChildModifierGroups(modGroup, cartItem);

  // handle default modifier
  if (!cartModGroup) {
    modGroup.defaultSelectedItemIds?.forEach((itemId) => {
      selectMenuItemModifier(
        modGroup.menuItems[itemId],
        modGroup,
        pathToItem,
        true
      );
    });
  }

  const isSingleChoice =
    modGroup.minimumSelections === modGroup.maximumSelections &&
    modGroup.maximumSelections === 1;

  const handleModChange = useCallback(
    (innerModGroup: ParsedModifierGroup) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const itemId = event.target.value;
        const isChecked = isSingleChoice || event.target.checked;
        if (itemId.length > 0) {
          selectMenuItemModifier(
            innerModGroup.menuItems[itemId],
            innerModGroup,
            pathToItem,
            isChecked
          );
        }
      },
    [isSingleChoice, pathToItem, selectMenuItemModifier]
  );

  const modGroupProps = {
    cartItem,
    key: `${modGroup.id}_main_node`,
    modGroup,
    cartModGroup,
    onChange: handleModChange,
    selectMenuItemModifier,
  };

  return (
    <div className={classes.modGroupClass}>
      {allModifierUnavailable ? null : (
        <>
          {isSingleChoice ? (
            <SingularSelectionModGroup
              {...modGroupProps}
              pathToItem={pathToItem}
            />
          ) : (
            <MultipleSelectionModGroup
              {...modGroupProps}
              pathToItem={pathToItem}
            />
          )}
        </>
      )}
    </div>
  );
};

export default memo(ModGroup);
