import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Menu, MenuItem, TextField, Theme, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectRestaurantsById } from '../../selectors/restaurant';
import { restaurantInfoSelector } from '../../utils/restaurants';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1, 2),
  },
  dropdownIcon: {
    color: '#e0e0e0',
  },
  menu: {
    '& .MuiMenu-paper': {
      width: '255px',
      maxHeight: '230px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  input: {
    color: '#616161',
  },
  name: {
    margin: '0 auto',
    color: theme.palette.common.white,
  },
}));

type RestaurantPickerProps = {
  handleRestaurantSelect: Function;
};

const RestaurantPicker: FC<RestaurantPickerProps> = ({ handleRestaurantSelect }) => {
  const classes = useStyles();
  const restaurantsById = useSelector(selectRestaurantsById);
  const selectedRestaurant = useSelector(restaurantInfoSelector);
  const [search, setSearch] = useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const filterRef = React.useRef<HTMLInputElement>();
  const isMenuOpen = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const restaurants = Object.values(restaurantsById);
  const showSearchInput = restaurants.length > 5;
  const filteredRestaurantsEntries =
    showSearchInput && search
      ? restaurants.filter((restaurant) => {
          return restaurant.restaurantName.toLocaleLowerCase().includes(search.toLocaleLowerCase());
        })
      : restaurants;

  const stopPropagation = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Home':
      case 'End':
        break;
      default:
        e.stopPropagation();
    }
  };

  const moveFocusToInput = (e: React.KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Tab' || e.key === 'ArrowRight') {
      e.stopPropagation();
      e.preventDefault();
      if (filterRef?.current) {
        filterRef.current.focus();
      }
    }
  };

  const selectRestaurant = (restaurantCode: string, primaryRestaurantCode: string) => {
    setSearch('');
    handleClose();
    handleRestaurantSelect(restaurantCode, primaryRestaurantCode);
  };

  return (
    <>
      <Button
        disableElevation
        aria-controls="restaurant-picker"
        aria-haspopup="true"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        className={classes.button}
        color="secondary"
        variant="contained"
      >
        {selectedRestaurant?.restaurantName || 'Choose One'}
        <ArrowDropDownIcon />
      </Button>
      <Menu id="restaurant-picker" className={classes.menu} keepMounted anchorEl={anchorEl} open={isMenuOpen} onClose={handleClose} variant="menu">
        {showSearchInput && (
          <MenuItem onKeyDown={moveFocusToInput}>
            <TextField
              data-testid="restaurant-search"
              className={classes.input}
              inputRef={filterRef}
              value={search}
              onKeyDown={stopPropagation}
              onChange={(e) => {
                setSearch(e.currentTarget.value);
              }}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
            />
          </MenuItem>
        )}
        {filteredRestaurantsEntries.map((restaurant) => {
          return (
            <MenuItem
              key={restaurant.restaurantCode}
              data-testid="restaurant"
              onClick={() => {
                if (selectedRestaurant?.restaurantCode !== restaurant.restaurantCode) {
                  selectRestaurant(restaurant.restaurantCode, restaurant.primaryRestaurantCode as string);
                }
              }}
            >
              <Tooltip title={restaurant.restaurantCode} arrow>
                <Typography>{restaurant.restaurantName}</Typography>
              </Tooltip>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default RestaurantPicker;
