import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import MemoryIcon from '@mui/icons-material/Memory';
import MicIcon from '@mui/icons-material/Mic';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import { green, grey, red, yellow } from '@mui/material/colors';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CheckResponseTypes } from '../../constants/event';
import useAIMessaging from '../../features/messaging/useAIMessaging.hooks';
import { messagingActions } from '../../reducers/messagingSlice';
import { Player } from '../Audio/Player';
import { VisualIndicator } from '../VisualIndicator';
import {
  selectCurrentTransactionId,
  selectOrderError,
  selectTransactions,
} from '../../selectors/order';
import HealthStatusCheckIcon from '../VisualIndicator/HealthStatusCheckIcon';
import { Timer } from '../VisualIndicator/Timer';
import {
  OrderStatus,
  TransactionStatus,
} from '../../reducers/orderSlice.constants';
import { resetCurrentTransactionId } from '../../reducers/orderSlice';
import { ORDER_STARUS_ICON_RESET_INTERVAL } from '../../constants';

const HeaderIndicators: FC = () => {
  const dispatch = useAppDispatch();
  const isCarPresent = useAppSelector((state) => state.messages.isCarPresent);
  const isNewSession = useAppSelector((state) => state.messages.isNewSession);
  const isConnected = useAppSelector((state) => state.messages.isConnected);
  const isStaffIntervention = useAppSelector(
    (state) => state.messages.isStaffIntervention
  );
  const [orderStatus, setOrderStatus] = useState(OrderStatus.noOrder);
  const sendStaffInterventionMetric = () => {
    //TODO
  };
  const isTTSOn = useAppSelector((state) => state.messages.isTTSOn);
  const currentTransactionId = useAppSelector(selectCurrentTransactionId);
  const transactions = useAppSelector(selectTransactions);
  const orderError = useAppSelector(selectOrderError);
  const transaction = currentTransactionId
    ? transactions[currentTransactionId]
    : null;

  useEffect(() => {
    if (orderError) {
      setOrderStatus(OrderStatus.failed);
    } else if (!transaction) {
      setOrderStatus(OrderStatus.noOrder);
    } else {
      if (
        transaction?.isFinal &&
        transaction.status === TransactionStatus.completed
      ) {
        setOrderStatus(OrderStatus.completed);
        setTimeout(() => {
          dispatch(resetCurrentTransactionId());
        }, ORDER_STARUS_ICON_RESET_INTERVAL);
      } else if (
        transaction.status === TransactionStatus.pending ||
        transaction.status === TransactionStatus.completed
      ) {
        setOrderStatus(OrderStatus.pending);
      } else if (transaction.status === TransactionStatus.error) {
        setOrderStatus(OrderStatus.failed);
      } else {
        setOrderStatus(OrderStatus.noOrder);
      }
    }
  }, [transaction, transaction?.status, orderError]);

  const getOrderStatusColor = (status: string) => {
    switch (status) {
      case OrderStatus.noOrder:
        return grey['500'];
      case OrderStatus.completed:
        return green['A400'];
      case OrderStatus.pending:
        return yellow['500'];
      case OrderStatus.failed:
        return red['700'];
      default:
        return '';
    }
  };

  useEffect(() => {
    dispatch(messagingActions.sendTTSRequest({} as any));
  }, [isConnected, dispatch]);

  const { isAIActive, toggleAIIndicator } = useAIMessaging();

  return (
    <>
      <HealthStatusCheckIcon />
      <VisualIndicator
        isActive={isAIActive}
        onClick={toggleAIIndicator}
        IconComponent={MemoryIcon}
      />
      <Player />
      <VisualIndicator IconComponent={MicIcon} isActive={isTTSOn} />
      <VisualIndicator
        IconComponent={NetworkCheckIcon}
        isActive={isConnected}
      />
      <VisualIndicator
        IconComponent={HeadsetMicIcon}
        isActive={isStaffIntervention}
        onClick={sendStaffInterventionMetric}
      />
      <VisualIndicator
        IconComponent={DirectionsCarIcon}
        isActive={isCarPresent || isNewSession}
      />
      <VisualIndicator
        IconComponent={AttachMoneyIcon}
        customizedColor={getOrderStatusColor(orderStatus)}
      />
      <Timer />
    </>
  );
};

export default HeaderIndicators;
