import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { messagingActions } from '../../reducers/messagingSlice';
import {
  IRestaurantInfo,
  selectRestaurant,
} from '../../reducers/restaurantSlice';
import {
  forceLogOutUserSession,
  updateUserSession,
} from '../../reducers/userSlice';
import {
  selectFeatureFlagTaskRouter,
  selectNodeEnv,
} from '../../redux/features/config/config.selector';
import { SOURCE } from '../../utils/constants';
import { getAuthToken } from '../../utils/local-storage';
import { getForceLogOutApiUrl } from '../../utils/network';
import ActiveSessionConfirmDialog from '../Restaurant/ActiveSessionConfirmDialog';
import RestaurantPicker from '../RestaurantPicker';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  name: {
    fontWeight: '500 !important',
    fontSize: '0.875rem !important',
    lineHeight: '1.75 !important',
    textTransform: 'uppercase',
    minWidth: spacing(8),
    padding: `${spacing(0.75)} ${spacing(2)}`,
  },
}));

type RestaurantsContainerProps = {
  targetRestaurant?: IRestaurantInfo;
};

const RestaurantsContainer: FC<RestaurantsContainerProps> = ({
  targetRestaurant,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isPlaying = useAppSelector((state) => state.messages.isPlaying);
  const env = useSelector(selectNodeEnv);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<{
    restaurantCode: string;
    primaryRestaurantCode: string | null;
    data: [any];
  }>();
  const taskRouterFeatureFlag = useSelector(selectFeatureFlagTaskRouter);

  const selectUpdatedRestaurant = (
    restaurantCode: string,
    primaryRestaurantCode: string
  ) => {
    dispatch(updateUserSession(restaurantCode));
    dispatch(
      selectRestaurant({
        restaurantCode: restaurantCode,
        primaryRestaurantCode: primaryRestaurantCode,
      })
    );

    // Reset state of cars
    if (!taskRouterFeatureFlag) {
      dispatch(messagingActions.resetCarState());
    }

    if (!isPlaying) {
      dispatch(messagingActions.setIsPlaying(true));
    }
  };

  const onConfirm = (restaurantCode: string, primaryRestaurantCode: string) => {
    selectUpdatedRestaurant(restaurantCode, primaryRestaurantCode);
    setShowConfirmModal(false);
  };

  const fetchActiveSession = (restaurantCode: string) => {
    const authToken = getAuthToken();
    if (!authToken) {
      return;
    }
    try {
      const url = getForceLogOutApiUrl(env);
      const result = fetch(
        `${url}?source_module=${SOURCE}&restaurant_code=${restaurantCode}&active=1&force_reload=0`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      return result;
    } catch (error) {
      console.error('Failed to fetching session', error);
    }
  };

  const handleRestaurantSelect = (
    restaurantCode: string,
    primaryRestaurantCode: any
  ) => {
    fetchActiveSession(restaurantCode)?.then((response) => {
      if (response.status !== 200) {
        selectUpdatedRestaurant(restaurantCode, primaryRestaurantCode);
      } else {
        response.json().then((data) => {
          setDialogData({ restaurantCode, primaryRestaurantCode, data });
          setShowConfirmModal(true);
        });
      }
    });
  };

  useEffect(() => {
    if (targetRestaurant)
      handleRestaurantSelect(
        targetRestaurant.restaurantCode,
        targetRestaurant.primaryRestaurantCode
      );
  }, [targetRestaurant]);

  return (
    <div id="restaurants-container">
      {dialogData && (
        <ActiveSessionConfirmDialog
          content={dialogData}
          open={showConfirmModal}
          onForceLogOut={(selectedSessions: any[]) => {
            if (dialogData.data) {
              dispatch(
                forceLogOutUserSession({
                  restarauntCode: dialogData.restaurantCode,
                  activeSessions: selectedSessions,
                })
              );
            }
            onConfirm(
              dialogData.restaurantCode,
              dialogData.primaryRestaurantCode as string
            );
          }}
          onConfirm={() => {
            onConfirm(
              dialogData.restaurantCode,
              dialogData.primaryRestaurantCode as string
            );
          }}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
      {targetRestaurant ? (
        <Typography className={classes.name}>
          {targetRestaurant?.restaurantName}
        </Typography>
      ) : (
        <RestaurantPicker handleRestaurantSelect={handleRestaurantSelect} />
      )}
    </div>
  );
};

export default RestaurantsContainer;
