import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useShallowSelector } from '../../app/hooks';
import { getCodeNameMapping } from '../../selectors/menu';
import { CartItem, CartModifierGroup } from '../../utils/cart';
import Colors from '../../utils/color';
import { DUMMY_MOD_SYMBOL } from '../../utils/constants';
import { getMenuItemPrice } from '../../utils/menu';
import { printCurrency } from '../../utils/money';
import CartItemModGroups from './CartItemModGroups';

interface IChildModGroup {
  modGroup: CartModifierGroup;
  quantity: number;
  cartItem: CartItem;
}

const useStyles = makeStyles((theme: Theme) => ({
  itemNameClass: {
    wordBreak: 'break-all',
    fontSize: `${theme.spacing(1.5)} !important`,
  },
  childModGroupClass: {
    fontSize: theme.spacing(1.5),
    paddingBottom: (hasChildren) => (hasChildren ? theme.spacing(1) : 0),
  },
  itemPriceClass: {
    fontSize: `${theme.spacing(1.5)} !important`,
    paddingLeft: theme.spacing(2),
  },
}));

export default function ChildModGroup({ modGroup, cartItem, quantity }: IChildModGroup) {
  const codeNameMapping = useShallowSelector(getCodeNameMapping);
  const { sortOrder, selectedItems, minimumSelections, name } = modGroup;

  const modSortOrder = sortOrder.reduce((acc: Record<string, number>, sortValue) => {
    const id: number = sortValue.id || 0;
    acc[id.toString()] = sortValue.sortOrder || 0;
    return acc;
  }, {});
  const sortedSelectedItems = Object.values(selectedItems || {}).sort((a, b) => modSortOrder[a.id] - modSortOrder[b.id]);
  const hasChildModifierGroups = !!sortedSelectedItems.find((item) => Object.keys(item.childModifierGroups).length);
  const { itemNameClass, childModGroupClass, itemPriceClass } = useStyles(hasChildModifierGroups);

  return (
    <div className={`cartitem-child-modgroup ${childModGroupClass}`}>
      {sortedSelectedItems.length < minimumSelections ? <Typography color={Colors.Thunderbird} variant="subtitle2" children={'! ' + name} /> : null}
      {sortedSelectedItems.map((item) => {
        const menuItemPrice = getMenuItemPrice(item, item.modality, quantity);
        return (
          <div key={item.id}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="subtitle2" className={itemNameClass}>
                {item.modcode && codeNameMapping[item.modcode].code !== DUMMY_MOD_SYMBOL ? codeNameMapping[item.modcode].name + ' ' : ''}
                {item.name}
              </Typography>
              {menuItemPrice > 0 && (
                <Typography variant="subtitle2" className={itemPriceClass}>
                  {printCurrency(menuItemPrice, 'USD')}
                </Typography>
              )}
            </Box>
            {item && item.childModifierGroups && <CartItemModGroups modGroupCartItem={item} cartItem={cartItem} quantity={quantity} />}
          </div>
        );
      })}
    </div>
  );
}
