import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector, useShallowSelector } from '../../app/hooks';
import { CartItemReview } from '../../components/OrderReview/CartItemReview';
import CouponApplyStatus from '../../components/OrderReview/CouponApplyStatus';
import CouponItemReview from '../../components/OrderReview/CouponItemReview';
import { sendOrder } from '../../reducers/orderSlice';
import { selectIsPosmonReconnected } from '../../selectors/order';
import { isValidCartSelector } from '../../utils/cart';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: '110px',
  },
}));

function OrderReview() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    cartItems,
    cartItemsQuantity,
    couponItem,
    couponApplyStatus,
    couponCode,
  } = useShallowSelector((state) => state.cart);
  const cartValid = useSelector(isValidCartSelector);
  const isPosmonReconnected = useAppSelector(selectIsPosmonReconnected);

  useEffect(() => {
    if (Object.values(cartItems).length > 0) {
      dispatch(sendOrder({ cartItems, cartValid }));
    }
  }, [cartItems, cartItemsQuantity, cartValid]);

  useEffect(() => {
    if (
      Object.values(cartItems).length > 0 &&
      couponItem &&
      !couponItem.isApplied
    ) {
      dispatch(sendOrder({ cartItems, cartValid }));
    }
  }, [couponItem]);

  useEffect(() => {
    if (Object.values(cartItems).length > 0 && isPosmonReconnected) {
      dispatch(sendOrder({ cartItems, cartValid }));
    }
  }, [isPosmonReconnected]);

  const cartItemsState = useAppSelector(
    (state) => state.order.currentTransactionItems
  );

  return (
    <div id="orderReview" className={classes.container}>
      {Object.values(cartItems).map((cartItem) => {
        return (
          <CartItemReview
            key={cartItem.cartItemId}
            status={cartItemsState[cartItem.cartItemId]}
            cartItem={cartItem}
            quantity={cartItemsQuantity[cartItem.cartItemId]}
          />
        );
      })}
      {/* Display Coupon Item */}
      {couponItem && <CouponItemReview item={couponItem} />}

      {/* Display coupon apply status */}
      {!couponItem && couponApplyStatus && couponCode && (
        <CouponApplyStatus status={couponApplyStatus} couponCode={couponCode} />
      )}
    </div>
  );
}

export default memo(OrderReview);
