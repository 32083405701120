import { useEffect, useRef, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  TASKROUTER_RECONNECT_ATTEMPTS as reconnectAttempts,
  TASKROUTER_RECONNECT_INTERVAL as reconnectInterval,
  TASKROUTER_WSLINK,
} from '../../constants';
import { messagingActions } from '../../reducers/messagingSlice';
import { selectNodeEnv } from '../../redux/features/config/config.selector';
import { stopLoading } from '../../redux/features/isLoading/isLoading.slice';
import { TaskStatuses } from '../../redux/features/taskRouter/taskRouter.constants';
import {
  selectIsAgentAvailable,
  selectTasksById,
  selectTaskStatusChanged,
} from '../../redux/features/taskRouter/taskRouter.selector';
import {
  addNewTask,
  resetTaskRouterData,
  setWSConnectionMessage,
  toggleAgentAvailability,
  updateTaskStatus,
} from '../../redux/features/taskRouter/taskRouter.slice';
import { selectRestaurantsById } from '../../selectors/restaurant';
import { selectUserProfile } from '../../selectors/user';
import { getWebsocketUrl } from '../../utils/network';

const useTaskRouterWS = () => {
  const didUnmount = useRef(false);
  const dispatch = useAppDispatch();
  const isAgentAvailable = useAppSelector(selectIsAgentAvailable);
  const tasksById = useAppSelector(selectTasksById);
  const taskStatusChanged = useAppSelector(selectTaskStatusChanged);
  const userDetails = useAppSelector(selectUserProfile);
  const restaurantsById = useAppSelector(selectRestaurantsById);

  const socketUrl = getWebsocketUrl(
    useAppSelector(selectNodeEnv),
    TASKROUTER_WSLINK
  );

  const [url, setUrl] = useState<string | null>(socketUrl);

  useEffect(() => {
    dispatch(resetTaskRouterData());
    return () => {
      didUnmount.current = true;
    };
  }, []);

  const onOpen = () => {};
  const onClose = () => {};
  const onError = () => {
    const errorMessage =
      'There was an error connecting to websocket - Retrying...';
    //@ts-ignore
    window.newrelic?.noticeError(errorMessage);
    dispatch(setWSConnectionMessage(errorMessage));
  };
  const shouldReconnect = (closeEvent: any) => {
    const isClosedManually = closeEvent.code === 1005;
    return isClosedManually ? false : didUnmount.current === false;
  };
  const onReconnectStop = (numAttempted: number) => {
    const errorMessage = `Unable to establish websocket connection. Maximum reconnect attempts(${numAttempted}) reached`;
    //@ts-ignore
    window.newrelic?.noticeError(errorMessage);
    dispatch(setWSConnectionMessage(errorMessage));
  };

  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    url,
    {
      onOpen,
      onClose,
      onError,
      shouldReconnect,
      onReconnectStop,
      reconnectInterval,
      reconnectAttempts,
      retryOnError: true,
    }
  );

  const processTaskRouterMessage = () => {
    const latestTaskDetails = JSON.parse(lastMessage?.data);
    const {
      event = TaskStatuses.none,
      data: {
        task_id: taskId = '',
        restaurant_code: restaurantCode = '',
        user_details: userDetailsFromWSRes = {},
      } = {},
    } = latestTaskDetails;

    if (taskId && restaurantsById[restaurantCode]) {
      if (tasksById[taskId]) {
        //Task status changed
        if (userDetailsFromWSRes.email === userDetails?.email) {
          if (event !== TaskStatuses.accepted) {
            if (event === TaskStatuses.assigned) {
              dispatch(messagingActions.carEnter());
            }
            dispatch(updateTaskStatus({ taskId, event }));
          }
        } else {
          dispatch(updateTaskStatus({ taskId, event: TaskStatuses.rejected }));
        }
      } else {
        //Received a new task
        dispatch(addNewTask(latestTaskDetails));
      }
    }
    if (taskStatusChanged?.data?.task_id === taskId) {
      dispatch(stopLoading());
    }
  };

  useEffect(() => {
    if (lastMessage !== null) {
      processTaskRouterMessage();
    }
  }, [lastMessage]);

  useEffect(() => {
    if (taskStatusChanged && userDetails) {
      const task = { ...taskStatusChanged };
      const { id, username, email, firstName, lastName } = userDetails;
      task.data = {
        ...task.data,
        user_details: { id, username, email, firstName, lastName },
      };
      sendMessage(JSON.stringify(task));
      if (
        [TaskStatuses.canceled, TaskStatuses.completed].includes(task.event)
      ) {
        dispatch(messagingActions.resetCarState());
      }
    }
  }, [taskStatusChanged]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const handleAgentAvailability = () => {
    if (isAgentAvailable) {
      getWebSocket()?.close();
      setUrl(null);
      dispatch(toggleAgentAvailability());
    } else {
      dispatch(resetTaskRouterData());
      setUrl(socketUrl);
    }
  };

  return {
    connectionStatus,
    handleAgentAvailability,
  };
};

export default useTaskRouterWS;
