import { createRouterMiddleware, createRouterReducer, ReduxRouterState } from '@lagunovsky/redux-react-router';
import { Action, AnyAction, combineReducers, configureStore, Dispatch, Reducer, ThunkAction } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import cartReducer from '../reducers/cartSlice';
import dialogReducer from '../reducers/dialogSlice';
import errorReducer from '../reducers/errorSlice';
import menuReducer from '../reducers/menuSlice';
import messagesReducer from '../reducers/messagingSlice';
import orderReducer from '../reducers/orderSlice';
import restaurantReducer from '../reducers/restaurantSlice';
import userReducer from '../reducers/userSlice';
import aiReducer from '../redux/features/ai/ai.slice';
import configReducer from '../redux/features/config/config.slice';
import healthStatusCheckReducer from '../redux/features/healthStatusCheck/healthStatusCheck.slice';
import isLoadingReducer from '../redux/features/isLoading/isLoading.slice';
import taskRouterReducer from '../redux/features/taskRouter/taskRouter.slice';
import { messagingTransformMiddleware, socketMiddleware } from './middleware';

const history = createBrowserHistory();
const appReducer = combineReducers({
  router: createRouterReducer(history) as Reducer<ReduxRouterState, AnyAction>,
  isLoading: isLoadingReducer,
  messages: messagesReducer,
  menu: menuReducer,
  cart: cartReducer,
  ai: aiReducer,
  user: userReducer,
  restaurant: restaurantReducer,
  error: errorReducer,
  order: orderReducer,
  dialog: dialogReducer,
  config: configReducer,
  healthStatusCheck: healthStatusCheckReducer,
  taskRouter: taskRouterReducer,
});

const rootReducer = (state: any, action: Action) => {
  if (action.type === 'user/logout') {
    // reset all state except user state when logout action is dispatched
    const { user, ...rest } = state;
    const newState: any = {};
    Object.keys(state).forEach((key) => {
      if (key in rest) {
        newState[key] = undefined;
      }
    });

    state = {
      ...newState,
      user,
    };
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([createRouterMiddleware(history), messagingTransformMiddleware, socketMiddleware]),
});

export type AppDispatch = typeof store.dispatch;
export type RootStore = { getState: () => RootState; dispatch: Dispatch };
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
