import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import ErrorPage from './ErrorPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import LoginPage from './LoginPage';
import ProtectedRoute from './ProtectedRoute';
import RestaurantPage from './RestaurantPage';
import TaskRouterPage from './TaskRouterPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordPage />,
  },
  {
    path: '/task-router',
    element: (
      <ProtectedRoute isTaskRouterPage>
        <TaskRouterPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/:restaurantId',
    element: (
      <ProtectedRoute isRestaurantPage>
        <RestaurantPage />
      </ProtectedRoute>
    ),
  },
]);

export default router;
