import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

const selectConfig = (state: RootState) => state.config;
const selectNodeEnv = createSelector(selectConfig, (state) => state.NODE_ENV);
const selectFeatureFlagTaskRouter = createSelector(
  selectConfig,
  (state) => state.FEATUREFLAG_TASKROUTER
);
const selectTaskRouterWaitingTime = createSelector(
  selectConfig,
  (state) => state.TASKROUTER_WAITING_TIME
);

export {
  selectConfig,
  selectNodeEnv,
  selectFeatureFlagTaskRouter,
  selectTaskRouterWaitingTime,
};
