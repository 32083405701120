import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { AlertProviderProps, Provider as AlertProvider } from 'react-alert';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { store } from './app/store';
import AlertTemplate from './components/common/AlertTemplate';
import ErrorBoundaryProvider from './components/ErrorBoundary';
import AuthProvider from './contexts/AuthContext/AuthProvider';
import './index.css';
import router from './pages/router';
import theme from './utils/theme';

const options: AlertProviderProps = {
  template: AlertTemplate,
  position: 'top center',
  offset: '130px',
  transition: 'scale',
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <ErrorBoundaryProvider>
          <AlertProvider {...options}>
            <AuthProvider>
              <RouterProvider router={router} />
            </AuthProvider>
          </AlertProvider>
        </ErrorBoundaryProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

if (window.hasOwnProperty('Cypress')) {
  (window as any).store = store;
}
