import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

const selectRestaurant = (state: RootState) => state.restaurant;
const selectActiveRestaurantCode = createSelector(selectRestaurant, (state) => state.selectedRestaurantCode);
const selectRestaurantsByUserRole = createSelector(selectRestaurant, (restaurant) => restaurant.restaurantsByUserRole);
const selectRestaurantsById = createSelector(selectRestaurant, (restaurant) => restaurant.restaurantsById);

const selectTimezone = (state: RootState) => state.restaurant.selectedRestaurant?.hoursOfOperation?.timezone;
const selectCurrentUTCTime = (state: RootState) => state.restaurant.selectedRestaurant?.hoursOfOperation?.currentUTCTime;
const selectedStageSelector = (state: RootState) => state.restaurant.selectedStage;

export { selectRestaurant, selectActiveRestaurantCode, selectTimezone, selectCurrentUTCTime, selectRestaurantsByUserRole, selectRestaurantsById, selectedStageSelector };
