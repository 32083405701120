import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC } from 'react';
import { useAppSelector } from '../../app/hooks';
import { selectIsAgentAvailable } from '../../redux/features/taskRouter/taskRouter.selector';
import { selectUserFullName } from '../../selectors/user';
import Colors from '../../utils/color';

type TaskRouterHeaderStyleProps = {
  isAgentAvailable: boolean;
};

type TaskRouterHeaderProps = {
  handleAgentAvailability?: () => void;
};

const useStyles = makeStyles<Theme, TaskRouterHeaderStyleProps>(
  ({ spacing }) => ({
    boxClass: {
      paddingLeft: spacing(2),
      width: spacing(19),
    },
    formControlClass: {
      backgroundColor: ({ isAgentAvailable }) =>
        `${isAgentAvailable ? Colors.readBack : 'unset'}`,
      height: '36px',
      '&.MuiFormControl-root': {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: ({ isAgentAvailable }) =>
          `${isAgentAvailable ? Colors.readBack : Colors.white}`,
      },
      borderRadius: spacing(0.5),
      '& .MuiInputLabel-root.MuiInputLabel-formControl': {
        top: spacing(-1),
        left: spacing(-0.5),
        color: `${Colors.white} !important`,
      },
      '& .MuiInputBase-formControl': {
        height: '36px',
        color: `${Colors.white} !important`,
        margin: '1px',
        '& .MuiSelect-select': {
          fontSize: spacing(1.75),
          paddingLeft: spacing(1),
          paddingTop: spacing(2.5),
        },
        '& .MuiSelect-icon': {
          color: `${Colors.white} !important`,
        },
      },
    },
  })
);

const TaskRouterHeaderInfo: FC<TaskRouterHeaderProps> = ({
  handleAgentAvailability,
}) => {
  const isAgentAvailable = useAppSelector(selectIsAgentAvailable);
  const { boxClass, formControlClass } = useStyles({ isAgentAvailable });
  const userName = useAppSelector(selectUserFullName);

  return (
    <Box className={boxClass}>
      <FormControl fullWidth variant="filled" className={formControlClass}>
        <InputLabel id="task-router-user-name">{userName}</InputLabel>
        <Select
          labelId="task-router-user-name"
          data-testid="task-router-user-availability-select"
          value={isAgentAvailable ? 1 : 0}
          label={userName}
          onChange={handleAgentAvailability}
        >
          <MenuItem value={1}>Available</MenuItem>
          <MenuItem value={0}>Unavailable</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default TaskRouterHeaderInfo;
