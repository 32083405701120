import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useMemo } from 'react';
import Colors from '../../../utils/color';
import { ParsedModifierGroup } from '../../../utils/menu';

const useStyles = makeStyles((theme: Theme) => ({
  modGroupLabelClass: {
    fontSize: `${theme.spacing(1.5)} !important`,
  },
}));

export function ModGroupLabel({ modGroup, selectedModNumber }: { modGroup: ParsedModifierGroup; selectedModNumber: Number }) {
  const { modGroupLabelClass } = useStyles();
  const isFree = modGroup.maximumSelections <= 0;
  const isSingleChoice = modGroup.minimumSelections === modGroup.maximumSelections;
  const isAtLeastOneRequired = modGroup.minimumSelections > 0;
  const isReqiuredMinimumNotMet = isAtLeastOneRequired && modGroup.minimumSelections > selectedModNumber;
  const optionsCount = Object.values(modGroup.menuItems).length;
  const max = modGroup.maximumSelections !== -1 ? Math.min(modGroup.maximumSelections, optionsCount) : optionsCount;

  const subText = useMemo(() => {
    if (isFree) return '';
    if (isSingleChoice) return `(select ${modGroup.minimumSelections})`;
    if (isAtLeastOneRequired) return `(select between ${modGroup.minimumSelections} and ${max})`;
    return `(select up to ${max})`;
  }, []);

  const modGroupText = modGroup.name + ' ' + subText;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ flex: 1 }}>
        {isReqiuredMinimumNotMet ? (
          <Typography variant="subtitle2" className={modGroupLabelClass} color={Colors.Thunderbird} children={'! ' + modGroupText} style={{ fontWeight: 'bold' }} />
        ) : (
          <Typography variant="subtitle2" className={modGroupLabelClass} color="textPrimary" children={modGroupText} />
        )}
      </Box>
    </Box>
  );
}
