enum TransitionTypes {
  slideOut = 'slide-out',
  fadeOut = 'fade-out',
}

enum Symbols {
  plus = '+',
  minus = '-',
  star = '*',
}

enum MenuStages {
  PLAYGROUND = 'PLAYGROUND',
  PRELIVE = 'PRELIVE',
  LIVE = 'LIVE',
}

enum MenuStagesLabel {
  PLAYGROUND = 'Draft',
  LIVE = 'Live',
  PRELIVE = '',
}

enum NodeEnv {
  production = 'PRODUCTION',
  staging = 'STAGING',
  development = 'DEVELOPMENT',
  sandbox = 'SANDBOX',
  test = 'TEST',
}

export { TransitionTypes, Symbols, NodeEnv, MenuStages, MenuStagesLabel };
