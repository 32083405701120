import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { TaskStatuses } from './taskRouter.constants';

const selectTaskRouter = (state: RootState) => state.taskRouter;
const selectIsAgentAvailable = createSelector(
  selectTaskRouter,
  (taskRouter) => taskRouter.isAgentAvailable
);
const selectTasksById = createSelector(
  selectTaskRouter,
  (taskRouter) => taskRouter.tasksById
);
const selectTasksOrder = createSelector(
  selectTaskRouter,
  (taskRouter) => taskRouter.tasksOrder
);
const selectWSConnectionMessage = createSelector(
  selectTaskRouter,
  (taskRouter) => taskRouter.wsConnectionMessage
);
const selectTasksOffered = createSelector(
  selectTasksOrder,
  selectTasksById,
  (tasksOrder, tasksById) =>
    tasksOrder.filter((id) => tasksById[id].event === TaskStatuses.offered)
);

const selectCurrentTask = createSelector(
  selectTaskRouter,
  (taskRouter) => taskRouter.currentTaskId
);
const selectTasksAssigned = createSelector(
  selectTasksOrder,
  selectTasksById,
  (tasksOrder, tasksById) =>
    tasksOrder.filter((id) => tasksById[id].event === TaskStatuses.assigned)
);

const selectTaskStatusChanged = createSelector(
  selectTasksById,
  selectCurrentTask,
  (tasksById, currentTaskId) => {
    if (tasksById && currentTaskId) {
      const task = tasksById[currentTaskId];
      if (
        (task && task.event === TaskStatuses.accepted) ||
        task.event === TaskStatuses.canceled ||
        task.event === TaskStatuses.completed
      ) {
        return task;
      }
    }
  }
);

export {
  selectTaskRouter,
  selectIsAgentAvailable,
  selectTasksById,
  selectTasksOrder,
  selectTasksOffered,
  selectWSConnectionMessage,
  selectTasksAssigned,
  selectCurrentTask,
  selectTaskStatusChanged,
};
