import { Button, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { default as React, FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import useEventListener from '../../hooks/useEventListener.hooks';
import { selectTaskRouterWaitingTime } from '../../redux/features/config/config.selector';
import {
  startLoading,
  stopLoading,
} from '../../redux/features/isLoading/isLoading.slice';
import { TaskStatuses } from '../../redux/features/taskRouter/taskRouter.constants';
import { selectTasksOffered } from '../../redux/features/taskRouter/taskRouter.selector';
import { updateTaskStatus } from '../../redux/features/taskRouter/taskRouter.slice';
import CountdownTimer from '../CountdownTimer';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  taskRouterAcceptanceClass: {
    display: 'flex',
    columnGap: spacing(8),
  },
  actionButtonClass: {
    marginTop: `${spacing(2)} !important`,
    textTransform: 'uppercase',
  },
  timerWrapperClass: {
    display: 'flex',
  },
}));

interface ITaskRouterAcceptanceProps {}

const TaskRouterAcceptance: FC<ITaskRouterAcceptanceProps> = ({}) => {
  const { taskRouterAcceptanceClass, actionButtonClass, timerWrapperClass } =
    useStyles();
  const dispatch = useAppDispatch();
  const areTasksOffered = useAppSelector(selectTasksOffered);
  const waitingTime = useAppSelector(selectTaskRouterWaitingTime);

  const handleTaskAccept = () => {
    if (areTasksOffered?.length) {
      dispatch(startLoading());
      dispatch(
        updateTaskStatus({
          taskId: areTasksOffered[0],
          event: TaskStatuses.accepted,
        })
      );
      setTimeout(() => {
        dispatch(stopLoading());
      }, waitingTime);
    }
  };

  const handleTimerComplete = () => {
    //Taking an assumption that if the agent doesn't accept before stipulated time, it is being rejected
    dispatch(
      updateTaskStatus({
        taskId: areTasksOffered[0],
        event: TaskStatuses.rejected,
      })
    );
  };

  const onSpaceBarClick = (e: Event) => {
    if (e instanceof KeyboardEvent && e.code === 'Space') {
      handleTaskAccept();
    }
  };

  useEventListener('keyup', onSpaceBarClick);

  return (
    <div data-testid="tr-acceptance" className={taskRouterAcceptanceClass}>
      <div>
        <Typography variant="h4" gutterBottom>
          New task available!
        </Typography>
        <Typography>Press Spacebar to accept</Typography>
        <Typography>or click the button below</Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleTaskAccept}
          className={actionButtonClass}
        >
          accept task
        </Button>
      </div>
      <div className={timerWrapperClass}>
        <CountdownTimer
          size={150}
          onComplete={handleTimerComplete}
          duration={waitingTime / 1000}
        />
      </div>
    </div>
  );
};

export default TaskRouterAcceptance;
