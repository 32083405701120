import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { CartModifierGroup } from '../utils/cart';
import { DUMMY_MOD_SYMBOL } from '../utils/constants';
import { ParsedMenuItem, ParsedModifierGroup, TopLevelMenuItem } from '../utils/menu';
import { GenericMap } from '../utils/types';

const selectMenu = (state: RootState) => state.menu;

export function getModSymbolMapping(state: RootState) {
  return state.menu.modSymbolMapping;
}

export function getCodeNameMapping(state: RootState) {
  return state.menu.codeNameMapping;
}

export function generateMenuItemsSelector(currentMessage: string) {
  return (state: RootState): (ParsedMenuItem | TopLevelMenuItem)[] => {
    const {
      cart: { cartItems },
      dialog: { selectedItem: dialogSelectedItem, selectedItemCartId },
      menu: { modSymbolMapping, topLevelMenuItems },
    } = state;
    const cartItemsValues = Object.values(cartItems);
    const selectedItem = dialogSelectedItem || cartItemsValues[cartItemsValues.length - 1];
    let currentItems: GenericMap<ParsedMenuItem | TopLevelMenuItem> = topLevelMenuItems;
    let currentMods: GenericMap<ParsedMenuItem | TopLevelMenuItem> = {};

    if (selectedItem) {
      let modGroups = Object.values(selectedItem.modifierGroups);

      let childrenMods = Object.assign(cartItems[selectedItemCartId]?.childModifierGroups || {});
      recurviselyGetMods(childrenMods, modGroups);
      modGroups.forEach((modGroup) => {
        currentMods = { ...currentMods, ...modGroup.menuItems };
        currentItems = { ...currentItems, ...modGroup.menuItems };
      });
    }

    const firstWord = currentMessage.split(' ')[0];
    const modSymbol = firstWord === DUMMY_MOD_SYMBOL ? DUMMY_MOD_SYMBOL : Object.keys(modSymbolMapping).filter((s) => firstWord.startsWith(s))[0];
    let itemsList = modSymbol ? Object.values(currentMods) : Object.values(currentItems);

    return itemsList;
  };
}
function recurviselyGetMods(childrenMods: GenericMap<CartModifierGroup>, modGroups: ParsedModifierGroup[]) {
  const children = Object.values(childrenMods || {})
    .flatMap((child) => Object.values(child.selectedItems))
    .flatMap(({ modifierGroups, childModifierGroups }) => {
      recurviselyGetMods(childModifierGroups, modGroups);
      return Object.values(modifierGroups);
    });
  modGroups.push(...children);
}

export function selectPersistentVoiceProps(state: RootState) {
  return state.menu.persistentVoiceProps;
}

export const selectMenuRes = createSelector(selectMenu, (menu) => menu.menuRes);

export const selectAvailableCategoryWithTimePeriod = createSelector(selectMenu, (menu) => menu.availableCategoryWithTimePeriod);

export const selectTopLevelMenuItems = createSelector(selectMenu, (menu) => menu.topLevelMenuItems);

export const selectedMenuVersionSelector = createSelector(selectMenu, (menu) => menu.selectedMenuVersion);
export const menuVersionsSelector = createSelector(selectMenu, (menu) => menu.menuVersions);
export const prodLiveVersionSelector = createSelector(selectMenu, (menu) => menu.prodLiveVersion);
