import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { fetchMenu } from '../../../reducers/menuSlice';

const isLoadingSlice = createSlice({
  name: 'isLoading',
  initialState: false,
  reducers: {
    startLoading: () => {
      return true;
    },
    stopLoading: () => {
      return false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(fetchMenu.fulfilled, fetchMenu.rejected), () => {
      return false;
    });
  },
});

export const { startLoading, stopLoading } = isLoadingSlice.actions;
export default isLoadingSlice.reducer;
