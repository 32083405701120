import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Image } from '../components/Layout/Image';
import prestoLogo from '../images/presto_logo_white.png';
import Colors from '../utils/color';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  unauthorizedPageClass: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.daintree,
    color: Colors.white,
  },
  prestoLogoClass: {
    marginBottom: spacing(4),
    '@media (max-height: 600px)': {
      marginBottom: 0,
    },
  },
}));

const UnauthorizedPage = () => {
  const { unauthorizedPageClass, prestoLogoClass } = useStyles();

  return (
    <div data-testid="unauthorized-page" className={unauthorizedPageClass}>
      <Image className={prestoLogoClass} url={prestoLogo} alt="Presto Logo" />
      <Typography variant="h4" gutterBottom>
        You're unauthorized to access this
      </Typography>
    </div>
  );
};

export default UnauthorizedPage;
