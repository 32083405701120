import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../app/store';
import { EventSources, EventTypes } from '../constants/event';
import { CartItem, recursivelyFindSelectedItemNode } from '../utils/cart';
import { ModSymbolCodeNameMappingType } from '../utils/mappings';
import {
  checkItemInTree,
  ParsedMenuItem,
  ParsedModifierGroup,
} from '../utils/menu';
import { COUPON_APPLY_STATUS } from './cartSlice.constants';
import {
  CouponItem,
  ILoyaltyTransmissionMessage,
  initialCartState,
} from './cartSlice.props';
import {
  processCouponResponses,
  processLoyaltyResponses,
} from './cartSlice.utils';
import {
  ICheckCouponTransmissionMessage,
  IStatusTransmissionMessage,
  messagingActions,
  sendLoyalty as sendLoyaltyMessage,
  TransmissionMessage,
} from './messagingSlice';
import { orderActions } from './orderSlice';
import { selectRestaurant } from './restaurantSlice';

export const sendOrderMetrics = createAsyncThunk(
  'cart/sendOrderMetrics',
  async (undefined, thunkAPI) => {
    console.log('Sending order metrics');
    let restaurantCode =
      (thunkAPI.getState() as RootState).restaurant.selectedRestaurantCode ||
      undefined;
    if (!restaurantCode) {
      restaurantCode = (thunkAPI.getState() as RootState).messages.startFrame
        ?.data.restaurant_code;
      if (!restaurantCode) {
        throw thunkAPI.rejectWithValue('No Current restarauntCode selected!');
      }
    }

    const cartItems = Object.values(
      (thunkAPI.getState() as RootState).cart.cartItems
    );
    console.log('ARJ CART ITEMS', cartItems);
    let smallCart: {
      name: string;
      id: string;
      children: { name: string; id: string }[];
    }[] = [];
    cartItems.forEach((item) => {
      let { name, id, childModifierGroups } = item;
      let groups = Object.values(childModifierGroups);
      let children: { name: string; id: string }[] = [];
      groups.forEach((g) => {
        Object.values(g.selectedItems).map((child) => {
          let { name, id } = child;
          children.push({ name, id });
        });
      });
      smallCart.push({
        name,
        id,
        children,
      });
    });

    console.log(smallCart);
    const orderMetrics = {
      store_id: restaurantCode,
      items: smallCart,
      source: EventSources.prestoVoice,
      request_id: uuidv4(),
      // session_id: sessionId,
    };

    const payload: Partial<TransmissionMessage & { data: { payload: any } }> = {
      data: { payload: orderMetrics },
      event: EventTypes.info,
    };
    thunkAPI.dispatch(messagingActions.sendInfo(payload as any));
    return;
  }
);

// Apply Loyalty Code
export const applyLoyaltyCode = createAsyncThunk(
  'cart/applyLoyaltyCode',
  async (
    { couponCode }: { couponCode: number },
    { dispatch, rejectWithValue, getState }
  ) => {
    const rootState = getState() as RootState;

    let restaurantCode =
      rootState.restaurant.selectedRestaurantCode || undefined;
    let currentSessionId = rootState.order.currentSessionId;

    if (!currentSessionId) {
      currentSessionId = `${uuidv4()}`;
      dispatch(orderActions.setCurrentSession(currentSessionId));
    }

    if (!restaurantCode) {
      restaurantCode = rootState.messages.startFrame?.data.restaurant_code;
      if (!restaurantCode) {
        throw rejectWithValue('No Current restarauntCode selected!');
      }
    }

    const requestId = uuidv4();
    const couponCodeToString = couponCode.toString();
    const payload: Partial<ILoyaltyTransmissionMessage> = {
      data: {
        check_id: '-1', // check ID of target check
        loyalty_code: couponCodeToString, // loyalty offer to apply
        session_id: currentSessionId,
        source: EventSources.prestoVoice,
        request_id: requestId,
        store_id: restaurantCode,
        seq_id: '1',
      },
    };

    dispatch(sendLoyaltyMessage(payload as any));
    dispatch(populateLoyaltyRequests({ requestId }));
    dispatch(setCurrentSession(currentSessionId));
    dispatch(setCouponItem(undefined));
    dispatch(setCouponCode(couponCodeToString));
    dispatch(setCouponApplyStatus(COUPON_APPLY_STATUS.PROCESSING));
  }
);

const cartSlice = createSlice({
  name: 'cart',
  initialState: initialCartState,
  reducers: {
    populateLoyaltyRequests: (state, { payload: { requestId } }) => {
      state.requests[requestId] = {
        id: requestId,
        status: '',
        transactionId: '',
        loyaltyResponse: null,
        couponResponse: null,
      };
    },
    setCurrentSession: (state, action: PayloadAction<string>) => {
      state.currentSessionId = action.payload;
    },
    addItemToCart: (state, action: PayloadAction<CartItem>) => {
      state.cartItems[action.payload.cartItemId] = action.payload;
      state.cartItemsQuantity[action.payload.cartItemId] = 1;
      state.sequenceId++;
    },
    updateCartItem: (
      state,
      {
        payload: { cartItem, ignoreHypothesis },
      }: PayloadAction<{ cartItem: CartItem; ignoreHypothesis: boolean }>
    ) => {
      state.cartItems[cartItem.cartItemId] = cartItem;
    },
    selectModifier: (
      state,
      {
        payload: {
          cartItemId,
          menuItem,
          modGroup,
          selected,
          modCode,
          modSymbolMapping,
          pathToItem,
        },
      }: PayloadAction<{
        cartItemId: number;
        menuItem: ParsedMenuItem;
        modGroup: ParsedModifierGroup;
        selected: boolean;
        modCode: string;
        modSymbolMapping: ModSymbolCodeNameMappingType;
        pathToItem?: string;
      }>
    ) => {
      let node: CartItem = state.cartItems[cartItemId];

      const selectedItemNodes: CartItem[] = [];
      if (pathToItem) {
        node =
          checkItemInTree({
            cartItem: node,
            pathToItem: pathToItem || '',
            fromSelectModifier: true,
          }) || node;
      } else {
        recursivelyFindSelectedItemNode(
          state.cartItems[cartItemId],
          modGroup.id,
          selectedItemNodes
        );
      }
      if (selectedItemNodes.length) {
        node = selectedItemNodes[0];
      }
      if (!node) {
        return;
      }

      if (!node.childModifierGroups[modGroup.id]) {
        node.childModifierGroups[modGroup.id] = {
          ...modGroup,
          cartModifierGroupId: String(state.sequenceId++),
          menuModifierGroupId: modGroup.id,
          name: modGroup.name,
          selectedItems: {},
        };
      }

      const existing = node.childModifierGroups[modGroup.id].selectedItems;
      const menuItemIsAlreadySelected = existing && existing[menuItem.itemId];

      if (modGroup.maximumSelections === 1) {
        // Remove other choices automatically
        // Need to remove other choices when select different choice
        if (!menuItemIsAlreadySelected) {
          node.childModifierGroups[modGroup.id].selectedItems = {};
        }
      } else if (
        selected &&
        existing &&
        modGroup.maximumSelections === Object.keys(existing).length
      ) {
        // Do not allow selection of more then maximumSelections
        return;
      }

      if (!selected) {
        delete node.childModifierGroups[modGroup.id].selectedItems[
          menuItem.itemId
        ];
      } else {
        // Select a new modifier or update the modcode
        const mappedModCode = modSymbolMapping[modCode]?.code || '';
        node.childModifierGroups[modGroup.id].selectedItems[menuItem.itemId] = {
          ...menuItem,
          modality: state.modality,
          cartItemId: state.sequenceId++,
          childModifierGroups: {},
          modcode: mappedModCode,
        };
      }

      // state.cartItems[cartItemId] = node
    },
    cloneCartItem: (state, { payload }: PayloadAction<string>) => {
      state.sequenceId += 1;
      const cartItems = {
        ...state.cartItems,
        [state.sequenceId]: {
          ...state.cartItems[payload],
          cartItemId: state.sequenceId,
        },
      };
      state.cartItems = { ...cartItems };
      state.cartItemsQuantity[state.sequenceId] =
        state.cartItemsQuantity[payload];
      state.sequenceId += 1;
    },
    deleteCartItem: (state, { payload }: PayloadAction<string>) => {
      delete state.cartItems[payload];
      delete state.cartItemsQuantity[payload];
    },
    clearCart: (state) => {
      const newState = Object.assign({}, initialCartState);
      state = newState;
      return state;
    },
    setCouponApplyStatus: (
      state,
      { payload }: PayloadAction<COUPON_APPLY_STATUS | undefined>
    ) => {
      state.couponApplyStatus = payload;
    },
    updateQuantity: (
      state,
      { payload }: PayloadAction<{ cartItemId: string; quantity: number }>
    ) => {
      state.cartItemsQuantity[payload.cartItemId] = payload.quantity;
    },
    setCouponCode: (state, { payload }: PayloadAction<string>) => {
      state.couponCode = payload;
    },
    setCouponItem: (
      state,
      { payload }: PayloadAction<CouponItem | undefined>
    ) => {
      state.couponItem = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(selectRestaurant.fulfilled, (state, action) => {
      const newState = Object.assign({}, initialCartState);
      state = newState;
      return state;
    });
    builder.addCase(
      messagingActions.messageReceived,
      (state, action: PayloadAction<TransmissionMessage[]>) => {
        const messages = action.payload;
        messages.forEach((message) => {
          if (message.session_id === state.currentSessionId) {
            if (message.event === EventTypes.coupon) {
              const { data } = message as ICheckCouponTransmissionMessage;
              const { request_id: requestId = '' } = data || {};

              if (state.requests[requestId]) {
                state.requests[requestId].couponResponse = message;
                processCouponResponses(state, requestId);
              }
            } else if (message.event === EventTypes.loyaltyStatus) {
              const { data: { request_id: requestId = '' } = {} } =
                message as IStatusTransmissionMessage;

              if (state.requests[requestId]) {
                state.requests[requestId].loyaltyResponse = message;
                processLoyaltyResponses(state, requestId);
              }
            }
          }
        });
      }
    );
  },
});

export const cartActions = cartSlice.actions;
export const {
  addItemToCart,
  updateCartItem,
  selectModifier,
  cloneCartItem,
  deleteCartItem,
  clearCart,
  updateQuantity,
  populateLoyaltyRequests,
  setCurrentSession,
  setCouponApplyStatus,
  setCouponCode,
  setCouponItem,
} = cartSlice.actions;
export default cartSlice.reducer;
