import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../app/store';
import { DUMMY_ITEM, KEY, POS_ID, POS_NAME } from '../constants';
import {
  CartItemAdditionTypes,
  EventSources,
  EventTypes,
} from '../constants/event';
import { GenericObjectType } from '../types';
import { findObjectInObjectByKeyValue, readEnvVariable } from '../utils';
import { CartItem } from '../utils/cart';
import { POS_BOOLEAN_PROPERTIES_LIST } from '../utils/constants';
import inMemoryGroupIdMapping from '../utils/inMemoryGroupIdMapping';
import { getMenuItemPrice, sortChildrenModGroup } from '../utils/menu';
import { GenericMap } from '../utils/types';
import { sendOrderMetrics } from './cartSlice';
import { selectMenuVersion } from './menuSlice';
import {
  CheckTransmissionMessage,
  IStatusTransmissionMessage,
  messagingActions,
  sendOrder as sendOrderMessage,
  TransmissionMessage,
} from './messagingSlice';
import { OrderStatus } from './orderSlice.constants';
import {
  IOrderTransmissionMessage,
  ISendItemProperties,
  ISendOrderData,
} from './orderSlice.props';
import {
  initialOrderState,
  processOrderStatusAndCheckResponses,
  setLastOrderItemsFailed,
} from './orderSlice.utils';
import { selectRestaurant, selectStage } from './restaurantSlice';

export const sendOrder = createAsyncThunk(
  'order/sendOrder',
  async (
    {
      cartItems,
      cartValid,
      isFinal,
    }: {
      cartItems: GenericMap<CartItem>;
      cartValid: boolean;
      isFinal?: boolean;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const groupIdMappings = inMemoryGroupIdMapping.getGroupIdMapping();
    const {
      config: { ITEM_BY_ITEM: isItemByItemEnabled },
      order: { currentSessionId },
      cart,
      restaurant,
      messages,
    } = getState() as RootState;
    const cartItemIds = Object.values(cartItems).reduce(
      (acc: number[], { cartItemId }) => {
        if (cartItemId) acc.push(cartItemId);
        return acc;
      },
      []
    );
    dispatch(orderActions.startSendOrder(cartItemIds));
    const sessionId = currentSessionId ? currentSessionId : `${uuidv4()}`; // create a session only when there is no active session
    dispatch(orderActions.setCurrentSession(sessionId));
    const { cartItemsQuantity: itemsQuantity, couponItem } = cart;

    let restaurantCode = restaurant.selectedRestaurantCode || undefined;
    if (!restaurantCode) {
      restaurantCode = messages.startFrame?.data.restaurant_code;
      if (!restaurantCode) {
        throw rejectWithValue('No Current restarauntCode selected!');
      }
    }
    let pathToGroupId: string = '';
    const boolList = ['true', 'false', ''];

    const getCartItemProperties = (
      cartItem: CartItem,
      groupId: string[],
      path_to_entity: string[],
      isModifier = false
    ) => {
      const posProperties = Object.values(cartItem.posProperties).reduce(
        (acc, { key, value }) => {
          if (key === POS_ID && value !== DUMMY_ITEM) {
            groupId.push(value);
          }
          if (key === POS_NAME) {
            path_to_entity.push(value);
          }
          acc[key] =
            POS_BOOLEAN_PROPERTIES_LIST.includes(key) &&
            boolList.includes(value.toLowerCase())
              ? value.toLowerCase() === boolList[0]
              : value;
          return acc;
        },
        {} as { [key: string]: any }
      );

      if (cartItem.modcode) {
        posProperties.modcode = cartItem.modcode;
      }
      console.log(cartItem);

      pathToGroupId = groupId.slice(0, -1).join('__');
      console.log('Finding component id for:', pathToGroupId);
      if (groupIdMappings[pathToGroupId]) {
        posProperties['group_id'] = groupIdMappings[pathToGroupId];
        posProperties['component_id'] = groupIdMappings[pathToGroupId];
        pathToGroupId = '';
      }
      const posIDObject: GenericObjectType = findObjectInObjectByKeyValue(
        cartItem.posProperties,
        KEY,
        POS_ID
      );
      const childItems = sortChildrenModGroup(cartItem).reduce(
        (acc, modGroup) => {
          if (posIDObject?.value !== DUMMY_ITEM) groupId.push(modGroup.prpName);
          const selectedItems = Object.values(modGroup.selectedItems).map(
            (item) => {
              const cartItem = getCartItemProperties(
                item,
                [...groupId],
                [...path_to_entity],
                true
              );
              return cartItem;
            }
          );
          groupId.pop();
          acc.push(...selectedItems);
          return acc;
        },
        [] as ISendItemProperties[]
      );

      const itemData: ISendItemProperties = {
        id: parseInt(cartItem.id),
        name: cartItem.name,
        memo: cartItem.itemLevelMemo || undefined,
        quantity: isModifier ? 1 : itemsQuantity[cartItem.cartItemId] || 1,
        price: (getMenuItemPrice(cartItem, cartItem.modality) / 100).toFixed(2), // Apply price override
        options: childItems,
        children: childItems,
        pos_specific_properties: posProperties,
        addedBy: cartItem.addedBy || CartItemAdditionTypes.human,
        path_to_entity,
      };
      return itemData;
    };

    const items = Object.values(cartItems).map((item) => {
      let groupId: string[] = [];

      const cartItem = getCartItemProperties(item, groupId, []);
      return cartItem;
    });

    // This is tighter than it was, but this really should be atomic...
    dispatch(orderSlice.actions.incSeqId({}));
    const {
      order: { seqId: orderSeqId },
      menu,
    } = getState() as RootState;
    const seqId = orderSeqId;

    const orderData: ISendOrderData = {
      check_id: '-1', // TODO Do we send the same transaction_id here???
      store_id: restaurantCode,
      final: isFinal ? true : false,
      items,
      source: EventSources.prestoVoice,
      request_id: uuidv4(),
      session_id: sessionId,
      seq_id: seqId,
      menu_version: menu?.selectedMenuVersion || '',
    };

    // Apply Coupon Item
    if (couponItem && !couponItem.isApplied) {
      orderData.couponno = couponItem.couponno;
    }

    const payload: Partial<IOrderTransmissionMessage> = {
      data: {
        ...orderData,
        environment: readEnvVariable('DEPLOY_ENV'),
      },
    };

    if (cartValid && (isFinal || isItemByItemEnabled)) {
      console.log('Send order | data: ', JSON.stringify(payload));
      dispatch(sendOrderMessage(payload as any));
      dispatch(
        populateOrderRequests({
          requestId: orderData.request_id,
          cartItemIds,
          isFinal,
        })
      );
      dispatch(sendOrderMetrics());
    }
  }
);

const orderSlice = createSlice({
  name: 'order',
  initialState: initialOrderState,
  reducers: {
    startSendOrder: (state, action: PayloadAction<number[]>) => {
      for (let cartItemId of action.payload) {
        if (!state.currentTransactionItems[cartItemId]) {
          state.currentTransactionItems[cartItemId] = OrderStatus.sending;
        }
      }
    },
    populateOrderRequests: (
      state,
      { payload: { requestId, cartItemIds, isFinal } }
    ) => {
      state.requestsById[requestId] = {
        id: requestId,
        cartItemIds,
        status: '',
        transactionId: '',
        orderStatusResponse: null,
        checkResponse: null,
        isFinal: isFinal ? true : false,
      };
      state.requestsOrder.push(requestId);
    },
    resetSession: (state) => {
      state = initialOrderState;
      return state;
    },
    finishOrderResetValues: (state) => {
      state.currentSessionId = null;
      state.currentTransactionItems = {};
      state.seqId = 0;
      state.total = '';
      state.subtotal = '';
      state.tax = '';
      state.completeClickCount = {};
      state.isPosmonDisconnected = false;
      state.isPosmonReconnected = false;
    },
    setCurrentSession: (state, action: PayloadAction<string>) => {
      state.currentSessionId = action.payload;
      console.log('-currentSessionId in order state-', state.currentSessionId);
    },
    incSeqId: (state, action: any) => {
      state.seqId += 1;
    },
    setOrderError: (state, action: PayloadAction<string>) => {
      state.orderError = action.payload;
    },
    increaseCompleteClickCount: (state) => {
      if (
        state.currentTransactionId &&
        state.currentTransactionId in state.completeClickCount
      ) {
        state.completeClickCount[state.currentTransactionId] += 1;
      } else if (state.currentTransactionId) {
        // update count whever transaction id updates
        state.completeClickCount = {};
        state.completeClickCount[state.currentTransactionId] = 1;
      }
    },
    resetCurrentTransactionId: (state) => {
      state.currentTransactionId = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      messagingActions.messageReceived,
      (state, action: PayloadAction<TransmissionMessage[]>) => {
        const messages = action.payload;
        messages.forEach((message) => {
          if (message.event === EventTypes.check) {
            const { data } = message as CheckTransmissionMessage;
            const { request_id: requestId } = data || {};

            if (state.requestsById[requestId]) {
              state.requestsById[requestId].checkResponse = message;
              processOrderStatusAndCheckResponses(state, requestId);
            }
          } else if (message.event === EventTypes.orderStatus) {
            const { data: { request_id: requestId = '' } = {} } =
              message as IStatusTransmissionMessage;

            if (state.requestsById[requestId]) {
              state.requestsById[requestId].orderStatusResponse = message;
              processOrderStatusAndCheckResponses(state, requestId);
            }
          } else if (message.event === EventTypes.connect) {
            // trigger send order
            state.isPosmonReconnected = true;

            state.isPosmonDisconnected = false;
          } else if (message.event === EventTypes.disconnect) {
            // display error message
            const posmonDisconnectMessage = 'POSMON is not connected';
            setLastOrderItemsFailed(state, posmonDisconnectMessage);

            // trigger health status check
            state.isPosmonDisconnected = true;

            state.isPosmonReconnected = false;
          }
        });
      }
    );
    builder.addMatcher(
      isAnyOf(
        selectRestaurant.fulfilled,
        selectStage.fulfilled,
        selectMenuVersion.fulfilled
      ),
      (state) => {
        state = initialOrderState;
        return state;
      }
    );
  },
});

export const orderActions = orderSlice.actions;
export const {
  startSendOrder,
  setCurrentSession,
  populateOrderRequests,
  setOrderError,
  finishOrderResetValues,
  resetCurrentTransactionId,
} = orderSlice.actions;

export default orderSlice.reducer;
