import Dinero from 'dinero.js';
import { RootState } from '../app/store';
import { CartItemAdditionTypes } from '../constants/event';
import { ModalityType } from '../generated-interfaces/graphql';
import { getMenuItemPrice, ParsedMenuItem, ParsedModifierGroup } from './menu';
import { computeCartSubtotal, computeCartTax } from './money';
import { GenericMap } from './types';

export interface CartItem extends ParsedMenuItem {
  cartItemId: number;
  modality: ModalityType;
  parentCartModifierGroupId?: string;
  childModifierGroups: GenericMap<CartModifierGroup>;
  // itemOrder?: number;
  itemLevelMemo?: string;
  modcode?: string;
  addedBy?: CartItemAdditionTypes;
}

export interface CartModifierGroup extends ParsedModifierGroup {
  cartModifierGroupId: string;
  menuModifierGroupId: string;
  name: string;
  selectedItems: GenericMap<CartItem>;
}

export interface CartTotal {
  subtotal: number;
  tax: number;
  total: number;
}

export function isValidCartSelector(state: RootState): boolean {
  const cartItems = state.cart.cartItems;
  return !getAllInvalidModGroups(cartItems).length;
}

export function activeCartSelector(state: RootState): boolean {
  return Object.keys(state.cart.cartItems).length > 0;
}

export function cartTotalSelector(state: RootState): CartTotal {
  const restaurantSettings =
    state.restaurant.selectedRestaurant?.restaurantSettings;
  const modality = state.cart.modality;
  const cartItems = Object.values(state.cart.cartItems);
  const cartItemsQuantity = state.cart.cartItemsQuantity;
  const menuItems = Object.values(state.menu.fullMenuItems).reduce(
    (acc, menuItem) => {
      if (!menuItem.isModifierOnly) {
        acc[menuItem.itemId] = menuItem;
      }
      return acc;
    },
    {} as GenericMap<ParsedMenuItem>
  );
  const subtotal = computeCartSubtotal(cartItems, modality, cartItemsQuantity);
  const tax = computeCartTax(
    cartItems,
    menuItems,
    modality,
    restaurantSettings,
    cartItemsQuantity
  );
  const total = subtotal.add(tax);
  return {
    subtotal: subtotal.getAmount(),
    tax: tax.getAmount(),
    total: total.getAmount(),
  };
}

export const calculateItemTotal = (
  cartItem: CartItem,
  modality: ModalityType
): Dinero.Dinero => {
  let basePrice = Dinero({ amount: getMenuItemPrice(cartItem, modality) });
  Object.values(cartItem.childModifierGroups).forEach((modGroup) => {
    Object.values(modGroup.selectedItems).forEach(
      (item) => (basePrice = basePrice.add(calculateItemTotal(item, modality)))
    );
  });
  return basePrice;
};

const isInvalidModGroup = (
  modGroup: ParsedModifierGroup,
  cartModGroup: CartModifierGroup | undefined
): boolean => {
  // If they need to select at least one but no items have been selected
  if (modGroup.minimumSelections > 0 && !cartModGroup) {
    return true;
  }
  // If they don't need to select any and we didn't pass it in, it's fine
  if (!cartModGroup) {
    return false;
  }

  if (modGroup.minimumSelections === 0 && modGroup.maximumSelections === 0) {
    return false;
  }

  const selectedItemsSize = Object.keys(cartModGroup.selectedItems).length;
  if (
    selectedItemsSize < modGroup.minimumSelections ||
    (selectedItemsSize > modGroup.maximumSelections &&
      modGroup.maximumSelections !== -1)
  ) {
    return true;
  }
  return false;
};

const getInvalidModGroups = (
  modifierGroups: GenericMap<ParsedModifierGroup>,
  childModifierGroups: GenericMap<CartModifierGroup>
): ParsedModifierGroup[] => {
  return Object.values(modifierGroups).filter((modGroup) => {
    const cartModGroup: CartModifierGroup | undefined =
      childModifierGroups[modGroup.id];
    return isInvalidModGroup(modGroup, cartModGroup);
  });
};

const flatInvalidModGroups = (cartItem: CartItem): ParsedModifierGroup[] => {
  let invalidModGroups: ParsedModifierGroup[] = [];
  invalidModGroups.push(
    ...getInvalidModGroups(
      cartItem.modifierGroups,
      cartItem.childModifierGroups
    )
  );
  Object.values(cartItem.childModifierGroups).forEach((chidModifierGroup) => {
    Object.values(chidModifierGroup.selectedItems).forEach((selectItem) => {
      invalidModGroups.push(...flatInvalidModGroups(selectItem));
    });
  });
  return invalidModGroups;
};

export const getAllInvalidModGroups = (
  cartItems: GenericMap<CartItem>
): ParsedModifierGroup[] => {
  let invalidModGroups: ParsedModifierGroup[] = [];
  Object.values(cartItems).forEach((cartItem) => {
    invalidModGroups.push(...flatInvalidModGroups(cartItem));
  });
  return invalidModGroups;
};

export const getCartModGroup = (
  modGroup: ParsedModifierGroup,
  currentCartItem: CartItem
) => {
  let node: CartItem;
  node = currentCartItem;

  return node.modifierGroups[modGroup.id]
    ? node.childModifierGroups[modGroup.id]
    : null;
};

/**
 * For the given cart item, loop through its child modifier groups and its selected items to find the matching mod group recursively
 * @param item Cart item in which the search has to be performed
 * @param modGroupId Find for this modgroup
 * @param selectedItemNodes This is an array into which the selectedItemNode will be populated when the condition matches
 */
export const recursivelyFindSelectedItemNode = (
  item: CartItem,
  modGroupId: string,
  selectedItemNodes: CartItem[]
) => {
  Object.values(item.childModifierGroups).every((childItemEntry) => {
    const { selectedItems } = childItemEntry || {};

    Object.values(selectedItems).every((selectedItem) => {
      const { modifierGroups } = selectedItem;
      const matchedModGroup = Object.values(modifierGroups).filter(
        (modifierGroup) => modifierGroup.id === modGroupId
      );

      if (matchedModGroup.length) {
        selectedItemNodes.push(selectedItem);
        return false;
      } else {
        recursivelyFindSelectedItemNode(
          selectedItem,
          modGroupId,
          selectedItemNodes
        );
        return true;
      }
    });

    return !selectedItemNodes.length;
  });
};

export const getCartModGroupInChildModifierGroups = (
  modGroup: ParsedModifierGroup,
  currentCartItem: CartItem
) => {
  let node: CartItem;
  node = currentCartItem;

  if (Object.keys(node.modifierGroups).includes(modGroup.id)) {
    return node.childModifierGroups[modGroup.id];
  } else {
    const selectedItemNodes: CartItem[] = [];
    recursivelyFindSelectedItemNode(node, modGroup.id, selectedItemNodes);
    if (selectedItemNodes.length) {
      return selectedItemNodes[0].childModifierGroups?.[modGroup.id];
    }
    return null;
  }
};

export const getCartInvalidModGroupDescriptions = (
  invalidModGroups: ParsedModifierGroup[]
) => {
  let invalidModGroupDescriptions: string[] = [];
  if (invalidModGroups.length > 0) {
    invalidModGroups.forEach((modGroup) => {
      if (modGroup.description) {
        invalidModGroupDescriptions.push(modGroup.description);
      } else {
        invalidModGroupDescriptions.push('.');
      }
    });
  }
  return invalidModGroupDescriptions;
};
