import { sortModGroup } from '../../../utils/menu';
import { IModGroup } from './modGroup.props';

const hasModGroupChildren = (props: IModGroup) => {
  const { modGroup, cartModGroup, cartItem } = props;

  const modChildren = Object.values(cartModGroup?.selectedItems || {}).filter((selectedModifierItem) => {
    const modGroupChildren = sortModGroup(modGroup.menuItems[selectedModifierItem.itemId]);
    const filteredModGroups = Object.values(modGroupChildren).filter((mod) => !cartItem.modifierGroups[mod.id]);
    return filteredModGroups.length;
  });

  return !!modChildren.length;
};

export { hasModGroupChildren };
