import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderOptionState,
  FilterOptionsState,
  TextField,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector, useShallowSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { ID, MAX_CART_ITEM_QUANTITY } from '../../constants';
import { Symbols } from '../../constants/enums';
import { AICommands } from '../../constants/event';
import useAIMessaging from '../../features/messaging/useAIMessaging.hooks';
import {
  applyLoyaltyCode,
  cartActions,
  updateQuantity,
} from '../../reducers/cartSlice';
import { dialogActions } from '../../reducers/dialogSlice';
import {
  addHypothesisItemToCart,
  addItemToCart,
} from '../../reducers/menuSlice';
import {
  InfoTransmissionMessage,
  messagingActions,
} from '../../reducers/messagingSlice';
import { SuggestionStatus } from '../../redux/features/ai/ai.constants';
import { selectSuggestions } from '../../redux/features/ai/ai.selector';
import { updateSuggestion } from '../../redux/features/ai/ai.slice';
import {
  invalidModGroupsSelector,
  selectCartItems,
  selectCartItemsQuantity,
} from '../../selectors/cart';
import { selectDialog } from '../../selectors/dialog';
import {
  generateMenuItemsSelector,
  getModSymbolMapping,
} from '../../selectors/menu';
import { findObjectInObjectByKeyValue } from '../../utils';
import {
  findAnotherSelectedItem,
  getFilteredOptions,
  getOptionLabel,
  getQuantityFromInput,
  iterateModGroups,
  processQuantity,
} from '../../utils/autocomplete';
import { getCartInvalidModGroupDescriptions } from '../../utils/cart';
import Colors from '../../utils/color';
import { INTENT_CARDS } from '../../utils/constants';
import {
  isItem86edToday,
  ParsedMenuItem,
  TopLevelMenuItem,
} from '../../utils/menu';
import { restaurantInfoSelector } from '../../utils/restaurants';
import { AutocompleteOption } from './AutocompleteOption';
import { getItemUnavailableMessage } from '../../utils/TTSMessage';

const useStyles = makeStyles((theme: Theme) => ({
  wrapText: {
    width: '100%',
    borderRadius: theme.spacing(1),
    backgroundColor: Colors.white,
    padding: theme.spacing(1),
    '& input': {
      paddingLeft: `${theme.spacing(1)} !important`,
    },
    '& .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  inProgressText: {
    '& input': {
      color: 'lightgray',
    },
  },
  option: {
    pointerEvents: 'none',
  },
}));

interface IAutocompleteComp {
  currentMessage: string;
  updateCurrentMessage: (message: string) => void;
  inProgressMessage: string | null;
  disabled: boolean;
  sendMessage: (message: string, metadata?: any, title?: string) => void;
}

export const AutocompleteComp = ({
  currentMessage,
  updateCurrentMessage,
  inProgressMessage,
  disabled,
  sendMessage,
}: IAutocompleteComp) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuItems = useSelector(generateMenuItemsSelector(currentMessage));
  const cartSequenceId = useSelector(
    (state: RootState) => state.cart.sequenceId
  );
  const cartItems = useSelector(selectCartItems);
  const cartItemsQuantity = useSelector(selectCartItemsQuantity);
  const { suggestionsById, filteredSuggestionsOrder } =
    useSelector(selectSuggestions);
  const cart = useShallowSelector((state: RootState) => state.cart);
  const selectedItem =
    useAppSelector((state) => state.dialog.selectedItem) ||
    Object.values(cart.cartItems)[Object.values(cart.cartItems).length - 1];

  const selectedItemCartId =
    useAppSelector((state) => state.dialog.selectedItemCartId) ||
    parseInt(
      Object.keys(cart.cartItems).find(
        (key) => cart.cartItems[key] === selectedItem
      ) || '0'
    );

  const dialogState = useAppSelector(selectDialog);
  const {
    steps: dialogSteps,
    step: dialogStep,
    voiceBoardValue,
    intentsStatus,
  } = dialogState;
  const selectedRestaurant = useSelector(restaurantInfoSelector);

  const [inputQuantity, setInputQuantity] = useState(1);
  const [inputModSymbol, setInputModSymbol] = useState('');
  const fullMenuItems = useAppSelector((state) => state.menu.fullMenuItems);
  const [downTime, setDownTime] = useState(0);
  const [hasPreviousMessage, setHasPreviousMessage] = useState(false);
  const modSymbolMapping = useShallowSelector(getModSymbolMapping);

  // Coupon Loyalty Code
  const [couponCode, setCouponCode] = useState<number | null>(null);
  const [aiCommand, setAICommand] = useState<AICommands>(AICommands.empty);

  const { applyAICommand } = useAIMessaging();

  const [prefixWord, setPrefixWord] = useState('');
  const [handleOutOfOrderSearch, setHandleOutOfOrderSearch] = useState(false);

  useEffect(() => {
    if (currentMessage) {
      setHasPreviousMessage(true);
    } else {
      let timer = setTimeout(() => {
        setHasPreviousMessage(false);
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [currentMessage]);

  // Get invalid modifier group description for agent to send as autoprompter if there is any
  const invalidModGroups = useSelector(invalidModGroupsSelector);
  const invalidModGroupDescriptions =
    getCartInvalidModGroupDescriptions(invalidModGroups);
  if (invalidModGroupDescriptions.length)
    dispatch(
      dialogActions.addInvalidModGroupDescriptions(invalidModGroupDescriptions)
    );

  const getCurrentDialogStep = () => {
    if (selectedItem) {
      if (invalidModGroupDescriptions.length) {
        return invalidModGroupDescriptions[0];
      } else if (dialogStep !== 1) {
        dispatch(dialogActions.setStep(1));
      }
    }

    return dialogSteps[dialogStep].replaceAll(
      '{RESTAURANT_NAME}',
      selectedRestaurant?.restaurantName || ''
    );
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    const payload: Partial<InfoTransmissionMessage> = {
      data: { message: 'Key ' + event.key + ' pressed', type: 'METRIC' },
    };
    dispatch(messagingActions.sendInfo(payload as any));

    if (event.key === 'Enter') {
      event.preventDefault();

      if (couponCode) {
        // Call Apply Loyalty Code API
        dispatch(applyLoyaltyCode({ couponCode }));
      } else if (!event.shiftKey) {
        if (voiceBoardValue !== '' && voiceBoardValue[0] === '/') {
          // Anything prepended with a / (either typed or via the voiceboard) will be spoken out as TTS.
          handleMessageSubmit();
        } else if (!event.shiftKey) {
          sendMessage(getCurrentDialogStep());
          if (dialogStep === dialogSteps.length - 1) {
            if (dialogStep !== 0) {
              dispatch(dialogActions.setStep(0));
            }
          } else {
            dispatch(dialogActions.increaseStep());
          }
        }
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (
      [
        'ArrowUp',
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
        'Enter',
        ' ',
      ].includes(event.key)
    ) {
      if (event.shiftKey && event.key !== 'Enter') {
        event.preventDefault();
        event.stopPropagation();
      }

      let dt = new Date().valueOf();
      if (!downTime) {
        setDownTime(dt);
      }
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent) => {
    let upTime = new Date().valueOf();
    const timePressed = Math.floor((upTime - downTime) / 100);
    const getMessage = (card: number) =>
      INTENT_CARDS[card].messages[timePressed] ||
      INTENT_CARDS[card].messages[INTENT_CARDS[card].messages.length - 1];

    if (!currentMessage || event.shiftKey) {
      event.preventDefault();
      switch (event.key) {
        case 'ArrowUp':
          intentsStatus[1] && sendMessage(getMessage(1)); // #okay
          break;
        case 'ArrowLeft':
          intentsStatus[3] && sendMessage(getMessage(3)); // #moment
          break;
        case 'ArrowRight':
          intentsStatus[5] && sendMessage(getMessage(5)); // #continue
          break;
        default:
          break;
      }
      setDownTime(0);
    }

    if (event.shiftKey) {
      // Use Shift+Backspace key for removing last cart item
      if (event.key === 'Backspace' && !currentMessage && !hasPreviousMessage) {
        if (selectedItem && selectedItemCartId > 0) {
          dispatch(cartActions.deleteCartItem(selectedItemCartId.toString()));

          const newCartItemId = findAnotherSelectedItem(
            cart.cartItems,
            selectedItemCartId
          );
          if (newCartItemId > 0) {
            dispatch(
              dialogActions.updateSelectedItem({
                item: cart.cartItems[newCartItemId.toString()],
                itemCartId: newCartItemId,
              })
            );
          } else {
            dispatch(dialogActions.updateSelectedItem());
          }
        }
      }
      if (event.key === 'Enter') {
        // Use Shift+Enter key for accepting the active suggestion
        const id = filteredSuggestionsOrder[0];
        const { suggestionStatus, ...orderItem } = suggestionsById[id];
        const { quantity } = orderItem;
        const matchingCartItem = findObjectInObjectByKeyValue(
          cartItems,
          ID,
          orderItem.id
        );
        if (!matchingCartItem) {
          dispatch(addHypothesisItemToCart(orderItem));
        } else {
          const currentCartQuantity = matchingCartItem?.id
            ? cartItemsQuantity[matchingCartItem.cartItemId]
            : 0;
          const updatedQuantity = quantity + currentCartQuantity;
          if (updatedQuantity > 0)
            dispatch(
              updateQuantity({
                cartItemId: matchingCartItem.cartItemId.toString(),
                quantity: Math.min(updatedQuantity, MAX_CART_ITEM_QUANTITY),
              })
            );
        }
        dispatch(
          updateSuggestion({ id, suggestionStatus: SuggestionStatus.accept })
        );
      } else if (event.key === ' ') {
        // Use Shift+space key for rejecting the active suggestion
        const id = filteredSuggestionsOrder[0];
        dispatch(
          updateSuggestion({ id, suggestionStatus: SuggestionStatus.reject })
        );
      }
    }
  };

  const handleMessageSubmit = () => {
    if (voiceBoardValue.length > 0) {
      let sendVal = voiceBoardValue;
      if (voiceBoardValue[0] === '/') {
        sendVal = voiceBoardValue.replace('/', '');
      }

      //Send message only when the agent typed '//' for TTS-ON and '/\' for TTS-OFF
      if (sendVal.length === 1 && ['/', '\\'].includes(sendVal))
        sendMessage(sendVal);
      updateCurrentMessage('');
      dispatch(dialogActions.setVoiceBoardValue(''));
    }
  };

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const curValue: any = event.currentTarget.value;
    updateCurrentMessage(curValue);

    // Coupon Loyalty Code
    if (
      curValue &&
      [Symbols.star, Symbols.plus, Symbols.minus].includes(curValue[0])
    ) {
      if (curValue[0] === Symbols.star) {
        //Logic related to coupon code
        const couponNum = parseInt(curValue.slice(1));
        // Coupon code should be less than 6
        if (!isNaN(couponNum)) {
          if (couponNum.toString().length <= 6) {
            setCouponCode(couponNum);
            updateCurrentMessage(Symbols.star + couponNum);
          } else {
            updateCurrentMessage(Symbols.star + couponCode);
          }
        } else {
          setCouponCode(null);
          updateCurrentMessage(Symbols.star);
        }
      } else if ([Symbols.plus, Symbols.minus].includes(curValue[0])) {
        setAICommand(curValue);
      }
    } else {
      setCouponCode(null);
      setAICommand(AICommands.empty);
      dispatch(dialogActions.setVoiceBoardValue(curValue));
    }
  };

  const setCurrentMessageAndVoiceBoardValue = (
    message = '',
    voiceBoardValue = ''
  ) => {
    updateCurrentMessage(message);
    dispatch(dialogActions.setVoiceBoardValue(voiceBoardValue));
  };

  const onSelectChange = (
    event: any,
    value: ParsedMenuItem | string | TopLevelMenuItem | null,
    reason: AutocompleteChangeReason
  ) => {
    if (
      event.shiftKey &&
      event.key === 'Enter' &&
      [AICommands.activate, AICommands.deactivate].includes(aiCommand)
    ) {
      applyAICommand(aiCommand);
      return;
    }

    if (value === null) {
      setCurrentMessageAndVoiceBoardValue();
    } else if (typeof value === 'string') {
      setCurrentMessageAndVoiceBoardValue(value, value);
    } else if (value instanceof Object) {
      if (isItem86edToday(value)) {
        sendMessage(getItemUnavailableMessage(value.name));
        return;
      }
      if (
        !value.name.includes('/upsell') &&
        (value.category === 'tts-prompt' || value.category.startsWith('/'))
      ) {
        let [intent, sendVal] = value.name.replace('/', '').split(':');
        sendMessage(sendVal, { intent });
        setCurrentMessageAndVoiceBoardValue();
      } else if (value.category === 'modifier') {
        setCurrentMessageAndVoiceBoardValue();
        if (selectedItem && selectedItemCartId) {
          const menuItem = value as ParsedMenuItem;

          // Declined removes selected upsell item
          if (menuItem.name.toLocaleLowerCase() === 'declined') {
            dispatch(cartActions.deleteCartItem(selectedItemCartId.toString()));

            const newCartItemId = findAnotherSelectedItem(
              cart.cartItems,
              selectedItemCartId
            );
            if (newCartItemId > 0) {
              dispatch(
                dialogActions.updateSelectedItem({
                  item: cart.cartItems[newCartItemId.toString()],
                  itemCartId: newCartItemId,
                })
              );
            } else {
              dispatch(dialogActions.updateSelectedItem());
            }
          }

          let groupsToCheck = Object.values(selectedItem.modifierGroups);

          let childrenMods =
            cart.cartItems[selectedItemCartId]?.childModifierGroups;
          let isChecked = iterateModGroups(
            childrenMods,
            groupsToCheck,
            value,
            true,
            inputModSymbol
          );

          let modGroup = groupsToCheck.filter((mg) => mg.menuItems[value.id]);

          if (modGroup.length > 0) {
            let mg = modGroup[0];

            dispatch(
              cartActions.selectModifier({
                cartItemId: selectedItemCartId,
                menuItem,
                modGroup: mg,
                selected: isChecked,
                modCode: inputModSymbol,
                modSymbolMapping,
              })
            );
          }
        }
      } else {
        if (value.name.includes('/upsell')) {
          let [intent, sendVal] = value.name.replace('/', '').split(':');
          sendMessage(sendVal, { intent });
          updateCurrentMessage('');
        }
        dispatch(dialogActions.setVoiceBoardValue(''));

        // note: mod symbol never used on root items
        dispatch(
          addItemToCart({ menuItem: value, prefixWord, inputModSymbol })
        );

        const { id, categoryId } = value;
        const itemId = `${id}-${categoryId}`;

        dispatch(
          dialogActions.updateSelectedItem({
            item: fullMenuItems[itemId],
            itemCartId: cartSequenceId,
          })
        );

        const quantity = processQuantity(inputQuantity, value, currentMessage);
        dispatch(
          cartActions.updateQuantity({
            cartItemId: cartSequenceId.toString(),
            quantity: Math.min(quantity, 19),
          })
        );
        setInputQuantity(1);
        updateCurrentMessage('');
        setPrefixWord('');
        setHandleOutOfOrderSearch(false);
      }
    }
  };

  const classNames = [classes.wrapText];
  if (inProgressMessage !== null) {
    classNames.push(classes.inProgressText);
  }

  const getPlaceHolder = () =>
    invalidModGroupDescriptions.length > 0
      ? invalidModGroupDescriptions[0]
      : getCurrentDialogStep();

  const filterOptions = (
    options: (TopLevelMenuItem | ParsedMenuItem)[],
    state: FilterOptionsState<TopLevelMenuItem | ParsedMenuItem>
  ) => {
    const { quantity, inputValue, modSymbol } = getQuantityFromInput(
      state.inputValue,
      modSymbolMapping
    );

    setInputQuantity(quantity || 1);
    setInputModSymbol(modSymbol ? modSymbol : '');

    let resultList = getFilteredOptions(
      modSymbol,
      inputValue,
      quantity,
      options,
      state.inputValue
    );

    // search for the second word if no result found for the whole input value and only hanlde two words for now. i.e. coke large
    const splittedInputValue = inputValue.trim().split(' ');
    if (!Object.values(resultList).length && splittedInputValue.length === 2) {
      resultList = getFilteredOptions(
        modSymbol,
        splittedInputValue[1],
        quantity,
        options,
        state.inputValue
      );
      setPrefixWord(splittedInputValue[0]);
      setHandleOutOfOrderSearch(true);
    }

    return resultList;
  };

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    menuItem: ParsedMenuItem | TopLevelMenuItem,
    state: AutocompleteRenderOptionState
  ) => {
    const optionProps = {
      htmlElemProps: props,
      menuItem,
      state,
      handleOutOfOrderSearch,
    };
    return <AutocompleteOption key={menuItem.id} {...optionProps} />;
  };

  return (
    <Autocomplete
      id="omnibar-autocomplete"
      freeSolo
      fullWidth
      clearOnEscape
      open={currentMessage !== ''}
      autoHighlight={true}
      value={null}
      inputValue={inProgressMessage || currentMessage}
      onChange={onSelectChange}
      options={menuItems}
      getOptionLabel={getOptionLabel}
      onClose={() => updateCurrentMessage('')}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={(input) => input && input.focus()}
          className={classNames.join(' ')}
          autoFocus
          id="omnitext"
          placeholder={getPlaceHolder()}
          onKeyPress={handleKeyPress}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          value={currentMessage}
          onChange={onTextChange}
          disabled={disabled}
          InputProps={{ ...params.InputProps, endAdornment: null }}
        />
      )}
      filterOptions={filterOptions}
      renderOption={renderOption}
    />
  );
};
