import { makeStyles } from '@mui/styles';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import Body from '../components/Body';
import Header from '../components/Layout/Header';
import { selectRestaurantsById } from '../selectors/restaurant';
import { restaurantInfoSelector } from '../utils/restaurants';
import UnauthorizedPage from './UnauthorizedPage';

const useStyles = makeStyles(() => ({
  restaurantPageClass: {
    height: '100vh',
  },
}));

const RestaurantPage = () => {
  const { restaurantPageClass } = useStyles();
  const { restaurantId } = useParams();
  const selectedRestaurant = useAppSelector(restaurantInfoSelector);
  const restaurantsById = useAppSelector(selectRestaurantsById);
  const accessibleRestaurantDetails = Object.values(restaurantsById).filter((restaurant) => restaurant.restaurantCode === restaurantId);

  if (!accessibleRestaurantDetails.length) return <UnauthorizedPage />;

  return (
    <div data-testid="restaurant-page" className={restaurantPageClass}>
      <Header targetRestaurant={accessibleRestaurantDetails[0]} />
      {selectedRestaurant && <Body />}
    </div>
  );
};

export default RestaurantPage;
