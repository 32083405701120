import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuStages, MenuStagesLabel } from '../../constants/enums';
import { selectStage } from '../../reducers/restaurantSlice';
import { selectedStageSelector } from '../../selectors/restaurant';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(1, 2),
  },
  dropdownIcon: {
    color: '#e0e0e0',
  },
  menu: {
    '& .MuiMenu-paper': {
      width: '255px',
      maxHeight: '230px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  input: {
    color: '#616161',
  },
  name: {
    margin: '0 auto',
    color: theme.palette.common.white,
  },
}));

const MenuVersionPicker: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedStage = useSelector(selectedStageSelector);

  return (
    <>
      <Button
        disableElevation
        aria-controls="menu-version-picker"
        aria-haspopup="true"
        onClick={(event) => {
          dispatch(selectStage());
        }}
        className={classes.button}
        color={selectedStage === MenuStages.LIVE ? 'success' : 'secondary'}
        variant="text"
      >
        {MenuStagesLabel[selectedStage]}
      </Button>
    </>
  );
};

export default memo(MenuVersionPicker);
