import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TaskStatuses } from './taskRouter.constants';
import { ITask, ITaskRouterSlice, ITaskUpdation } from './taskRouter.props';

const initialState: ITaskRouterSlice = {
  isAgentAvailable: true,
  wsConnectionMessage: '',
  tasksById: {},
  tasksOrder: [],
  currentTaskId: '',
};

const taskRouterSlice = createSlice({
  name: 'taskRouter',
  initialState,
  reducers: {
    resetTaskRouterData: (state) => {
      state = { ...initialState };
      return state;
    },
    toggleAgentAvailability: (state) => {
      state.isAgentAvailable = !state.isAgentAvailable;
    },
    setWSConnectionMessage: (state, action) => {
      state.wsConnectionMessage = action.payload;
    },
    addNewTask: (state, { payload }: PayloadAction<ITask>) => {
      const {
        data: { task_id: taskId },
      } = payload;
      if (taskId) {
        state.tasksById[taskId] = { ...payload };
        state.tasksOrder.push(taskId);
      }
    },
    updateTaskStatus: (state, { payload }: PayloadAction<ITaskUpdation>) => {
      const { taskId, event } = payload;
      if (event === TaskStatuses.rejected) {
        if (state.tasksById[taskId]) {
          delete state.tasksById[taskId];
          state.tasksOrder = state.tasksOrder.filter((item) => item !== taskId);
          if (taskId === state.currentTaskId) {
            state.currentTaskId = '';
          }
        }
        return;
      }
      if (event === TaskStatuses.accepted) {
        state.currentTaskId = taskId;
      }
      if (state.tasksById[taskId]) state.tasksById[taskId].event = event;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  toggleAgentAvailability,
  resetTaskRouterData,
  updateTaskStatus,
  setWSConnectionMessage,
  addNewTask,
} = taskRouterSlice.actions;
export default taskRouterSlice.reducer;
