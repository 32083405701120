import { Button } from '@mui/material';
import React, { useCallback } from 'react';
import ConfirmDialog from '../common/ConfirmDialog';
import useLogout from './useLogout';

export function Logout() {
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const { logout } = useLogout();

  const handleLogout = useCallback(() => logout(), []);

  return (
    <>
      {showConfirmModal && (
        <ConfirmDialog content="Do you want to log out from HITL?" open={showConfirmModal} onConfirm={handleLogout} onClose={() => setShowConfirmModal(false)} />
      )}
      <div>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setShowConfirmModal(true);
          }}
        >
          Log out
        </Button>
      </div>
    </>
  );
}
