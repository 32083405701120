import { Button, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { default as React, FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import { selectIsLoading } from '../../redux/features/isLoading/isLoading.selector';
import {
  selectIsAgentAvailable,
  selectTasksAssigned,
  selectTasksById,
  selectTasksOffered,
} from '../../redux/features/taskRouter/taskRouter.selector';
import LoadingIndicator from '../LoadingIndicator';
import TaskRouterAcceptance from '../TaskRouterAcceptance';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  taskRouterBodyClass: {
    height: `calc(100vh - ${spacing(8)})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButtonClass: {
    marginTop: `${spacing(2)} !important`,
    textTransform: 'uppercase',
  },
}));

type TaskRouterBodyProps = {
  handleAgentAvailability?: () => void;
};

const TaskRouterBody: FC<TaskRouterBodyProps> = ({
  handleAgentAvailability,
}) => {
  const { taskRouterBodyClass, actionButtonClass } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const isAgentAvailable = useAppSelector(selectIsAgentAvailable);
  const tasksById = useAppSelector(selectTasksById);
  const areTasksOffered = useAppSelector(selectTasksOffered);
  const areTasksAssigned = useAppSelector(selectTasksAssigned);
  const isLoading = useAppSelector(selectIsLoading);

  useEffect(() => {
    if (areTasksAssigned?.length) {
      const task = tasksById[areTasksAssigned[0]];
      if (task?.data?.restaurant_code) {
        navigate(location.state?.from || '/' + task.data.restaurant_code);
      }
    }
  }, [areTasksAssigned]);

  const renderContent = () => {
    switch (true) {
      case isLoading: {
        return <LoadingIndicator />;
      }
      case !isAgentAvailable: {
        return (
          <>
            <Typography variant="h4" gutterBottom>
              You're Unavailable to take Orders
            </Typography>
            <Typography>You can't take orders when Unavailable.</Typography>
            <Typography>
              When you're ready, switch your status to Available to start taking
              orders.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleAgentAvailability}
              className={actionButtonClass}
            >
              change status to available
            </Button>
          </>
        );
      }
      case isAgentAvailable && !!areTasksOffered?.length: {
        return <TaskRouterAcceptance />;
      }
      case isAgentAvailable: {
        return (
          <>
            <Typography variant="h4" gutterBottom>
              Get ready
            </Typography>
            <Typography>New tasks will be offered to you shortly</Typography>
          </>
        );
      }
      default:
        break;
    }
  };

  return (
    <div id="task-router-body" className={taskRouterBodyClass}>
      {renderContent()}
    </div>
  );
};

export default TaskRouterBody;
