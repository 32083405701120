//TODO: This file along with its slice has to be moved into some folder specific to orderSlice

import { CheckResponseTypes, StatusResponseTypes } from '../constants/event';
import {
  CheckTransmissionMessage,
  IStatusTransmissionMessage,
} from './messagingSlice';
import { IOrderState } from './orderSlice.props';
import { TransactionStatus, OrderStatus } from './orderSlice.constants';

const initialOrderState: IOrderState = {
  currentSessionId: null,
  seqId: 0,
  total: '',
  subtotal: '',
  tax: '',
  orderError: '',
  currentTransactionId: null,
  transactions: {},
  currentTransactionItems: {},
  completeClickCount: {},
  canAcceptHypothesisEvents: true,
  requestsById: {},
  requestsOrder: [],
  currentRequestIdIndex: -1,
  isPosmonReconnected: false,
  isPosmonDisconnected: false,
};

const processOrderStatusAndCheckResponses = (
  state: IOrderState,
  requestId: string
) => {
  const { orderStatusResponse, checkResponse, cartItemIds } =
    state.requestsById[requestId];

  //order_status response destructuring
  const { data: orderStatusData, session_id: orderStatusSessionId } =
    (orderStatusResponse as IStatusTransmissionMessage) || {};
  const { status: orderStatus, result } = orderStatusData || {};
  const { transaction_id: orderTransactionId = '' } = result || {};
  const { type = '', message: statusMessage = '' } = orderStatus || {};

  //check response destructuring
  const { data: checkData, session_id: checkSessionId } =
    (checkResponse as CheckTransmissionMessage) || {};
  const {
    status: checkStatus = CheckResponseTypes.unknown,
    check,
    transaction_id: checkTransactionId = '',
  } = checkData || {};
  const { total = '', subtotal = '', tax = '' } = check || {};

  const transactionId = orderTransactionId || checkTransactionId;

  state.requestsById[requestId].transactionId = transactionId;

  if (
    state.requestsOrder.length &&
    state.requestsOrder[state.requestsOrder.length - 1] === requestId
  )
    state.currentTransactionId = transactionId; // Note: when order_status returns error, no transactionId will be created. currentTransactionId will be set to empty string ''

  if (orderStatusResponse && !checkResponse) {
    //order_status event is received while check event is not received
    state.requestsById[requestId].status = type;
    if (type === StatusResponseTypes.success) {
      // sendOrder.fullfilled
      state.orderError = '';
      state.requestsById[requestId].transactionId = transactionId;

      state.transactions[transactionId] = {
        data: null,
        status: TransactionStatus.pending, // transactionId is created but not finished
        cartItemIds,
        isFinal: state.requestsById[requestId].isFinal,
      };

      if (state.currentSessionId === orderStatusSessionId) {
        for (let cartItemId of cartItemIds) {
          if (
            state.currentTransactionItems[cartItemId] === OrderStatus.sending
          ) {
            state.currentTransactionItems[cartItemId] = OrderStatus.pending;
          }
        }
      }
    } else if (type === StatusResponseTypes.error) {
      state.orderError = statusMessage;
      if (
        !state.errorCartItemId ||
        !cartItemIds.find((id: number) => id === state.errorCartItemId)
      ) {
        state.errorCartItemId = cartItemIds[cartItemIds.length - 1];
      }
      if (state.errorCartItemId) {
        state.currentTransactionItems[state.errorCartItemId] =
          OrderStatus.failed;
      }
    }
  } else {
    //One of the below two scenarios:
    //(1) order_status event is received and check event is also received
    //(2) order_status event is not received but check event is received

    //checkTransactionStatus.fulfilled
    state.orderError = '';
    state.requestsById[requestId].status = StatusResponseTypes.success;
    state.transactions[transactionId] = {
      data: checkData,
      status:
        checkStatus === CheckResponseTypes.ok
          ? TransactionStatus.completed
          : TransactionStatus.error,
      cartItemIds: state.requestsById[requestId].cartItemIds,
      isFinal: state.requestsById[requestId].isFinal,
    };
    if (state.currentSessionId === checkSessionId) {
      if (checkStatus === CheckResponseTypes.ok) {
        if (state.errorCartItemId) {
          state.currentTransactionItems[state.errorCartItemId] =
            OrderStatus.completed; // Set the status of previous error cart item to completed if the following transaction succeeds
        }
        state.errorCartItemId = undefined;
      } else if (
        !state.errorCartItemId ||
        !cartItemIds.find((id) => id === state.errorCartItemId)
      ) {
        state.errorCartItemId = cartItemIds[cartItemIds.length - 1]; // Set the last cart item id to be the errorCartItemId
      }

      if (checkStatus !== CheckResponseTypes.ok && state.errorCartItemId) {
        state.currentTransactionItems[state.errorCartItemId] =
          OrderStatus.failed;
      }

      for (let cartItemId of state.transactions[transactionId].cartItemIds) {
        if (
          !state.currentTransactionItems[cartItemId] ||
          state.currentTransactionItems[cartItemId] !== OrderStatus.completed
        ) {
          state.currentTransactionItems[cartItemId] =
            checkStatus === CheckResponseTypes.ok
              ? OrderStatus.completed
              : OrderStatus.failed;
        }
      }
      const requestIdIndex = state.requestsOrder.findIndex(
        (id) => id === requestId
      );
      if (
        checkStatus === CheckResponseTypes.ok &&
        requestIdIndex > state.currentRequestIdIndex
      ) {
        state.currentRequestIdIndex = requestIdIndex;
        state.total = total ? total : state.total;
        state.subtotal = total ? subtotal : state.subtotal;
        state.tax = total ? tax : state.tax;
      }
    }
  }
};

const setLastOrderItemsFailed = (state: IOrderState, errorMessage: string) => {
  const orderRequestsLength = state.requestsOrder.length;
  if (orderRequestsLength) {
    state.orderError = errorMessage;
    const lastRequestId = state.requestsOrder[orderRequestsLength - 1] || '';
    const cartItemIds = state.requestsById[lastRequestId].cartItemIds;
    if (
      !state.errorCartItemId ||
      !cartItemIds.find((id: number) => id === state.errorCartItemId)
    ) {
      state.errorCartItemId = cartItemIds[cartItemIds.length - 1];
    }
    if (state.errorCartItemId) {
      state.currentTransactionItems[state.errorCartItemId] = OrderStatus.failed;
    }
  }
};

export {
  initialOrderState,
  processOrderStatusAndCheckResponses,
  setLastOrderItemsFailed,
};
