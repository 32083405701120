import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../app/store';
import { cartActions } from '../../reducers/cartSlice';
import {
  EndSessionTransmissionMessage,
  messagingActions,
  RestaurantStaffInterventionStatus,
} from '../../reducers/messagingSlice';
import { orderActions, sendOrder } from '../../reducers/orderSlice';
import { resetAI } from '../../redux/features/ai/ai.slice';
import { selectFeatureFlagTaskRouter } from '../../redux/features/config/config.selector';
import { TaskStatuses } from '../../redux/features/taskRouter/taskRouter.constants';
import { selectCurrentTask } from '../../redux/features/taskRouter/taskRouter.selector';
import { updateTaskStatus } from '../../redux/features/taskRouter/taskRouter.slice';
import {
  selectIsStaffIntervention,
  selectRestaurantStaffIntervention,
} from '../../selectors/message';
import { activeCartSelector, isValidCartSelector } from '../../utils/cart';
import Colors from '../../utils/color';
import { END_SESSION_REASON } from '../../utils/constants';
const useStyles = makeStyles((theme: Theme) => ({
  buttonWrapper: {
    height: '64px',
    padding: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(1),
  },
  actionButton: {
    flex: 1,
    fontWeight: 'bold !important',
  },
  addBlackBackgroundColor: {
    backgroundColor: Colors.black,
  },
  customizeDisabledButton: {
    backgroundColor: `${Colors.alto2} !important`,
  },
  progress: {
    position: 'absolute',
    bottom: 0,
  },
}));

export const OrderActions = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cartActive = useSelector(activeCartSelector);
  const cartValid = useSelector(isValidCartSelector);
  const cartItems = useSelector((state: RootState) => state.cart.cartItems);
  const restaurantCode = useSelector(
    (state: RootState) => state.restaurant.selectedRestaurantCode
  );
  const orderSessionId = useSelector(
    (state: RootState) => state.order.currentSessionId
  );
  const messageSessionId = useSelector(
    (state: RootState) => state.messages.currentSessionId
  );
  const isStaffIntervention = useSelector(selectIsStaffIntervention);
  const restaurantStaffIntervention = useSelector(
    selectRestaurantStaffIntervention
  );
  const isRestaurantStaffInterventionClicked =
    restaurantStaffIntervention !== RestaurantStaffInterventionStatus.initial;
  const isStaffInterventionHappening =
    isStaffIntervention || isRestaurantStaffInterventionClicked;
  const currentTaskId = useSelector(selectCurrentTask);
  const navigate = useNavigate();
  const location = useLocation();
  const taskROuterFeatureFlag = useSelector(selectFeatureFlagTaskRouter);

  useEffect(() => {
    if (isStaffInterventionHappening) {
      cancelOrder();
    }
  }, [isStaffInterventionHappening]);

  const sendEndSession = (reason: string) => {
    if (restaurantCode) {
      const payload: Partial<EndSessionTransmissionMessage> = {
        data: {
          restaurant_code: restaurantCode,
          session_id: orderSessionId || messageSessionId,
        }, // still end the session if there is no order session yet
        metadata: reason,
      };
      dispatch(messagingActions.sendEndSession(payload as any));
    }
  };

  function sendTaskStatus(event: TaskStatuses) {
    if (taskROuterFeatureFlag && currentTaskId) {
      dispatch(messagingActions.closeConnection());
      dispatch(
        updateTaskStatus({
          taskId: currentTaskId,
          event,
        })
      );
      navigate(location.state?.from || '/' + 'task-router');
    }
  }

  const cancelOrder = () => {
    sendEndSession(END_SESSION_REASON.CANCEL_ORDER);
    dispatch(cartActions.clearCart());
    dispatch(resetAI());
    dispatch(messagingActions.clearMessages());
    dispatch(orderActions.finishOrderResetValues());
    dispatch(orderActions.resetCurrentTransactionId());
    sendTaskStatus(TaskStatuses.canceled);
  };

  const finishOrder = () => {
    sendEndSession(END_SESSION_REASON.FINISH_ORDER);
    dispatch(sendOrder({ cartItems, cartValid, isFinal: true }));
    dispatch(cartActions.clearCart());
    dispatch(resetAI());
    dispatch(messagingActions.clearMessages());
    dispatch(orderActions.finishOrderResetValues());
    sendTaskStatus(TaskStatuses.completed);
  };

  return (
    <div
      id="orderActions"
      className={`${classes.buttonWrapper} ${
        cartActive ? classes.addBlackBackgroundColor : ''
      }`}
    >
      <Button
        onClick={cancelOrder}
        variant="contained"
        disabled={!restaurantCode}
        color="error"
        className={classes.actionButton}
      >
        Cancel Order
      </Button>
      <Button
        onClick={finishOrder}
        variant="contained"
        color="success"
        disabled={!cartActive || !cartValid}
        className={`${classes.actionButton} ${
          !cartValid ? classes.customizeDisabledButton : ''
        }`}
      >
        Finish Order
      </Button>
    </div>
  );
};
