import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material';
import { InfoTransmissionMessage, messagingActions, RestaurantStaffInterventionStatus } from '../../reducers/messagingSlice';
import { selectRestaurantStaffIntervention } from '../../selectors/message';

const reasons = ['3rd party / mobile', 'audio issues', 'language not supported', 'coupon / loyalty issue', 'order issue', 'requested staff', 'other'];

export default function RestaurantStaffDialog() {
  const dispatch = useDispatch();
  const restaurantStaffIntervention = useSelector(selectRestaurantStaffIntervention);

  const isOpen = restaurantStaffIntervention === RestaurantStaffInterventionStatus.open;

  const handleClose = () => {
    dispatch(messagingActions.setRestaurantStaffIntervention(RestaurantStaffInterventionStatus.close));
  };

  const handleSelectReason = (reason: string) => {
    const message = 'Intervention reason: ' + reason + ' is selected';
    const payload: Partial<InfoTransmissionMessage> = {
      data: { message, type: 'METRIC', metadata: { event_name: 'intervention_reason', reason } },
    };
    dispatch(messagingActions.sendInfo(payload as any));

    handleClose();
  };

  return (
    <Dialog open={!!isOpen} PaperProps={{ sx: { maxWidth: '450px' } }}>
      <DialogTitle sx={{ textAlign: 'center' }}>Select an intervention reason</DialogTitle>
      <DialogContent>
        {reasons.map((reason) => (
          <Button key={reason} data-testid={`reason-${reason}`} variant="outlined" size="large" fullWidth sx={{ mt: 1.5 }} onClick={() => handleSelectReason(reason)}>
            {reason}
          </Button>
        ))}
      </DialogContent>
    </Dialog>
  );
}
