import { GraphQLClient } from 'graphql-request/dist';
import { readEnvVariable } from '.';
import { MenuStages, NodeEnv } from '../constants/enums';
import { getSdk as createSdk, SdkFunctionWrapper } from '../generated-interfaces/graphql';
import { addQueryParamsToUrl } from './common';
import { RESTAURANT_DIAGNOSTIC_PASSWORD, RESTAURANT_DIAGNOSTIC_USERNAME } from './constants';
import { saveAuthToken } from './local-storage';
import { PersistentMenuProperty } from './menu';
import { nullOrUndefined } from './types';

let client: GraphQLClient | null;

const AUTH_TOKEN_RESPONSE_HEADER = 'x-presto-auth-token';
export const AUTHORIZATION_HEADER = 'Authorization';
let authToken: string | nullOrUndefined;

export const setAuthToken = (token: string | nullOrUndefined) => {
  authToken = token;
  client = null; // Reset client so we use the new auth token
  saveAuthToken(token);
};

interface ApiRequestResult<T> {
  data: T & { responseStatusCode: number };
  headers: Headers;
  status: number;
}

const apiCallWrapper: SdkFunctionWrapper = async <T>(action: () => Promise<T>): Promise<T> => {
  const startTime = new Date().getTime();
  const result: ApiRequestResult<T> = (await action()) as any;
  const requestDuration = new Date().getTime() - startTime;
  setAuthToken(result.headers.get(AUTH_TOKEN_RESPONSE_HEADER));
  result.data['responseStatusCode'] = result.status;
  console.debug('API request duration (ms)', { requestDuration });
  return result.data;
};

export const ISOStringToEpoch = (dateString: string) => {
  return new Date(dateString).getTime();
};

export const getWebsocketUrl = (nodeEnv: NodeEnv, restaurantCode: string) => {
  // TODO: I think this should pull the root from the config instead of the whole env
  switch (nodeEnv) {
    case 'PRODUCTION': {
      if (readEnvVariable('MENU_VERSION_STAGE').toLowerCase() === 'live') {
        return 'wss://prestovoice:HvAMF7FVMtHNAyBv@events.presto.com/' + restaurantCode + '/HITL_100';
      }
      return 'wss://prestovoice:HvAMF7FVMtHNAyBv@staging-events.presto.com/' + restaurantCode + '/HITL_100';
    }
    case 'STAGING':
      return 'wss://prestovoice:HvAMF7FVMtHNAyBv@staging-events.presto.com/' + restaurantCode + '/HITL_100';
    case 'DEVELOPMENT':
      return 'wss://prestovoice:HvAMF7FVMtHNAyBv@staging-events.presto.com/' + restaurantCode + '/HITL_100';
    case 'TEST':
      return 'wss://fake-events.presto.com/' + restaurantCode + '/HITL_100';
    default:
      return 'wss://prestovoice:HvAMF7FVMtHNAyBv@events.presto.com/' + restaurantCode + '/HITL_100';
    // return 'http://localhost:4000';
  }
};

export const getPortalApiUrl = (nodeEnv: NodeEnv) => {
  switch (nodeEnv) {
    case 'PRODUCTION':
      return 'https://api.portal.voice.presto.com';
    case 'STAGING':
      return 'https://api.portal.staging.presto.com';
    case 'DEVELOPMENT':
      return 'https://api.portal.dev.presto.com';
    // case 'SANDBOX':
    //   return 'https://api.portal.sandbox.presto.com';
    case 'TEST':
      return 'https://api.portal.voice.presto.com';
    default:
      return 'https://api.portal.staging.presto.com';
  }
};

export const getOrderApiUrl = (nodeEnv: NodeEnv) => {
  switch (nodeEnv) {
    case 'PRODUCTION':
      if (readEnvVariable('MENU_VERSION_STAGE').toLowerCase() === 'live') {
        return 'https://6pl066qvok.execute-api.us-west-2.amazonaws.com';
      }
      return 'https://o0r0pq9g0g.execute-api.us-west-2.amazonaws.com';
    case 'TEST':
      return 'https://fake-poslink.presto.com';
    case 'STAGING':
      return 'https://tc2ym9zqvl.execute-api.us-west-2.amazonaws.com';
    case 'DEVELOPMENT':
      return 'https://j41j11y2ok.execute-api.us-west-2.amazonaws.com';
    case 'SANDBOX':
      return 'https://o0r0pq9g0g.execute-api.us-west-2.amazonaws.com';
    default:
      return 'https://j41j11y2ok.execute-api.us-west-2.amazonaws.com';
  }
};

export const getPersistentMenuPropApiUrl = (nodeEnv: NodeEnv) => {
  switch (nodeEnv) {
    case 'PRODUCTION':
      return 'https://wq4mos2tv0.execute-api.us-west-2.amazonaws.com/prod';
    case 'STAGING':
      return 'https://gwnusditq0.execute-api.us-west-2.amazonaws.com/staging';
    case 'DEVELOPMENT':
      return 'https://s5lfdtdmxf.execute-api.us-west-2.amazonaws.com/dev';
    case 'SANDBOX':
    default:
      return 'https://gwnusditq0.execute-api.us-west-2.amazonaws.com/staging';
  }
};

export const getMenuApiUrl = (nodeEnv: NodeEnv) => {
  switch (nodeEnv) {
    case 'PRODUCTION':
      return 'https://ea7f88cvxg.execute-api.us-west-2.amazonaws.com';
    case 'SANDBOX':
      return 'https://nffc1w4pta.execute-api.us-west-2.amazonaws.com';
    case 'DEVELOPMENT':
      return 'https://hey29l99j8.execute-api.us-west-2.amazonaws.com';
    case 'STAGING':
      return 'https://86jfzbb0x2.execute-api.us-west-2.amazonaws.com';
    default:
      return 'https://86jfzbb0x2.execute-api.us-west-2.amazonaws.com';
  }
};

export const getForceLogOutApiUrl = (nodeEnv: NodeEnv) => {
  switch (nodeEnv) {
    case 'PRODUCTION':
      return 'https://w8sqqkf4h9.execute-api.us-west-2.amazonaws.com/user_session';
    case 'STAGING':
      return 'https://sk8hifottf.execute-api.us-west-2.amazonaws.com/user_session';
    case 'DEVELOPMENT':
      return 'https://qs57hoqlk4.execute-api.us-west-2.amazonaws.com/user_session';
    case 'SANDBOX':
    default:
      return 'https://sk8hifottf.execute-api.us-west-2.amazonaws.com/user_session';
  }
};

export const getPersistentMenuPropByRestaurant = async (nodeEnv: NodeEnv, restaurantCode: string, params: { [key: string]: string }) => {
  const url = addQueryParamsToUrl(`${getPersistentMenuPropApiUrl(nodeEnv)}/persistent-menu-property/${restaurantCode}`, params);
  let persistentMenuProp: PersistentMenuProperty[] = [];
  if (authToken) {
    const data = await (
      await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: authToken,
        },
      })
    ).json();
    persistentMenuProp = data;
  }
  return persistentMenuProp;
};

let orderApiToken: string | null = null;

export const getOrderApiToken = async (nodeEnv: NodeEnv) => {
  if (!orderApiToken) {
    const result = await fetch(`${getOrderApiUrl(nodeEnv)}/voice/auth/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_name: 'PrestoVoice User',
        password: 'password@123',
        pos_partner: 'PRESTOVOICE',
      }),
    });
    const json = await result.json();
    orderApiToken = json?.result?.auth_token;
  }
  return orderApiToken;
};

export const getGraphQLClient = (nodeEnv: NodeEnv) => {
  if (!client) {
    console.debug('Created new GraphQL Client');
    client = new GraphQLClient(`${getPortalApiUrl(nodeEnv)}/graphql`);
    if (authToken) {
      client.setHeader(AUTHORIZATION_HEADER, authToken);
    }
  }
  return createSdk(client, apiCallWrapper);
};

export const sleep = async (timeToWait: number) => {
  return new Promise((resolve) => setTimeout(resolve, timeToWait));
};

export const getMenuURLFromMenuAPI = async (nodeEnv: NodeEnv, stage: MenuStages, params: { [key: string]: string }) => {
  const url = addQueryParamsToUrl(`${getMenuApiUrl(nodeEnv)}/${stage.toLocaleLowerCase()}/menu`, params);
  let MenuRespons: any = {};
  if (authToken) {
    const data = await (
      await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: authToken,
        },
      })
    ).json();
    MenuRespons = data;
  }
  return MenuRespons;
};

export const getMenuFromMenuAPI = async (nodeEnv: NodeEnv, menuURL: string, params: { [key: string]: string }) => {
  const url = addQueryParamsToUrl(menuURL, params);
  let MenuRespons: any = {};
  if (authToken) {
    const data = await (
      await fetch(url, {
        method: 'GET',
      })
    ).json();
    MenuRespons = data;
  }
  return MenuRespons;
};

export const getMenuVersionsFromMenuAPI = async (nodeEnv: NodeEnv, params: { restaurant_code: string }) => {
  const url = addQueryParamsToUrl(`${getMenuApiUrl(nodeEnv)}/${MenuStages.PLAYGROUND.toLowerCase()}/menu-commit-stage`, params);
  let MenuRespons: any = {};
  if (authToken) {
    const data = await (
      await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: authToken,
        },
      })
    ).json();
    MenuRespons = data;
  }
  return MenuRespons;
};

export const getNewRelicFilePath = (nodeEnv: NodeEnv) => {
  switch (nodeEnv) {
    case 'PRODUCTION':
      return './new_relic/production.js';
    case 'STAGING':
      return './new_relic/staging.js';
    case 'DEVELOPMENT':
      return './new_relic/dev.js';
    case 'SANDBOX':
    default:
      return './new_relic/dev.js';
  }
};

const getRestaurantDiagnosticUrl = (nodeEnv: NodeEnv) => {
  switch (nodeEnv) {
    case 'PRODUCTION':
      return 'https://restaurant-diagnostic-api.presto.com/';
    case 'STAGING':
      return 'https://xxsylmou02.execute-api.us-west-2.amazonaws.com/staging/';
    case 'DEVELOPMENT':
    case 'SANDBOX':
    default:
      return 'https://xxsylmou02.execute-api.us-west-2.amazonaws.com/staging/';
  }
};
export const getRestaurantDiagnostic = async (nodeEnv: NodeEnv, restaurantCode: string, successCallback: (data: any) => void) => {
  const url = `${getRestaurantDiagnosticUrl(nodeEnv)}${restaurantCode}`;
  const authInfo = `${RESTAURANT_DIAGNOSTIC_USERNAME}:${RESTAURANT_DIAGNOSTIC_PASSWORD}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: 'Basic ' + btoa(authInfo),
      },
    });
    const json = await response.json();
    successCallback(json);
  } catch (error) {
    console.error('Get restaurant diagnostic failed with', error);
  }
};
