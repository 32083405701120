import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useRouteError } from 'react-router-dom';
import { Image } from '../components/Layout/Image';
import prestoLogo from '../images/presto_logo_white.png';
import Colors from '../utils/color';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  errorPageClass: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.daintree,
    color: Colors.white,
  },
  prestoLogoClass: {
    marginBottom: spacing(4),
    '@media (max-height: 600px)': {
      marginBottom: 0,
    },
  },
}));

const ErrorPage = () => {
  const { errorPageClass, prestoLogoClass } = useStyles();
  const error: any = useRouteError();
  console.error(error);

  return (
    <div data-testid="error-page" className={errorPageClass}>
      <Image className={prestoLogoClass} url={prestoLogo} alt="Presto Logo" />
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error?.statusText || error?.message}</i>
      </p>
    </div>
  );
};

export default ErrorPage;
