import { Chip, Stack, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ID, MAX_CART_ITEM_QUANTITY } from '../../constants';
import { SuggestionStatus } from '../../redux/features/ai/ai.constants';
import { selectSuggestions } from '../../redux/features/ai/ai.selector';
import { addSuggestion, resetAI, updateSuggestion } from '../../redux/features/ai/ai.slice';
import { selectCartItems, selectCartItemsQuantity } from '../../selectors/cart';
import { selectPersistentVoiceProps } from '../../selectors/menu';
import { selectHypothesisFrame, selectIsNewSession } from '../../selectors/message';
import { findObjectInObjectByKeyValue } from '../../utils';
import Colors from '../../utils/color';
import { getVoiceProps } from '../../utils/menu';

const useStyles = makeStyles((theme: Theme) => ({
  aiSuggestionsBarClass: {
    width: '100%',
    height: '48px',
    backgroundColor: Colors.white,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    overflow: `auto hidden`,
  },
  firstSuggestionsClass: {
    backgroundColor: `${Colors.gold} !important`,
  },
  emptyBarClass: {
    paddingLeft: theme.spacing(3),
    fontSize: '0.75rem',
  },
}));

const AISuggestionsBar: FC = (props) => {
  const { aiSuggestionsBarClass, emptyBarClass, firstSuggestionsClass } = useStyles(props);
  const dispatch = useDispatch();
  const hypothesisFrame = useSelector(selectHypothesisFrame);
  const isNewSession = useSelector(selectIsNewSession);
  const cartItems = useSelector(selectCartItems);
  const cartItemsQuantity = useSelector(selectCartItemsQuantity);
  const { suggestionsById, filteredSuggestionsOrder } = useSelector(selectSuggestions);
  const persistentVoiceProps = useSelector(selectPersistentVoiceProps);

  useEffect(() => {
    hypothesisFrame?.data?.hypotheses?.forEach((hypothesis) => {
      const orderItems = hypothesis.order_items;
      orderItems.forEach((orderItem) => {
        const { id, quantity } = orderItem;
        // TODO: Not handling recursive search as current hypothesis order events are only top level menu items
        const isItemPresent = findObjectInObjectByKeyValue(suggestionsById, ID, id);
        const transformedQuantity = Math.floor(quantity); //Added for temporary purpose only as quantity will never be in decimals
        if (!isItemPresent) {
          if (transformedQuantity > 0) dispatch(addSuggestion({ ...orderItem, quantity: Math.min(transformedQuantity, MAX_CART_ITEM_QUANTITY) }));
        } else {
          const matchingCartItem = findObjectInObjectByKeyValue(cartItems, ID, orderItem.id);
          const currentCartQuantity = matchingCartItem?.id ? cartItemsQuantity[matchingCartItem.cartItemId] : 0;
          const derivedQuantity = transformedQuantity - currentCartQuantity;
          if (derivedQuantity > 0) dispatch(updateSuggestion({ id, suggestionStatus: SuggestionStatus.new, quantity: Math.min(derivedQuantity, MAX_CART_ITEM_QUANTITY) }));
        }
      });
    });
  }, [hypothesisFrame]);

  useEffect(() => {
    if (isNewSession) dispatch(resetAI());
  }, [isNewSession]);

  return (
    <div data-testid="ai-suggestions-bar" className={aiSuggestionsBarClass}>
      {filteredSuggestionsOrder.length ? (
        <Stack direction="row" spacing={1}>
          {filteredSuggestionsOrder.map((id, index) => {
            const { name, quantity } = suggestionsById[id];
            const [displayName] = getVoiceProps(name, persistentVoiceProps);
            const label = `${quantity}x ${displayName ? displayName : name}`;
            return <Chip label={label} size="small" {...(index === 0 ? { className: firstSuggestionsClass } : null)} />;
          })}
        </Stack>
      ) : (
        <div className={emptyBarClass}>listening...</div>
      )}
    </div>
  );
};

export default AISuggestionsBar;
