import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  loadingIndicatorClass: {
    height: '100%',
  },
  loadingBoxClass: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

const LoadingIndicator = () => {
  const { loadingIndicatorClass, loadingBoxClass } = useStyles();

  return (
    <div data-testid="loading-indicator" className={loadingIndicatorClass}>
      <Box className={loadingBoxClass}>
        <CircularProgress />
        <Typography variant="h4" gutterBottom>
          Switching restaurant...
        </Typography>
        <Typography>Please wait, this might take a moment</Typography>
      </Box>
    </div>
  );
};

export default LoadingIndicator;
