import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { MenuStages } from '../constants/enums';
import {
  Maybe,
  Restaurant,
  RolePermissions,
  Scalars,
  UserRestaurantsRole,
} from '../generated-interfaces/graphql';
import { messagingActions } from '../reducers/messagingSlice';
import { startLoading } from '../redux/features/isLoading/isLoading.slice';
import { getGraphQLClient } from '../utils/network';
import {
  getRestaurantAccessLevels,
  getSelectedRestaurantAccess,
  hasRoleAccess,
  RestaurantAccess,
} from '../utils/restaurants';
import {
  fetchMenu,
  fetchMenuVersions,
  updateSelectedMenuVersion,
} from './menuSlice';

export interface IRestaurantInfo {
  restaurantName: string;
  restaurantCode: string;
  primaryRestaurantCode: Maybe<Scalars['String']>;
}

export interface RestaurantState {
  selectedRestaurant: Restaurant | null;
  selectedRestaurantCode: string | null;
  selectedPrimaryRestaurantCode: string | null;
  restaurantsByUserRole: UserRestaurantsRole[];
  restaurantAccessLevels: RestaurantAccess;
  userRolesHaveLoaded: boolean;
  restaurantsById: Record<string, IRestaurantInfo>;
  selectedStage: MenuStages;
}

export const initialState: RestaurantState = {
  selectedRestaurant: null,
  selectedRestaurantCode: null,
  selectedPrimaryRestaurantCode: null,
  restaurantsByUserRole: [],
  restaurantAccessLevels: {},
  userRolesHaveLoaded: false,
  restaurantsById: {},
  selectedStage: MenuStages.PLAYGROUND,
};

export const restaurantsByUserRole = createAsyncThunk(
  'restaurant/restaurantsByUserRole',
  async (_, thunkAPI) => {
    const sdk = getGraphQLClient(
      (thunkAPI.getState() as RootState).config.NODE_ENV
    );
    const { restaurantsByUserRole } =
      (await sdk.restaurantsByUserRoleQuery()) as any as {
        restaurantsByUserRole: UserRestaurantsRole[];
      };
    return restaurantsByUserRole;
  }
);

export const selectStage = createAsyncThunk(
  'restaurant/selectStage',
  async (_, { getState, dispatch }) => {
    const {
      restaurant: { selectedRestaurant, selectedStage },
      menu: { selectedMenuVersion },
    } = getState() as RootState;
    const { PLAYGROUND, LIVE } = MenuStages;
    const isPlayground = selectedStage === PLAYGROUND;
    const currentStage = isPlayground ? LIVE : PLAYGROUND;
    const timezone = selectedRestaurant?.hoursOfOperation?.timezone;
    const currentMenuVersion = isPlayground ? '' : selectedMenuVersion;
    dispatch(updateSelectedStage(currentStage));

    if (selectedRestaurant) {
      const { restaurantCode, primaryRestaurantCode } = selectedRestaurant;
      dispatch(startLoading());
      dispatch(
        fetchMenu({
          currentMenuVersion,
          timezone,
          restaurantCode,
          primaryRestaurantCode: primaryRestaurantCode || '',
          currentStage,
        })
      );
    }
    return currentStage;
  }
);

export const selectRestaurant = createAsyncThunk(
  'restaurant/selectRestaurant',
  async (
    {
      restaurantCode,
      primaryRestaurantCode,
    }: { restaurantCode: string; primaryRestaurantCode: string },
    { getState, dispatch }
  ) => {
    const {
      config: { NODE_ENV },
      restaurant: { restaurantAccessLevels },
    } = getState() as RootState;
    const sdk = getGraphQLClient(NODE_ENV);
    const { restaurant } = (await sdk.restaurantInfo({
      restaurantCode,
    })) as any as { restaurant: Restaurant };
    const timezone = restaurant.hoursOfOperation?.timezone;
    let currentMenuVersion = '';
    const { PLAYGROUND, LIVE } = MenuStages;
    const selectedRestaurantAccessLevel = getSelectedRestaurantAccess(
      restaurantCode,
      restaurantAccessLevels
    );
    const hasMenuVersionAccess = hasRoleAccess(
      RolePermissions.RestaurantManager,
      selectedRestaurantAccessLevel
    );
    let currentStage = LIVE;

    if (hasMenuVersionAccess) {
      currentStage = PLAYGROUND;
      dispatch(fetchMenuVersions({ restaurantCode }));
      currentMenuVersion = 'latest';
    }

    dispatch(startLoading());
    dispatch(updateSelectedMenuVersion(currentMenuVersion));
    dispatch(
      fetchMenu({
        restaurantCode,
        primaryRestaurantCode,
        timezone,
        currentMenuVersion,
        currentStage,
      })
    ).then((_) => {
      dispatch(messagingActions.clearMessages());
      dispatch(messagingActions.startConnecting());
    });
    return restaurant;
  }
);

export const restaurantState = createSlice({
  name: 'restaurant',
  initialState: initialState,
  reducers: {
    updateSelectedStage: (state, { payload }: PayloadAction<MenuStages>) => {
      state.selectedStage = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(selectRestaurant.fulfilled, (state, action) => {
      state.selectedRestaurant = action.payload;
      state.selectedRestaurantCode = action.payload.restaurantCode;
      state.selectedPrimaryRestaurantCode =
        action.payload.primaryRestaurantCode;
      state.selectedStage = MenuStages.PLAYGROUND;
    });
    builder.addCase(restaurantsByUserRole.fulfilled, (state, action) => {
      state.restaurantsByUserRole = action.payload;
      state.restaurantAccessLevels = getRestaurantAccessLevels(action.payload);
      state.userRolesHaveLoaded = true;

      const acc: Record<string, IRestaurantInfo> = {};
      state.restaurantsByUserRole.reduce((a, c) => {
        c.restaurants.forEach((r) => {
          if (!(r.restaurantCode in a)) {
            a[r.restaurantCode] = r;
          }
        });
        return a;
      }, acc);
      state.restaurantsById = acc;
    });
    builder.addCase(restaurantsByUserRole.rejected, (state, action) => {
      state.userRolesHaveLoaded = true;
      state.restaurantsById = {};
    });
    builder.addCase(selectStage.fulfilled, (state, action) => {
      state.selectedStage = action.payload;
    });
  },
});

export const { updateSelectedStage } = restaurantState.actions;

export default restaurantState.reducer;
