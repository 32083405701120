const FORCE_RELOAD_CHECK_TIME = 60_000;
const IDLE_TIME = 1000 * 60 * 60; // 60 MIN  IDEAL TIME OUT
const KEY = 'key';
const POS_ID = 'pos_id';
const POS_NAME = 'pos_name';
const DUMMY_ITEM = 'dummy_item';
const ID = 'id';
const MAX_CART_ITEM_QUANTITY = 19;
const RESET_HEALTH_STATUS_ONCLOSE_DELAY = 1000;
const TASKROUTER_WSLINK = 'TASK_CHANNEL';
const TASKROUTER_RECONNECT_ATTEMPTS = 5;
const TASKROUTER_RECONNECT_INTERVAL = 5000;
const TIMEOUT = 5000;
const ORDER_STARUS_ICON_RESET_INTERVAL = 3000;

export {
  FORCE_RELOAD_CHECK_TIME,
  IDLE_TIME,
  ID,
  KEY,
  POS_ID,
  POS_NAME,
  DUMMY_ITEM,
  MAX_CART_ITEM_QUANTITY,
  RESET_HEALTH_STATUS_ONCLOSE_DELAY,
  TASKROUTER_WSLINK,
  TASKROUTER_RECONNECT_ATTEMPTS,
  TASKROUTER_RECONNECT_INTERVAL,
  TIMEOUT,
  ORDER_STARUS_ICON_RESET_INTERVAL,
};
