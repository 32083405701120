import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

const selectUser = (state: RootState) => state.user;
const selectIsLoggedIn = createSelector(selectUser, (user) => user.isLoggedIn);
const selectUserProfile = createSelector(
  selectUser,
  (user) => user.userProfile
);
const selectDidAttemptAuthCheck = createSelector(
  selectUser,
  (user) => user.didAttemptAuthCheck
);
const selectUserFullName = createSelector(
  selectUserProfile,
  (userProfile) => `${userProfile?.firstName} ${userProfile?.lastName}`
);

export {
  selectUser,
  selectIsLoggedIn,
  selectUserProfile,
  selectDidAttemptAuthCheck,
  selectUserFullName,
};
