import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { default as React, FC } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  timerWrapperClass: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButtonClass: {
    marginTop: `${spacing(2)} !important`,
    textTransform: 'uppercase',
  },
  timerClass: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

interface ICountdownTimerProps {
  duration?: number;
  size?: number;
  colors?: any;
  onComplete?: Function;
}

const CountdownTimer: FC<ICountdownTimerProps> = ({
  duration = 60,
  size = 180,
  onComplete,
  colors = '#ffc107',
}) => {
  const { timerWrapperClass, timerClass } = useStyles();

  const renderTime = ({ remainingTime }: { remainingTime: number }) => {
    if (remainingTime === 0) {
      return <div className={timerClass}>Too late...</div>;
    }

    return (
      <div className={timerClass}>
        <div className="text">Remaining</div>
        <div className="value">{remainingTime}</div>
        <div className="text">seconds</div>
      </div>
    );
  };

  const handleOnComplete = () => {
    onComplete?.();
  };

  return (
    <div data-testid="vivo-countdown-timer" className={timerWrapperClass}>
      <CountdownCircleTimer
        isPlaying
        duration={duration}
        size={size}
        colors={colors}
        onComplete={handleOnComplete}
      >
        {renderTime}
      </CountdownCircleTimer>
    </div>
  );
};

export default CountdownTimer;
