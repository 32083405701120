import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { MenuStages } from '../constants/enums';
import { CartItemAdditionTypes } from '../constants/event';
import {
  CategoryAndTimePeriodQueryQuery,
  MenuItemsQueryQuery,
  ModalityType,
  ModifierGroupsQueryQuery,
} from '../generated-interfaces/graphql';
import { startLoading } from '../redux/features/isLoading/isLoading.slice';
import { defaultMenuVersion } from '../utils/constants';
import { getModifierAndModGroupFromHypotheses } from '../utils/hypotheses';
import { ModSymbolCodeNameMappingType } from '../utils/mappings';
import {
  buildFullMenuItem,
  considerTimePeriodCategory,
  fetchMenuBasedOnStage,
  formatCategoryAndTimePeriodResponse,
  formatMenuResponse,
  formatModGroupResponse,
  IMenuVersion,
  IMenuVersionsResponse,
  MenuResponses,
  parseCategoryAndTimeperiodResponse,
  ParsedCategory,
  ParsedMenuItem,
  parseMenuResponse,
  PersistentMenuProperty,
  TopLevelMenuItem,
} from '../utils/menu';
import { getMenuVersionsFromMenuAPI } from '../utils/network';
import { GenericMap } from '../utils/types';
import { cartActions } from './cartSlice';
import { dialogActions } from './dialogSlice';
import {
  EntityMenuItem,
  ErrorTransmissionMessage,
  messagingActions,
} from './messagingSlice';

export interface MenuState {
  topLevelMenuItems: GenericMap<TopLevelMenuItem>;
  availableCategoryWithTimePeriod: ParsedCategory[];
  categoriesWithTimePeriod: ParsedCategory[];
  alwaysAvailableCategories: ParsedCategory[];
  menuRes?: MenuResponses;
  fullMenuItems: GenericMap<ParsedMenuItem>;
  persistentVoiceProps: GenericMap<PersistentMenuProperty>;
  modSymbolMapping: ModSymbolCodeNameMappingType;
  codeNameMapping: ModSymbolCodeNameMappingType;
  menuVersions: IMenuVersion[];
  selectedMenuVersion: string;
  prodLiveVersion?: IMenuVersion;
}

export interface IAddItemToCart {
  menuItem: TopLevelMenuItem;
  quantity?: number;
  addedBy?: CartItemAdditionTypes;
  prefixWord?: string;
  inputModSymbol?: string;
}

export const initialState: MenuState = {
  topLevelMenuItems: {},
  availableCategoryWithTimePeriod: [],
  categoriesWithTimePeriod: [],
  alwaysAvailableCategories: [],
  menuRes: undefined,
  fullMenuItems: {},
  persistentVoiceProps: {},
  modSymbolMapping: {},
  codeNameMapping: {},
  menuVersions: [],
  selectedMenuVersion: 'latest',
  prodLiveVersion: {} as IMenuVersion,
};

export const fetchMenu = createAsyncThunk(
  'menu/getMenu',
  async (
    {
      restaurantCode,
      primaryRestaurantCode,
      timezone,
      currentMenuVersion,
      currentStage,
    }: {
      restaurantCode: string;
      primaryRestaurantCode?: string;
      timezone?: string;
      currentMenuVersion: string;
      currentStage: MenuStages;
    },
    thunkAPI
  ) => {
    try {
      const {
        categoryAndTimePeriodJSON,
        menuJSON,
        modifierGroupJSON,
        persistentMenuProperty,
      } = await fetchMenuBasedOnStage({
        restaurantCode,
        primaryRestaurantCode,
        state: thunkAPI.getState() as RootState,
        currentMenuVersion,
        currentStage,
      });

      let persistentVoiceProps = {};
      try {
        if (persistentMenuProperty.length > 0) {
          Object.assign(
            persistentVoiceProps,
            (persistentMenuProperty as PersistentMenuProperty[]).reduce(
              (acc, entry) => {
                acc[entry.unique_identifier] = entry;
                return acc;
              },
              {} as GenericMap<PersistentMenuProperty>
            )
          );
        }
      } catch (error) {
        console.error('Get Persistent Menu Property Failed', error);
      }

      const processedCategoryAndTimePeriodJSON =
        formatCategoryAndTimePeriodResponse(
          categoryAndTimePeriodJSON as CategoryAndTimePeriodQueryQuery
        );
      const { categoriesWithTimePeriod, alwaysAvailableCategories } =
        parseCategoryAndTimeperiodResponse(processedCategoryAndTimePeriodJSON);
      const availableCategoryWithTimePeriod = timezone
        ? considerTimePeriodCategory(categoriesWithTimePeriod, timezone)
        : categoriesWithTimePeriod;

      const processedMenuJSON = formatMenuResponse(
        menuJSON as MenuItemsQueryQuery
      );
      const { topLevelMenuItems, codeNameMapping, modSymbolMapping } =
        parseMenuResponse(
          [...availableCategoryWithTimePeriod, ...alwaysAvailableCategories],
          processedMenuJSON,
          persistentVoiceProps
        );

      const modality = (thunkAPI.getState() as RootState).cart.modality;
      return {
        modality,
        topLevelMenuItems,
        processedCategoryAndTimePeriodJSON,
        processedMenuJSON,
        processedModGroupsJSON: formatModGroupResponse(
          modifierGroupJSON as ModifierGroupsQueryQuery
        ),
        categoriesWithTimePeriod,
        availableCategoryWithTimePeriod,
        alwaysAvailableCategories,
        persistentVoiceProps,
        codeNameMapping,
        modSymbolMapping,
      };
    } catch (error) {
      console.error('Get Menu Items From Restaurant Portal Failed', error);
    }
    throw new Error(`Failed To Get Menu For Restaurant Code ${restaurantCode}`);
  }
);

export const addItemToCart = createAsyncThunk(
  'menu/addItemToCart',
  async (
    {
      menuItem,
      quantity,
      addedBy = CartItemAdditionTypes.human,
      prefixWord,
      inputModSymbol,
    }: IAddItemToCart,
    thunkAPI
  ) => {
    const { dispatch, getState } = thunkAPI;
    const {
      cart: { modality, sequenceId: cartSequenceId },
      menu: { menuRes, persistentVoiceProps, modSymbolMapping },
    } = getState() as RootState;

    if (menuRes) {
      const parsedMenuItem = buildFullMenuItem(
        menuItem,
        menuRes,
        persistentVoiceProps
      );
      dispatch(
        cartActions.addItemToCart({
          ...parsedMenuItem,
          cartItemId: cartSequenceId,
          childModifierGroups: {},
          modality,
          modcode: menuItem.modcode,
          addedBy,
        })
      );
      if (quantity) {
        dispatch(
          cartActions.updateQuantity({
            cartItemId: String(cartSequenceId),
            quantity,
          })
        );
      }

      if (prefixWord) {
        // handle prefixWord by selecting it as modifier if it is found
        Object.values(parsedMenuItem.modifierGroups).every((modGroup) => {
          const target = Object.values(modGroup.menuItems).find(
            (menuItem) =>
              menuItem.name.toLowerCase() === prefixWord.toLowerCase()
          );
          if (target) {
            dispatch(
              cartActions.selectModifier({
                cartItemId: cartSequenceId,
                menuItem: target,
                modGroup: modGroup,
                selected: true,
                modCode: inputModSymbol || '',
                modSymbolMapping,
              })
            );
            return false;
          }
          return true;
        });
      }

      dispatch(setFullMenuItem(parsedMenuItem));
    }
  }
);

export const addHypothesisItemToCart = createAsyncThunk(
  'menu/addHypothesisItemToCart',
  async (orderItem: EntityMenuItem, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const {
      menu: { topLevelMenuItems, fullMenuItems, modSymbolMapping },
      cart: { sequenceId: cartSequenceId },
    } = getState() as RootState;

    //use the first menu item with the id in the assumption of the same item will show up in the menu once
    const mainItem = Object.values(topLevelMenuItems).find(
      (menuItem) => orderItem.id === menuItem.id
    );

    if (mainItem) {
      let currentCartItemId = cartSequenceId;

      //add the main item to cart
      await dispatch(
        addItemToCart({
          menuItem: mainItem,
          quantity: orderItem.quantity,
          addedBy: CartItemAdditionTypes.AI,
        })
      );
      const itemId = mainItem.id + '-' + mainItem.categoryId;
      const fullMainItem = fullMenuItems[itemId];

      const children = orderItem.children;
      if (children?.length > 0) {
        children.forEach((mod) => {
          //find the modifier group and modifier based on the modifier id
          const { modGroup, modifier } = getModifierAndModGroupFromHypotheses(
            fullMainItem,
            mod.id
          );
          if (modifier && modGroup) {
            //TODO handle the quantity of modifier when we have the quantity selection function in the future. Now the quantity should default to 1.
            dispatch(
              cartActions.selectModifier({
                cartItemId: currentCartItemId,
                menuItem: modifier,
                modGroup,
                selected: true,
                modCode: '',
                modSymbolMapping,
              })
            );
          } else {
            console.log(
              "Can't find the modifier based on the id from the hypotheses in the menu",
              mod.id
            );
            const errorMessage = `can't find the modifier ${orderItem.id} based on the id from the hypothesis in the menu`;
            const payload: Partial<ErrorTransmissionMessage> = {
              data: { message: errorMessage },
            };
            dispatch(messagingActions.sendError(payload as any));
          }
        });
      }

      let quantity = orderItem.quantity ? Number(orderItem.quantity) : 1;

      if (quantity > 1) {
        dispatch(
          cartActions.updateQuantity({
            cartItemId: currentCartItemId.toString(),
            quantity: Math.min(quantity, 19),
          })
        );
      }
      dispatch(
        dialogActions.updateSelectedItem({
          item: fullMainItem,
          itemCartId: currentCartItemId,
        })
      );
    } else {
      console.log(
        "Can't find the main item based on the id from the hypotheses in the menu",
        orderItem.id
      );
      const errorMessage = `can't find the main item ${orderItem.id} based on the id from the hypothesis in the menu`;
      const payload: Partial<ErrorTransmissionMessage> = {
        data: { message: errorMessage },
      };
      dispatch(messagingActions.sendError(payload as any));
    }
  }
);

export const fetchMenuVersions = createAsyncThunk(
  'menu/menuVersions',
  async (
    { restaurantCode: restaurant_code }: { restaurantCode: string },
    { getState, dispatch }
  ) => {
    const {
      config: { NODE_ENV },
    } = getState() as RootState;
    let filteredMenuVersions: IMenuVersion[] = [];
    let prodLiveVersion = {} as IMenuVersion;
    if (restaurant_code) {
      const menuVersions: { data: IMenuVersionsResponse[] } =
        await getMenuVersionsFromMenuAPI(NODE_ENV, {
          restaurant_code,
        });
      const { LIVE, PLAYGROUND } = MenuStages;
      const versionMapping = (menuVersions?.data || []).reduce(
        (
          list,
          {
            commit_id: commitId,
            created_at: createdAt,
            creator_first_name,
            creator_last_name,
            publisher_username: publisherUsername,
            publisher_first_name,
            publisher_last_name,
            creator_username: creatorUsername,
            id,
            stage,
            is_active: isActive,
            updated_at: updatedAt,
            comment,
          }
        ) => {
          const versionData = {
            commitId,
            createdAt,
            creatorUsername,
            creatorName: `${creator_first_name} ${creator_last_name}`,
            publisherUsername,
            publisherName: `${publisher_first_name} ${publisher_last_name}`,
            id,
            stage,
            isActive,
            updatedAt,
            comment,
          };
          if (stage.toUpperCase() === LIVE && isActive) {
            prodLiveVersion = versionData;
          }
          if (stage.toUpperCase() === PLAYGROUND) {
            list[commitId] = versionData;
          }
          return list;
        },
        {} as Record<string, IMenuVersion>
      );
      filteredMenuVersions = Object.values(versionMapping).sort(
        (a, b) => Number(b.commitId) - Number(a.commitId)
      );
    }
    return {
      menuVersions: [
        Object.assign({}, defaultMenuVersion),
        ...filteredMenuVersions,
      ],
      prodLiveVersion,
    };
  }
);

export const selectMenuVersion = createAsyncThunk(
  'menu/selectMenuVersion',
  async (currentMenuVersion: string, { getState, dispatch }) => {
    const {
      restaurant: { selectedRestaurant, selectedStage: currentStage },
    } = getState() as RootState;
    const timezone = selectedRestaurant?.hoursOfOperation?.timezone;

    if (selectedRestaurant) {
      const { restaurantCode, primaryRestaurantCode } = selectedRestaurant;
      dispatch(startLoading());
      dispatch(
        fetchMenu({
          currentMenuVersion,
          timezone,
          restaurantCode,
          primaryRestaurantCode: primaryRestaurantCode || '',
          currentStage,
        })
      );
    }
    return currentMenuVersion;
  }
);

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    considerTimePeriods: (
      state,
      action: PayloadAction<{ modality: ModalityType; timezone: string }>
    ) => {
      if (state.categoriesWithTimePeriod.length && state.menuRes) {
        const { timezone } = action.payload;
        state.availableCategoryWithTimePeriod = [
          ...considerTimePeriodCategory(
            state.categoriesWithTimePeriod,
            timezone
          ),
        ];
        const { menuItems, overrides, menuItemSettings, posSettings } =
          state.menuRes;
        const { topLevelMenuItems, codeNameMapping, modSymbolMapping } =
          parseMenuResponse(
            [
              ...state.alwaysAvailableCategories,
              ...state.availableCategoryWithTimePeriod,
            ],
            { menuItems, overrides, menuItemSettings, posSettings },
            state.persistentVoiceProps
          );
        state.topLevelMenuItems = topLevelMenuItems;
        state.codeNameMapping = codeNameMapping;
        state.modSymbolMapping = modSymbolMapping;
      }
    },
    setFullMenuItem: (state, action: PayloadAction<ParsedMenuItem>) => {
      const item = action.payload;
      const itemId = item.itemId;
      state.fullMenuItems[itemId] = item;
    },
    updateSelectedMenuVersion: (state, { payload }: PayloadAction<string>) => {
      state.selectedMenuVersion = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMenu.fulfilled, (state, action) => {
      const {
        topLevelMenuItems,
        processedCategoryAndTimePeriodJSON,
        processedMenuJSON,
        processedModGroupsJSON,
        categoriesWithTimePeriod,
        availableCategoryWithTimePeriod,
        alwaysAvailableCategories,
        persistentVoiceProps,
        codeNameMapping,
        modSymbolMapping,
      } = action.payload;
      state.topLevelMenuItems = topLevelMenuItems;
      console.log(JSON.stringify(state.topLevelMenuItems));
      state.modSymbolMapping = modSymbolMapping;
      state.codeNameMapping = codeNameMapping;
      state.menuRes = {
        ...processedCategoryAndTimePeriodJSON,
        ...processedMenuJSON,
        ...processedModGroupsJSON,
      };
      state.persistentVoiceProps = persistentVoiceProps;
      state.categoriesWithTimePeriod = categoriesWithTimePeriod;
      state.availableCategoryWithTimePeriod = availableCategoryWithTimePeriod;
      state.alwaysAvailableCategories = alwaysAvailableCategories;
    });
    builder.addCase(
      fetchMenuVersions.fulfilled,
      (state, { payload: { menuVersions, prodLiveVersion } }) => {
        state.menuVersions = menuVersions;
        state.prodLiveVersion = prodLiveVersion;
      }
    );
    builder.addCase(selectMenuVersion.fulfilled, (state, action) => {
      state.selectedMenuVersion = action.payload;
    });
  },
});

export const menuActions = menuSlice.actions;
export const {
  considerTimePeriods,
  setFullMenuItem,
  updateSelectedMenuVersion,
} = menuSlice.actions;

export default menuSlice.reducer;
