import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useAppSelector } from '../app/hooks';
import Header from '../components/Layout/Header';
import TaskRouterBody from '../components/TaskRouterBody';
import useTaskRouterWS from '../hooks/taskRouterWS/useTaskRouterWS.hooks';
import { selectWSConnectionMessage } from '../redux/features/taskRouter/taskRouter.selector';
import Colors from '../utils/color';

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  taskRouterPageClass: {},
  errorMessageClass: {
    paddingLeft: spacing(1),
  },
  errorClass: {
    height: spacing(5),
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Colors.red,
    color: Colors.white,
    paddingLeft: spacing(2),
  },
}));

const TaskRouterPage = () => {
  const { taskRouterPageClass, errorClass, errorMessageClass } = useStyles();
  const { handleAgentAvailability } = useTaskRouterWS();
  const wsConnectionMessage = useAppSelector(selectWSConnectionMessage);

  return (
    <div data-testid="task-router-page" className={taskRouterPageClass}>
      <Header
        isTaskRouterPage
        handleAgentAvailability={handleAgentAvailability}
      />
      {wsConnectionMessage ? (
        <div className={errorClass}>
          <WarningAmberIcon />
          <span className={errorMessageClass}>{wsConnectionMessage}</span>
        </div>
      ) : (
        <TaskRouterBody handleAgentAvailability={handleAgentAvailability} />
      )}
    </div>
  );
};

export default TaskRouterPage;
