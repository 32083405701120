import React from 'react';
import Body from './components/Body';
import Header from './components/Layout/Header';
import useApp from './hooks/useApp.hooks';
import useNewRelicScript from './hooks/useNewRelic.hooks';

function App() {
  useApp();
  useNewRelicScript();

  return (
    <div className="App">
      <Header />
      <Body />
    </div>
  );
}

export default App;
