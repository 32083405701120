import { useEffect } from 'react';
import { useAppSelector } from '../app/hooks';
import { selectNodeEnv } from '../redux/features/config/config.selector';
import { getNewRelicFilePath } from '../utils/network';

const useNewRelicScript = () => {
  const nodeEnv = useAppSelector(selectNodeEnv);
  const newRelicFilePath = getNewRelicFilePath(nodeEnv);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = newRelicFilePath;

    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);
};

export default useNewRelicScript;
