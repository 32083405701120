import { CouponResponseTypes, StatusResponseTypes } from '../constants/event';
import { ICartState } from './cartSlice.props';
import {
  ICheckCouponTransmissionMessage,
  IStatusTransmissionMessage,
} from './messagingSlice';
import { COUPON_APPLY_STATUS } from './cartSlice.constants';

const processLoyaltyResponses = (state: ICartState, requestId: string) => {
  const { loyaltyResponse } = state.requests[requestId];

  //loyalty response destructuring
  const { data: loyaltyData } =
    (loyaltyResponse as IStatusTransmissionMessage) || {};
  const { status, result } = loyaltyData || {};
  const { transaction_id: transactionId = '' } = result || {};
  const { type = '' } = status || {};

  state.currentTransactionId = transactionId;

  if (loyaltyResponse) {
    state.requests[requestId].status = type;
    if (type === StatusResponseTypes.success) {
      state.requests[requestId].transactionId = transactionId;
    } else {
      state.couponApplyStatus = COUPON_APPLY_STATUS.INVALID;
    }
  }
};

const processCouponResponses = (state: ICartState, requestId: string) => {
  const { couponResponse } = state.requests[requestId];

  //coupon response destructuring
  const { data } = (couponResponse as ICheckCouponTransmissionMessage) || {};

  console.log('data', data);
  const {
    status: statusCheckCoupon = CouponResponseTypes.unknown,
    couponno,
    coupontext,
    transaction_id: transactionId = '',
  } = data || {};

  state.currentTransactionId = transactionId;

  if (couponResponse) {
    state.requests[requestId].transactionId = transactionId;

    if (statusCheckCoupon === CouponResponseTypes.ok) {
      if (!couponno || !coupontext) {
        state.couponApplyStatus = COUPON_APPLY_STATUS.INVALID;
        return;
      }

      state.couponApplyStatus = COUPON_APPLY_STATUS.SUCCESS;

      if (coupontext.includes('_')) {
        /**
         * Normal case: <desc>_<comma separated offer item list>
         */
        const [name, modifiersSubstring, ...rest] = coupontext.split('_');

        if (name) {
          state.couponItem = {
            couponno,
            name,
            modifiers: modifiersSubstring.split(', '),
          };
        }
      } else {
        /**
         * Special case: <comma separated offer item list>
         */
        state.couponItem = {
          couponno,
          name: '',
          modifiers: coupontext.split(', '),
        };
      }
    }
    if (statusCheckCoupon === CouponResponseTypes.error) {
      state.couponApplyStatus = COUPON_APPLY_STATUS.INVALID;
    }
  }
};

export { processLoyaltyResponses, processCouponResponses };
