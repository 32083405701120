const SLICE_NAME = 'healthStatusCheckSlice';

enum CheckStatus {
  pending = 'pending',
  completed = 'completed',
}

enum IntegratedSystemsStatus {
  healthy = 'HEALTHY',
  unknown = 'UNKNOWN',
  unhealthy = 'UNHEALTHY',
}

enum IntegratedSystems {
  HITL = 'HITLService',
  event = 'eventService',
  posmon = 'posmonService',
  poslink = 'poslinkService',
}

enum SystemCheckActionType {
  setIsGenerating = 'setIsGenerating',
  setGeneratedTimeText = 'setGeneratedTimeText',
  setIsAllServiceHealth = 'setIsAllServiceHealth',
  setSystemCheckContent = 'setSystemCheckContent',
}

const RESET_TIMEOUT = 5000;

export { SLICE_NAME, CheckStatus, IntegratedSystemsStatus, IntegratedSystems, SystemCheckActionType, RESET_TIMEOUT };
