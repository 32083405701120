import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

const selectDialog = (state: RootState) => state.dialog;
function selectSelectedItemCartId(state: RootState) {
  return state.dialog.selectedItemCartId;
}

const selectDialogStep = createSelector(selectDialog, (dialog) => dialog.step);

export { selectDialog, selectSelectedItemCartId, selectDialogStep };
