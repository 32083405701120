import { AnyAction, Middleware } from 'redux';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { AgentTypes, EventTypes } from '../constants/event';
import { messagingActions } from '../reducers/messagingSlice';
import { getWebsocketUrl, sleep } from '../utils/network';
import { RootStore } from './store';

var agentId = uuidv4(); // Generated once at compile time is better than never

const isMatchMessagingAction = (action: AnyAction) => {
  const {
    sendMessage,
    sendInfo,
    sendOrder,
    sendLoyalty,
    sendEndSession,
    sendTTSRequest,
    sendError,
  } = messagingActions;
  return (
    sendMessage.match(action) ||
    sendInfo.match(action) ||
    sendOrder.match(action) ||
    sendLoyalty.match(action) ||
    sendEndSession.match(action) ||
    sendTTSRequest.match(action) ||
    sendError.match(action)
  );
};

const socketMiddleware: Middleware = (store: RootStore) => {
  let socket: WebSocket | undefined = undefined;

  return (next) => (action) => {
    const isConnectionEstablished =
      socket && store.getState().messages.isConnected;
    const reconnect = async (maxRetries: number) => {
      let retries = 0;
      while (retries < maxRetries) {
        let multipler = 1 + Math.random();
        let timeToWait = 2 ** retries * 1000 * multipler;
        await sleep(timeToWait);
        if (socket && socket.readyState !== 3) {
          return;
        }
        // reconnect if lost connection
        connect();
        retries += 1;
      }
    };

    const connect = () => {
      console.log('create websocket connection');
      // This really isn't the "right" way to do this, but it works.
      // TODO This probably needs to be a reducer of some kind, I couldn't work out how to do that.
      const restaurantCode = store.getState().restaurant.selectedRestaurantCode;
      const username = store.getState().user?.userProfile?.username;
      const nodeEnv = store.getState().config?.NODE_ENV;

      if (!restaurantCode || !username) {
        return;
      }

      agentId = uuidv5(username, uuidv5('presto.com', uuidv5.URL));

      if (socket) {
        socket.close();
      }

      socket = new WebSocket(getWebsocketUrl(nodeEnv, restaurantCode));

      socket.onopen = () => {
        console.log('websocket connection is open');
        store.dispatch(messagingActions.connectionEstablished());
      };

      var messages: any[] = [];
      socket.onmessage = (event: any) => {
        const data = JSON.parse(event.data);
        if (data) {
          messages.push(data);
        }
      };

      setInterval(() => {
        if (messages.length) {
          console.log(`Websocket Communication | Received message: `, messages);
          store.dispatch(messagingActions.messageReceived(messages));
        }
        messages = [];
      }, 100);

      socket.onclose = () => {
        console.log('websocket connection is closed');
        store.dispatch(messagingActions.connectionLost());
        reconnect(4);
      };
    };
    if (messagingActions.startConnecting.match(action)) {
      connect();
    }
    if (messagingActions.closeConnection.match(action)) {
      if (socket) {
        socket.onclose = () => {};
        socket.close();
        socket = undefined;
      }
    }

    if (isConnectionEstablished && isMatchMessagingAction(action))
      socket?.send(JSON.stringify(action.payload));
    next(action);
  };
};

const messagingTransformMiddleware: Middleware = (store: RootStore) => {
  let textSeqIdCounter = 0;
  let endSessionSeqIdCounter = 0;
  let infoSeqIdCounter = 0;
  let orderSeqIdCounter = 0;
  let loyaltySeqIdCounter = 0;
  let ttsRequestSeqIdCounter = 0;
  let errorSeqIdCounter = 0;
  return (next) => (action) => {
    if (isMatchMessagingAction(action)) {
      let payload = {
        ...action.payload,
        id: uuidv4(),
        session_id: store.getState().messages.currentSessionId,
        agent_type: AgentTypes.HITL,
        agent_id: agentId,
        timestamp: new Date().toISOString(),
        // I would prefer not to expose this, see PRV-2631
        metadata: { agent_name: store.getState().user?.userProfile?.username },
      };

      switch (action.type) {
        case messagingActions.sendMessage.type:
          payload.event = EventTypes.text;
          payload.seq = textSeqIdCounter++;
          break;
        case messagingActions.sendEndSession.type:
          payload.event = EventTypes.endSession;
          payload.seq = endSessionSeqIdCounter++;
          break;
        case messagingActions.sendInfo.type:
          payload.event = EventTypes.info;
          payload.seq = infoSeqIdCounter++;
          break;
        case messagingActions.sendOrder.type:
          payload.event = EventTypes.order;
          payload.seq = orderSeqIdCounter++;
          break;
        case messagingActions.sendLoyalty.type:
          payload.event = EventTypes.loyalty;
          payload.seq = loyaltySeqIdCounter++;
          break;
        case messagingActions.sendTTSRequest.type:
          payload.event = EventTypes.TTSRequest;
          payload.seq = ttsRequestSeqIdCounter++;
          break;
        case messagingActions.sendError.type:
          payload.event = EventTypes.error;
          payload.seq = errorSeqIdCounter++;
          break;
        default:
          break;
      }
      if (!('data' in payload)) {
        payload.data = {}; // Sending empty data field to be consistent with the high level schema for all other events
      }
      action.payload = payload;
      console.log(
        `Websocket Communication | ${payload.event} | data: `,
        JSON.stringify(payload)
      );
    }

    next(action);
  };
};

export { socketMiddleware, messagingTransformMiddleware };
