import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from '../../../app/store';
import { selectMenuVersion } from '../../../reducers/menuSlice';
import { selectRestaurant, selectStage } from '../../../reducers/restaurantSlice';
import { getRestaurantDiagnostic } from '../../../utils/network';
import { CheckStatus, IntegratedSystems, IntegratedSystemsStatus, RESET_TIMEOUT, SLICE_NAME } from './healthStatusCheck.constant';
import { HealthStatusCheckContent } from './healthStatusCheck.props';
import { initialState } from './healthStatusCheck.utils';

export const checkHealthStatus = createAsyncThunk('healthStatusCheck/checkStatus', (_: void, { getState, dispatch }) => {
  const { config, restaurant } = getState() as RootState;
  const nodeEnv = config.NODE_ENV;
  const restaurantCode = restaurant.selectedRestaurantCode;
  const handleRestaurantDiagnosticResponse = (response: any) => {
    const unhealthyServices = Object.keys(response)
      .filter(
        (key: any) =>
          [IntegratedSystems.HITL, IntegratedSystems.event, IntegratedSystems.poslink, IntegratedSystems.posmon].includes(key) &&
          response[key].status === IntegratedSystemsStatus.unhealthy
      )
      .map((key: any) => ({ name: key.charAt(0).toUpperCase() + key.slice(1).replace('S', ' S'), status: response[key].status }));
    if (unhealthyServices.length) {
      dispatch(setIsHealthy(false));
      dispatch(setHealthStatusCheckContent({ heading: `"${restaurantCode}" is experiencing issues`, body: unhealthyServices }));
    } else {
      dispatch(setIsHealthy(true));
      dispatch(setHealthStatusCheckContent({ heading: `"${restaurantCode}" is healthy`, body: 'All systems ok' }));
      setTimeout(() => {
        dispatch(resetHealthStatusState());
      }, RESET_TIMEOUT);
    }

    dispatch(setGeneratedTime(`This report was generated ${moment().format('MMM Do YYYY [at] h:mm a')}`));
    dispatch(setCheckStatus(CheckStatus.completed));
  };

  if (restaurantCode) {
    dispatch(setCheckStatus(CheckStatus.pending));
    getRestaurantDiagnostic(nodeEnv, restaurantCode, handleRestaurantDiagnosticResponse);
  }
});

export const healthStatusCheckSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setGeneratedTime: (state, action: PayloadAction<string>) => {
      state.generatedTime = action.payload;
    },
    setHealthStatusCheckContent: (state, action: PayloadAction<HealthStatusCheckContent>) => {
      state.healthStatusCheckContent = action.payload;
    },
    setCheckStatus: (state, action) => {
      state.checkStatus = action.payload;
    },
    setIsHealthy: (state, action) => {
      state.isHealthy = action.payload;
    },
    resetHealthStatusState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(selectRestaurant.fulfilled, selectStage.fulfilled, selectMenuVersion.fulfilled), () => initialState);
  },
});

const { setGeneratedTime, setHealthStatusCheckContent, setCheckStatus, setIsHealthy, resetHealthStatusState } = healthStatusCheckSlice.actions;

export { setGeneratedTime, setHealthStatusCheckContent, setCheckStatus, setIsHealthy, resetHealthStatusState };
export default healthStatusCheckSlice.reducer;
